import { Button, Modal } from "antd";
import ROUTES from "constants/_routes";
import { toastError, toastSuccess } from "helpers/toasters";
import { OTPInputComponent } from "modules/Authentications/components/OTPInput/Index";
import { verifyParentalCode } from "modules/Authentications/network";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const VerifyOldCodeModal = ({ open, onCancel }) => {
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [oldCode, setOldCode] = useState("");
  const otpRef = useRef();

  const handleInputChange = (oldPinCode) => {
    setOldCode(oldPinCode);
  };

  const submitCode = () => {
    if (!oldCode || oldCode.length !== 4) {
      toastError(t("emptycodemessage"));
      return;
    }
    if (oldCode.length === 4) {
      setLoading(true);
      const payload = {
        code: oldCode,
      };

      verifyParentalCode(
        payload,
        (success) => {
          toastSuccess(success.msg);
          navigate(ROUTES.UPDATE_PARENTAL_CODE, {
            state: { oldCode },
          });
        },
        (fail) => {
          setLoading(false);
          toastError(t("errorPinCode"));
        },
      );
    }
  };

  return (
    <Modal
      centered
      open={open}
      width={498}
      onCancel={onCancel}
      className='verifyOldCode-modal modal-container text-center'
      footer={false}
    >
      <div className='d-flex flex-column justify-content-center align-items-center'>
        <p className='verifyOldCode-modal_title mb-5'>{t("oldParentalCode")}</p>

        <div className='verifyOldCode-modal_form justify-content-center mb-5'>
          <div className='otp__container  text-center'>
            <div className='d-flex justify-content-center' dir='ltr'>
              <OTPInputComponent
                length={4}
                value={oldCode}
                autoFocus
                className='otpContainer'
                inputClassName='otpInput'
                isSecure
                onChangeOTP={(pinCode) => {
                  handleInputChange(pinCode);
                }}
              />
            </div>
          </div>
        </div>

        <Button
          loading={loading}
          className='button-primary px-1 mw-25 verifyOldCode-modal_form-button'
          onClick={submitCode}
        >
          {t("next")}
        </Button>
      </div>
    </Modal>
  );
};

export default VerifyOldCodeModal;
