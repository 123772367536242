import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  getAudiosAndSubtitle,
  getCastsData,
  getVideoDetails,
} from "../network";
import VideoDetials from "./VideoDetials";
import { toastError } from "helpers/toasters";
import { useLocation, useSearchParams } from "react-router-dom";
import { dbInfo, getSpecificDataFromDB } from "helpers/indexdb";
import VideoShowNew from "./VideoShowNew";
import VideoShow from "./VideoShow";
import VideoShowTest from "./VideoShowTest";
import VideoShowM3u8 from "./VideoShowM3u8";

const VideoDetailsWrapper = ({
  seriesOrParts,
  data: { videoId, videoHeaderId, isSeries, isFree, isSubscribed },
  getCurrentSeasonId,
  relatedPartsSeries,
  setSelectedVideo,
}) => {
  const { authorization } = useSelector((state) => state.auth);
  const location = useLocation();
  const [detailsData, setDetailsData] = useState([]);

  const [currentSeasonId, updateCurrentSeasonId] = useState(null);
  const [castsList, updateCastsList] = useState([]);
  const [audiosList, updateAudiosList] = useState([]);
  const [subtitlesList, updateSubtitlesList] = useState([]);
  const { lang } = useSelector((state) => state.lang);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const urlparams = new URLSearchParams(location.search);

  // const isOffline = isOfflineParam === "true";
  const isOffline = !urlparams.get("isOffline")
    ? false
    : urlparams.get("isOffline");

  const [videoDetailsLoading, setVideoDetailsLoading] = useState(
    navigator.onLine ? true : false
  );

  const getVideoDetailss = () => {
    const payload = {
      lang,
      videoHeaderId,
      videoId,
      isSeries,
      // isFree,
      userAccountId: authorization?.online?._id,
    };

    getVideoDetails(
      payload,
      (success) => {
        setDetailsData(success.data);
        // console.log("🚀 ~ getVideoDetailss ~ success.data:", success.data);

        setVideoDetailsLoading(false);
        const { seriesPartNoId } = success.data;
        payload.partId = seriesPartNoId;
        getCastsData(
          payload,
          (success) => {
            updateCastsList(success.data);
          },
          (fail) => {},
        );

        getAudiosAndSubtitle(
          payload,
          (success) => {
            const { audios, subtitles } = success.data;
            updateAudiosList(audios);
            updateSubtitlesList(subtitles);
          },
          (fail) => {},
        );
      },
      (fail) => {
        setVideoDetailsLoading(false);
        if (fail?.data?.err_msg) {
          toastError(fail?.data?.err_msg);
        } else {
          toastError();
        }
      },
    );
  };

  useEffect(() => {
    if (!navigator.onLine) {
      getSpecificDataFromDB(
        authorization.online._id,
        dbInfo.dbVersion,
        { videoId },
        (videoData) => {
          if (!videoData) {
            getVideoDetailss();
          } else {
            setDetailsData({ ...videoData, isDownloaded: true });
            setVideoDetailsLoading(false);
            updateSubtitlesList(videoData.subtitles);
            updateAudiosList(videoData.audios);
          }
          console.log("🚀 ~ useEffect ~ videoData:", videoData);
        },
        authorization.online._id
      );
    } else {
      setTimeout(() => {
        getVideoDetailss();
      }, 100);
    }
  }, [
    lang,
    authorization?.online?._id,
    videoHeaderId,
    videoId,
    isSeries,
    currentSeasonId,
  ]);

  return (
    <div className='watch-now'>
      <div>
        {/* <VideoShow
					audiosList={audiosList}
					subtitlesList={subtitlesList}
					videoData={detailsData}
					relatedPartsData={relatedPartsSeries}
					setSelectedVideo={setSelectedVideo}
					currentSeasonId={currentSeasonId}
					getCurrentSeasonId={getCurrentSeasonId}
					seriesOrParts={seriesOrParts}
				/> */}
        {/* <VideoShowNew
          videoData={{
            ...detailsData,
            audios: audiosList,
            subtitles: subtitlesList,
          }}
          relatedPartsData={relatedPartsSeries}
          setSelectedVideo={setSelectedVideo}
          currentSeasonId={currentSeasonId}
          getCurrentSeasonId={getCurrentSeasonId}
          seriesOrParts={seriesOrParts}
        /> */}
        {/* <VideoShowTest
          isFree={isFree}
          isSubscribed={isSubscribed}
          videoData={{
            ...detailsData,
            audios: audiosList,
            subtitles: subtitlesList,
          }}
          relatedPartsData={relatedPartsSeries}
          setSelectedVideo={setSelectedVideo}
          // currentSeasonId={currentSeasonId}
          getCurrentSeasonId={getCurrentSeasonId}
          seriesOrParts={seriesOrParts}
        /> */}

        <VideoShowM3u8
          detailsData={detailsData}
          isFree={isFree}
          isSubscribed={isSubscribed}
          relatedPartsData={relatedPartsSeries}
          setSelectedVideo={setSelectedVideo}
          // currentSeasonId={currentSeasonId}
          getCurrentSeasonId={getCurrentSeasonId}
          seriesOrParts={seriesOrParts}
        />
        <VideoDetials
          getVideoDetailss={getVideoDetailss}
          videoDetails={{
            ...detailsData,
            audios: audiosList,
            subtitles: subtitlesList,
          }}
          isLoading={videoDetailsLoading}
          seriesOrParts={seriesOrParts}
          getCurrentSeasonId={(seasonId) => {
            updateCurrentSeasonId(seasonId);
            getCurrentSeasonId(seasonId);
          }}
          isSeries={isSeries}
          castsList={castsList}
          isFree={isFree}
        />
      </div>
    </div>
  );
};

export default VideoDetailsWrapper;
