import {
  deleteResource,
  getResource,
  postResource,
  putResource,
} from "../../services";

import { PATHS, PATHS_PARAMS } from "constants/PATHS";

export function getAllGenders(lang, onSuccess, onFail) {
  const path = `api/genders?lang=${lang}`;
  getResource(path, onSuccess, onFail);
}
export function getAllLanguages({ lang }, onSuccess, onFail) {
  const path = `api/languages?lang=${lang}`;
  getResource(path, onSuccess, onFail);
}

export function signup(data, onSuccess, onFail) {
  const path = "api/register";
  postResource(path, data, onSuccess, onFail);
}

export function signin(data, onSuccess, onFail, headers) {
  const path = `api/login`;
  postResource(path, data, onSuccess, onFail, false, false, headers);
}

//Passwords APIs
export function forgetPassword({ phone }, onSuccess, onFail) {
  const data = {
    phone,
  };
  const path = "api/forgetPassword";
  postResource(path, data, onSuccess, onFail);
}
export function forgetThePinCode(onSuccess, onFail) {
  const path = "api/forgetPinCode";
  postResource(path, {}, onSuccess, onFail);
}
export function ConfirmCodeForPinCode(data, onSuccess, onFail) {
  const path = "api/ConfirmCodeForPinCode";
  postResource(path, data, onSuccess, onFail);
}

export function changePassword(
  { parentId, oldPassword, newPassword },
  onSuccess,
  onFail
) {
  const data = { parentId, oldPassword, newPassword };
  const path = "api/user/password";
  putResource(path, data, onSuccess, onFail);
}

export function resetPassword(data, onSuccess, onFail) {
  const path = "api/AddNewPassword";
  postResource(path, data, onSuccess, onFail);
}
//OTP
export function confirmCode({ phone, code }, onSuccess, onFail) {
  const data = {
    phone,
    code,
  };
  const path = "api/ConfirmCode";
  postResource(path, data, onSuccess, onFail);
}

//user account APIs-------------------------------
export function watchersData({ code, parentId }, onSuccess, onFail) {
  const data = {
    code,
    parentId,
  };
  const path = "api/user/pinCode";
  postResource(path, data, onSuccess, onFail);
}
export function createUserAccount(
  { age, avatarId, nameAr, nameEn, parentId, preferedLanguage },
  onSuccess,
  onFail
) {
  const data = { parentId, avatarId, nameAr, nameEn, age, preferedLanguage };
  const path = "api/userAccount/create";
  postResource(path, data, onSuccess, onFail);
}

//edit personal information for parent
export function editUserData(data, onSuccess, onFail) {
  const path = "api/user/edit";
  putResource(path, data, onSuccess, onFail);
}
export function editUserAccount(
  { avatarId, nameAr, nameEn, age, preferedLanguage, kidId, parentId },
  onSuccess,
  onFail
) {
  const data = {
    avatarId,
    "name.ar": nameAr,
    "name.en": nameEn,
    age,
    preferedLanguage,
    kidId,
    parentId,
  };
  const path = "api/userAccount/edit";
  putResource(path, data, onSuccess, onFail);
}
export function deleteUserAccount(data, onSuccess, onFail) {
  const path = "api/userAccount/delete";
  deleteResource(path, data, onSuccess, onFail);
}

export function getAllAvatars(onSuccess, onFail) {
  const path = "api/user/avatars";
  getResource(path, onSuccess, onFail);
}
//get all data for parent and there kids with age and language
export function getUserData(data, onSuccess, onFail) {
  const { userId } = data;
  const path = `api/userAccounts?userId=${userId}`;
  getResource(path, onSuccess, onFail);
}

//parental code APIs
export function createParentalCode({ code, parentId }, onSuccess, onFail) {
  const data = {
    code,
    parentId,
  };
  const path = "api/user/pinCode";
  postResource(path, data, onSuccess, onFail);
}

//parental code APIs
export function updateParentalCode(data, onSuccess, onFail) {
  const path = "api/user/pinCode";
  putResource(path, data, onSuccess, onFail);
}

export function verifyParentalCode({ code }, onSuccess, onFail) {
  const path = `api/user/verifyPinCode?code=${code}`;
  getResource(path, onSuccess, onFail);
}

export function contactUs({ name, phone, email, message }, onSuccess, onFail) {
  const data = { name, phone, email, message };
  const path = PATHS(PATHS_PARAMS.contactUs);
  postResource(path, data, onSuccess, onFail);
}

export function enterUserAccount(
  { deviceId, deviceName, accountId, userToken },
  onSuccess,
  onFail
) {
  const data = {
    deviceId,
    accountId,
    deviceName,
    userToken,
  };
  const path = PATHS(PATHS_PARAMS.enterUserAccount);
  postResource(path, data, onSuccess, onFail);
}

export function logoutUserAccount(
  { deviceId, accountId, userId },
  onSuccess,
  onFail
) {
  const data = {
    deviceId,
    accountId,
    userId,
  };
  const path = PATHS(PATHS_PARAMS.enterUserAccount, null, data);
  deleteResource(path, onSuccess, onFail, data);
}
