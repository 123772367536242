import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource } from "services";

export function getGenderAvatar({ isParent, isBoy }, onSuccess, onFail) {
  const data = { isParent, isBoy };
  const path = PATHS(PATHS_PARAMS.genderAvatars, null, data);
  getResource(path, onSuccess, onFail);
}

export function getRelatedAvatar(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getRelatedAvatars, null, data);
  getResource(path, onSuccess, onFail);
}
