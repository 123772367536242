import React from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import closeIcon from "assets/icons/close.svg";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../constants/_routes.js";
import { useSelector } from "react-redux";
function SignInModal({ isModalVisible, onCancel }) {
  const { authorization } = useSelector((state) => state.auth);

  const isParentOnline = authorization?.online?.isParent;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const navigateToSignIn = () => {
    navigate(ROUTES.SIGNIN);
    onCancel();
  };
  const titleStyles = {
    color: "#356AB3",
    fontSize: "25px",
    fontWeight: "bold",
    width: "60%",
    margin: "auto",
  };
  return (
    <Modal
      centered
      open={isModalVisible}
      width={678}
      onCancel={onCancel}
      className='subscripe-now-modal modal-container text-center '
      footer={false}
      closeIcon={
        <img src={closeIcon} alt='icon' style={{ width: "24px" }} />
      }
    >
      <div className='text mt-3 mb-4'>
        {authorization?.token ? t("subscribeFirst") : t("loginFirst")}
      </div>
      <div className='text-center m-auto'>
        {isParentOnline || !authorization?.token ? (
          // {isParentOnline || !authorization?.token ? (
          <Button
            className='button-primary button-primary--signIn'
            onClick={() => {
              if (authorization?.token) {
                navigate(ROUTES.SUBSCRIPTION);
                onCancel();
              } else {
                navigate(ROUTES.SIGNIN);
                onCancel();
              }
            }}
          >
            {authorization?.token ? t("subscribeNow") : t("signin")}
          </Button>
        ) : null}
      </div>
    </Modal>
  );
}

export default SignInModal;
