import { Slider } from "antd";
import React, { useState } from "react";
import volumeIcon from "assets/video-controllers/volume-up.svg";
import mute from "assets/video-controllers/mute.svg";
import { t } from "i18next";

export default function VolumeItem({ mutePlayer, setVolume, volume }) {
  const [isMuted, setIsMuted] = useState(false);
  const [viewSlider, setViewSlider] = useState(false);

  const style = {
    // display: "inline-block",
    height: 100,
    marginTop: "-100px",
    position: "absolute",
    bottom: "35px",
    margin: "auto",
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        position: "relative",
      }}
    >
      <div
        style={{
          color: "#fff",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
        role="button"
      >
        <img
          src={isMuted ? mute : volumeIcon}
          alt=""
          role="button"
          onClick={() => {
            setIsMuted((val) => !val);
            if (volume) {
              setVolume(0);
            } else {
              setVolume(30 / 100);
            }
            // mutePlayer();
          }}
        />
        <span onClick={() => setViewSlider((value) => !value)}>
          {t("volume")}
        </span>
      </div>

      {viewSlider && (
        <div
          style={style}
          onMouseLeave={() => setViewSlider((value) => !value)}
        >
          <Slider
            step={1}
            min={0}
            max={100}
            value={volume * 100}
            vertical
            onChange={(val) => {
              console.log("🚀 ~ VolumeItem ~ val:", val);
              setVolume(val / 100);
              if (val) {
                setIsMuted(false);
              } else {
                setIsMuted(true);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
