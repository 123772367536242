import React from "react";
import ControllersList from "./ControllersList";

function BottomControllers({
  videoData,
  changeAudio,
  changeSubtitle,
  changeQuality,
  mutePlayer,
  volume,
  setVolume,
  fullScreenChange,
  isFullScreen,
  selectPrevVideo,
  selectNextVideo,
  isNotLastVideoInSeries,
  isNotFirstVideoInSeries,
  selectedQuality,
  setSelectedQuality,
  selectedAudio,
  setSelectedAudio,
  selectedSub,
  setSelectedSub,
}) {
  const style = {
    position: "absolute",
    width: "100%",
    maxWidth: "100%",
    height: `${isFullScreen ? "10%" : "15%"}`,
    maxHeight: "100px",
    bottom: "0px",
    left: "0",
    zIndex: "5",
    background: `linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 80%, rgba(0, 0, 0, 0.2) 100%)`,
  };

  return (
    <div style={{ ...style }}>
      <ControllersList
        videoData={videoData}
        changeAudio={changeAudio}
        changeSubtitle={changeSubtitle}
        changeQuality={changeQuality}
        mutePlayer={mutePlayer}
        volume={volume}
        setVolume={setVolume}
        fullScreenChange={fullScreenChange}
        isFullScreen={isFullScreen}
        isNotFirstVideoInSeries={isNotFirstVideoInSeries}
        isNotLastVideoInSeries={isNotLastVideoInSeries}
        selectNextVideo={selectNextVideo}
        selectPrevVideo={selectPrevVideo}
        selectedQuality={selectedQuality}
        setSelectedQuality={setSelectedQuality}
        selectedAudio={selectedAudio}
        setSelectedAudio={setSelectedAudio}
        selectedSub={selectedSub}
        setSelectedSub={setSelectedSub}
      />
    </div>
  );
}

export default BottomControllers;
