// import React, { useState } from "react";
// import { Popover, Space } from "antd";
// import { useTranslation } from "react-i18next";

// import { BsCheckLg } from "react-icons/bs";

// export default function SubtitleItem({ subtitle, changeSubtitle }) {
//   const [selected, setSelected] = useState(-1);

//   const { t, i18n } = useTranslation();

//   const handleSelected = (indx) => {
//     setSelected(indx);
//   };

//   const subtitleStyle = {
//     paddingInline: "1rem",
//     paddingBlock: ".20rem",
//   };

//   const subtitleContent = (
//     <ul
//       //   className="setting-list-wrapper"
//       style={{
//         width: "130px",
//         position: "absolute",
//         bottom: "40px",
//         background: "rgba(0, 0,0, .5)",
//         color: "#fff",
//         zIndex: 1000,
//         listStyle: "none",
//         padding: 0,
//         margin: 0,
//         boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
//       }}
//       key={subtitle.id}
//       //   style={{ width: "100%" }}
//     >
//       <li
//         role="button"
//         style={subtitleStyle}
//         onClick={() => {
//           changeSubtitle(-1);
//           handleSelected(-1);
//         }}
//       >
//         {selected === -1 ? (
//           <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
//         ) : null}
//         {t("off")}
//       </li>
//       {subtitle?.options?.map((option, index) => {
//         return (
//           <li
//             style={subtitleStyle}
//             onClick={() => {
//               changeSubtitle(option.id);
//               handleSelected(option.id);
//             }}
//             key={option.id}
//             role="button"
//           >
//             {selected === option.id ? (
//               <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
//             ) : null}
//             {option.label}
//           </li>
//         );
//       })}
//     </ul>
//   );

//   return (
//     <Popover
//       style={{ position: "relative" }}
//       content={subtitleContent}
//       placement="topLeft"
//       title={subtitle.title}
//       key={subtitle.id}
//       //   trigger="click"
//       trigger="hover"
//       showArrow={false}
//       //   getPopupContainer={() => videoFullScreenWrapper?.current}
//       //   overlayStyle={{ position: "fixed", zIndex: "99999" }}
//     >
//       <Space align="center" role="button">
//         <span className="mock-block">
//           <img src={subtitle.icon} alt="" />
//         </span>
//         <span
//           className="mock-block"
//           style={{
//             color: "#fff",
//           }}
//         >
//           {t(subtitle.title)}
//         </span>
//       </Space>
//     </Popover>
//   );
// }

import React, { useState } from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { BsCheckLg } from "react-icons/bs";

export default function SubtitleItem({
  subtitle,
  changeSubtitle,
  selectedSub,
  setSelectedSub,
}) {
  // const [selected, setSelected] = useState(-1);
  const [isListVisible, setIsListVisible] = useState(false);

  const { t, i18n } = useTranslation();

  const subtitleStyle = {
    padding: "0.5rem",
    display: "flex",
    color: "#fff",
    width: "100%",
  };

  const subtitleContent = (
    <ul
      //   className="setting-list-wrapper"
      onMouseLeave={() => setIsListVisible(!isListVisible)}
      key={subtitle.id}
      style={{
        width: "130px",
        position: "absolute",
        bottom: "30px",
        background: "rgba(0, 0,0, .5)",
        color: "#fff",
        zIndex: 1000,
        listStyle: "none",
        padding: 0,
        margin: 0,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      <li
        role="button"
        style={subtitleStyle}
        onClick={() => {
          changeSubtitle(-1);
          setSelectedSub(-1);
          setIsListVisible(false);
        }}
      >
        {selectedSub === -1 ? (
          <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
        ) : null}
        {t("playerOffKey")}
      </li>
      {subtitle?.options?.map((option) => (
        <li
          style={subtitleStyle}
          onClick={() => {
            changeSubtitle(option.id);
            setSelectedSub(option.id);
            setIsListVisible(false);
          }}
          key={option.id}
          role="button"
        >
          {selectedSub === option.id ? (
            <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
          ) : null}
          {option.label}
        </li>
      ))}
    </ul>
  );

  return (
    <div
      style={{ position: "relative" }}
      onClick={() => setIsListVisible(!isListVisible)}
      //   onMouseLeave={() => setIsListVisible(!isListVisible)}
    >
      <Space align="center" role="button">
        <span className="mock-block">
          <img src={subtitle.icon} alt="" />
        </span>
        <span
          className="mock-block"
          style={{
            color: "#fff",
          }}
        >
          {t(subtitle.title)}
        </span>
      </Space>
      {isListVisible && subtitleContent}
    </div>
  );
}
