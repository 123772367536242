import { useState, useEffect } from "react";
import CountryPhoneInput, {
  ConfigProvider,
  // defaultAreas,
} from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import { useTranslation } from "react-i18next";
import { Form } from "antd";
import "antd/dist/antd.css";
import "../../../../node_modules/antd-country-phone-input/dist/index.css";

const InputPhone = ({
  getPhoneValues,
  phoneValues,
  getErrorMessage,
  mode,
  itemClassName,
  isSubmitted,
  disabled,
  name,
  autoComplete,
  isAfterSubmit,
}) => {
  const [colorValidation, setColorValidation] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation();
  // const [short, updateShort] = useState(null);
  // const [isInitiallyLoaded, updateIsInitiallyLoaded] = useState(false);
  // const [phoneCode, updatePhoneCode] = useState(null);
  // const [phone, updatePhone] = useState(null);
  //prevent mouse scrolling for increament and decreament
  var inputTypeNumbers = document.querySelectorAll("input[type=number]");
  for (var a = 0; a < inputTypeNumbers.length; a++) {
    inputTypeNumbers[a].onwheel = function (event) {
      event.target.blur();
    };
  }

  // useEffect(() => {
  //   if (mode === "initial" && !isInitiallyLoaded) {
  //     updateIsInitiallyLoaded(true);
  //     updatePhoneCode(phoneValues.code);
  //     updatePhone(phoneValues.phone);
  //     updateShort(phoneValues.short);
  //   } else {
  //     if (phoneValues?.phone && !isInitiallyLoaded) {
  //       updateIsInitiallyLoaded(true);
  //       const filteredCountry = defaultAreas.filter((area) => {
  //         if (area.phoneCode.toString() === phoneValues.phone.slice(0, 2)) {
  //           updatePhone(phoneValues.phone.slice(2));
  //           return area.phoneCode.toString() === phoneValues.phone.slice(0, 2);
  //         } else if (
  //           area.phoneCode.toString() === phoneValues.phone.slice(0, 3)
  //         ) {
  //           updatePhone(phoneValues.phone.slice(3));
  //           return area.phoneCode.toString() === phoneValues.phone.slice(0, 3);
  //         }
  //       });
  //       updateShort(filteredCountry[0]?.short);
  //       updatePhoneCode(filteredCountry[0]?.phoneCode);
  //     }
  //   }
  // }, [phoneValues, isInitiallyLoaded]);

  useEffect(() => {
    const [success, fail] = ["#356ab3", "#ff7875"];
    if (errorMessage) {
      setColorValidation(fail);
    } else {
      setColorValidation(success);
    }
    getErrorMessage(errorMessage);
    // eslint-disable-next-line
  }, [errorMessage]);

  useEffect(() => {
    let inputBorder = document.querySelector(".antd-country-phone-input");
    inputBorder.style.borderColor = colorValidation;
  }, [colorValidation]);

  useEffect(() => {
    if (phoneValues?.phone?.length || isSubmitted) {
      validatePhoneInput(phoneValues?.phone);
    }
    // eslint-disable-next-line
  }, [phoneValues, isSubmitted, isAfterSubmit]);

  function validatePhoneInput(phone) {
    if (!disabled) {
      // const minLength = mode === "initial" ? 10 : 11;
      // const maxLength = mode === "initial" ? 11 : 12;
      const isStartsWithZero = phone.startsWith("0");
      const minLength = isStartsWithZero ? 11 : 10;
      const maxLength = isStartsWithZero ? 11 : 10;
      const validPrefix = ["10", "11", "12", "15", "010", "011", "012", "015"];
      const isValid = validPrefix.some((prefix) => phone?.startsWith(prefix));

      if (!phone.length) {
        setErrorMessage(isAfterSubmit ? null : t("providePhoneNumber"));
      } else if (!isValid) {
        setErrorMessage(t("provideValidPhoneNumber"));
      } else if (
        (phone?.length > 0 && phone?.length < minLength) ||
        (phone?.length > 0 && phone?.length > maxLength)
      ) {
        setErrorMessage(t("provideValidPhoneNumber"));
      } else {
        setErrorMessage("");
      }
    }
  }

  function handleNumberChange(value) {
    const { code, phone, short } = value;
    let phoneNumber = String(phone).replace(/\D/g, "");
    if (phoneNumber.length && +phoneNumber[0] === 0) {
      let numberArr = phoneNumber.split("");
      getPhoneValues({ code, phone: numberArr.join("") });
    } else {
      getPhoneValues({ code, phone: phoneNumber });
    }
    // updateShort(short);
    // updatePhoneCode(code);
    // updatePhone(phone);
    validatePhoneInput(phoneNumber);
  }
  // const handlePreventLetters = (e) => {
  //   if (
  //     !(
  //       e.code?.includes("Digit") ||
  //       e.code === "Backspace" ||
  //       e.code?.includes("Arrow") ||
  //       e.code?.includes("Numpad")
  //     ) ||
  //     e.shiftKey
  //   ) {
  //     e.preventDefault();
  //   }
  // };

  return (
    <ConfigProvider
      locale={en}
      areaMapper={(area) => {
        return {
          ...area,
          emoji: <span className={`fp ${area.short.toLowerCase()}`} />,
        };
      }}
    >
      <Form.Item
        className={itemClassName}
        required={true}
        name={name}
        // autoComplete={autoComplete ? autoComplete : undefined}
      >
        <CountryPhoneInput
          disabled={disabled}
          inline
          value={phoneValues}
          onChange={handleNumberChange}
          // onKeyDown={handlePreventLetters}
          className={
            "w-100 border-none" + errorMessage?.length ? " hasError" : ""
          }
          placeholder={t("mobileNumber")}
          // autoComplete={autoComplete ? autoComplete : undefined}
        />
        <div
          className='auth__phoneError'
          style={{ opacity: errorMessage ? 1 : 0 }}
        >
          {errorMessage ? errorMessage : "noError"}
        </div>
      </Form.Item>
    </ConfigProvider>
  );
};
export default InputPhone;
