import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import playButton from "assets/icons/play-button.svg";
import plusButton from "assets/icons/plus-button.svg";
import { createBackgroundImage } from "helpers/createBackgoundImageUrl";

const Banner = ({ selectedBanner, isLoading }) => {
  const { lang } = useSelector((state) => state.lang);
  const { t } = useTranslation();

  const homeBannerStyle = {
    backgroundImage: `url(${createBackgroundImage(
      selectedBanner?.imgPath
    )})`,
  };

  return (
    <main
      className={`home-banner ${lang === "ar" ? "rtl" : ""}`}
      style={homeBannerStyle}
    >
      <Container>
        <div className='wrapper'>
          <div className='home-banner__data'>
            <iv className='home-banner__season'>
              {!isLoading ? (
                <span>{selectedBanner.videoHeader.title}</span>
              ) : (
                <Skeleton width={100} />
              )}
            </iv>
            <div className='home-banner__categories d-flex'>
              {!isLoading ? (
                <ul>
                  {selectedBanner?.category ? (
                    <li>{selectedBanner.category}</li>
                  ) : null}
                  {selectedBanner?.genre ? (
                    <li>{selectedBanner.genre}</li>
                  ) : null}
                </ul>
              ) : (
                <ul>
                  <Skeleton width={50} />
                  <Skeleton width={50} className='ms-2' />
                </ul>
              )}
            </div>

            <div className='home-banner__desc'>
              {!isLoading ? (
                <p>{selectedBanner?.description}</p>
              ) : (
                <Skeleton count={3} width={280} />
              )}
            </div>
          </div>
          <div className='home-banner__watch-now-add-list d-flex'>
            {!isLoading ? (
              <ul>
                <li
                  role='button'
                  onClick={(e) => {
                    e.stopPropagation();
                    alert("watch now");
                  }}
                >
                  <img src={playButton} alt={t("watchNow")} />
                  <div className='watch-now'>
                    <div className='watch-now__text'>
                      {t("watchNow")}
                    </div>
                    {selectedBanner?.seriesNumber && (
                      <small className='watch-now__small'>
                        {selectedBanner?.seriesNumber}
                      </small>
                    )}
                  </div>
                </li>
                <li
                  role='button'
                  onClick={(e) => {
                    e.stopPropagation();
                    alert("addToMyList");
                  }}
                >
                  <img src={plusButton} alt={t("myList")} />
                  {t("myList")}
                </li>
              </ul>
            ) : (
              <>
                <Skeleton width={135} />
                <Skeleton width={135} className='ms-2' />
              </>
            )}
          </div>
        </div>
      </Container>
    </main>
  );
};

export default Banner;
