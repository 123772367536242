import ROUTES from "constants/_routes";

export const responseErrorHandeler = (statusCode) => {
  switch (statusCode) {
    case 401: {
      localStorage.removeItem("persist:Amuse");
      window.location.reload();
      break;
    }
    case 0: {
      const offlinePathNames = [ROUTES.WATCH_NOW];
      if (!offlinePathNames.includes(window.location.pathname)) {
        window.location.href = ROUTES.NOINTERNET;
      }
      break;
    }
    case 404: {
      window.location.href = ROUTES.NOT_FOUND;
      break;
    }
    default: {
      break;
    }
  }
};
