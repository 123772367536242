import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyPlan from "./models/MyPlan/MyPlan";
import PaymentDetails from "./models/PaymentDetails/PaymentDetails";

function SubscriptionPlan() {
  const [selectedTab, updateSelectedTab] = useState("myPlan");
  const [refresh, updateRefresh] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    updateRefresh((prevVal) => !prevVal);
  }, []);
  return (
    <div className='subscription_plan main-section text-center'>
      <div className='title my-2'>{t("subscriptionPlan")}</div>
      <div className='switcher my-2'>
        <div
          className={`option ${selectedTab === "myPlan" && "selected"}`}
          onClick={() => {
            updateSelectedTab("myPlan");
          }}
        >
          {t("myPlan")}
        </div>
        <div
          className={`option ${selectedTab === "paymentDetails" && "selected"}`}
          onClick={() => {
            updateSelectedTab("paymentDetails");
          }}
        >
          {t("myCreditCards")}
        </div>
      </div>
      {selectedTab === "myPlan" ? <MyPlan /> : <PaymentDetails />}
    </div>
  );
}

export default SubscriptionPlan;
