import {
  dbInfo,
  deleteSpecificDataFromDB,
  getAllDataFromDB,
  initializeDatabase,
} from "helpers/indexdb";
import { getVideoToDownloads } from "modules/WatchNow/network";
import { useEffect, useLayoutEffect } from "react";
import useIsDownloaded from "./useIsDownloaded";

function useSyncDeleteDownloads(authorization, videoId) {
  const {
    isDownloaded,
    updateDownloadedState, // Return the update function
  } = useIsDownloaded(authorization, videoId);

  useEffect(() => {
    if (
      authorization &&
      authorization?.online &&
      authorization?.online?._id &&
      navigator.onLine
    ) {
      setTimeout(() => {
        initializeDatabase(
          authorization?.online?._id,
          dbInfo.dbVersion,
          authorization?.online?._id,
        );
        getVideoToDownloads(
          { userAccountId: authorization.online._id },
          (success) => {
            getAllDataFromDB(
              authorization?.online?._id,
              dbInfo.dbVersion,
              (localData) => {
                const obj = localData.filter(
                  (obj1) =>
                    !success.data.some((obj2) => obj1.videoId === obj2.videoId),
                );

                if (obj && obj.length > 0) {
                  obj.forEach((video) => {
                    deleteSpecificDataFromDB(
                      authorization?.online?._id,
                      dbInfo.dbVersion,
                      authorization?.online?._id,
                      video?.videoId,
                      () => {},
                    );
                  });
                }
                if (videoId) updateDownloadedState();
              },
              authorization?.online?._id,
            );
          },
        );
      }, 100);

      // deleteAllDataFromDB();
    }
  }, [authorization?.online?._id]);

  return {
    isDownloaded,
    updateDownloadedState, // Return the update function
  };
}

export default useSyncDeleteDownloads;
