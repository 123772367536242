import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource, postResource } from "services";

export const getGiftQuestion = (success, fail, phone) => {
  const path = PATHS(PATHS_PARAMS.getGiftQues, { phone: phone });
  getResource(path, success, fail, false);
};

export const postQuestAnswer = (data, success, fail) => {
  const path = PATHS(PATHS_PARAMS.postQuestAnswer);
  postResource(path, data, success, fail);
};
