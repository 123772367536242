import React, { useState } from "react";
import { VideoSeekSlider } from "react-video-seek-slider";
import timeFormatter from "format-duration";
import { useSelector } from "react-redux";

function SeekBar({ currentTime, seekTo, duration }) {
  const { lang } = useSelector((state) => state.lang);
  return (
    <div
      className="seek-bar"
      style={{
        width: "95%",
        position: "absolute",
        bottom: "0%",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: "5",
      }}
    >
      <VideoSeekSlider
        currentTime={currentTime}
        max={duration * 1000}
        secondsPrefix="00:"
        minutesPrefix="0:"
        onChange={(time) => seekTo(time)}
      />
      <div className="timer">
        {/* <p style={{ paddingBottom: ".25rem", order: lang === "en" ? 1 : 2 }}> */}
        <p style={{ paddingBottom: ".25rem", order: lang === "en" ? 2 : 1 }}>
          {timeFormatter(Math.round(duration * 1000))}
        </p>
        {/* <p style={{ paddingBottom: ".25rem", order: lang === "en" ? 2 : 1 }}> */}
        <p style={{ paddingBottom: ".25rem", order: lang === "en" ? 1 : 2 }}>
          {timeFormatter(Math.round(currentTime))}
        </p>
      </div>
    </div>
  );
}

export default SeekBar;
