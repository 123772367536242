import { useSelector } from "react-redux";
import { t } from "i18next";

/*_______________ Component _______________ */
import { Modal } from "antd";

/*_______________ Images _______________ */
import tick from "../../../../assets/icons/tick.svg";
import closeIcon from "../../../../assets/icons/close.svg";

function FawryModal({ isModalVisible, onCancel, fawryDetails }) {
  const { lang } = useSelector((state) => state.lang);

  const handlingReaminingTime = (remainingTime = 0) => {
    if (lang === "en") {
      if (remainingTime > 1)
        return `${t("expirationDate")} ${remainingTime} hours`;
      if (remainingTime === 1)
        return `${t("expirationDate")} ${remainingTime} hour`;
    }

    if (lang === "ar") {
      const formattedArabic = new Intl.NumberFormat("ar-EG").format(
        remainingTime,
      );

      if (remainingTime === 1) return `${t("expirationDate")} ساعة`;
      if (remainingTime === 2) return `${t("expirationDate")} ساعتين`;
      if (remainingTime >= 3 && remainingTime <= 10)
        return `${t("expirationDate")} ${formattedArabic} ساعات`;
      return `${t("expirationDate")} ${formattedArabic} ساعة`;
    }
  };

  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={onCancel}
      className='fawry-modal modal-container text-center'
      footer={false}
      closeIcon={
        <img src={closeIcon} alt='icon' style={{ width: "30px" }} />
      }
    >
      <img src={tick} alt='tick' className='success-img' />
      <div className='total'>
        <span className='title me-2'>{t("total")}</span>
        <span className='value'>
          {fawryDetails?.paymentAmount} {t("EGP")}
        </span>
      </div>
      <div className='fawryCode'>
        <span className='title' style={{ display: "block" }}>
          {t("YourFawryCode")}
        </span>
        <span
          className='value mx-2'
          style={{
            display: "block",
            fontWeight: "700",
            letterSpacing: "5px",
          }}
        >
          {fawryDetails?.referenceNumber}
        </span>
      </div>
      <div className='expirationDate '>
        <span className='value'>
          {handlingReaminingTime(fawryDetails?.remainingHours)}
        </span>
      </div>
      <div className='share-title '>{t("shareDigits")}</div>
    </Modal>
  );
}

export default FawryModal;
