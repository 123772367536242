import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { changeLang } from "store/lang";
import { useTranslation } from "react-i18next";
import { Container, Dropdown } from "react-bootstrap";
import { Button, Input, Dropdown as AntDropdown } from "antd";
import { Image } from "react-image-and-background-image-fade";
import i18next from "localization/i18next";
import amuseLogo from "assets/images/amuse-logo.svg";
//import giftIcon from "assets/icons/gift-box.svg";
// import giftIcon from "assets/icons/gift-box.gif";
import localizationAr from "assets/icons/localization-ar.svg";
import localizationEn from "assets/icons/localization-en.svg";
import search from "assets/icons/search.svg";
import searchWhite from "assets/icons/search-white.svg";
import NoResultsIcon from "assets/icons/NoResultsIcon.svg";
import ROUTES, { pagesNotHaveNavbar } from "constants/_routes";
import { baseUrl } from "services";
import { LANG_PARAMS } from "localization/translation-params";
import { getSearchResults } from "./network";
import SignInModal from "./SignInModal";
import Skeleton from "react-loading-skeleton";
import SubscripeNowModal from "modules/Subscription/models/SubscripeNowModal/SubscripeNowModal";
import GifPlayer from "react-gif-player";
import gifIcon from "assets/images/giftIcon.gif";
import JoinToCompetitionModal from "./JoinToCompetitionModal";

const Navbar = () => {
  const [searchValue, setSearchValue] = useState("");
  const [searchBarStatus, toggleSearchBarStatus] = useState(false);
  const [searchResults, updateSearchResults] = useState([]);
  const [isModalVisible, toggleModalVisible] = useState(false);
  const location = useLocation();
  const [notInHome, setNotInHome] = useState(location.pathname === "/");
  const [whiteNavbar, setWhiteNavbar] = useState(!location.pathname === "/");
  const [joinToCompetitionModalVisible, setJoinToCompetitionModalVisible] =
    useState(false);
  const [isSubscribeNowModalVisible, updateIsSubscribeNowModalVisible] =
    useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const isAuth = authorization?.token;
  const navbar = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.lang);
  const { t } = useTranslation();

  function onScrollHome() {
    if (location.pathname === "/") {
      if (window.scrollY >= navbar?.current?.clientHeight) {
        setWhiteNavbar(true);
      } else setWhiteNavbar(false);
      // reset because of not in home
    } else {
      setWhiteNavbar(false);
    }
  }

  // useEffect(() => {
  // 	requestForToken();
  // }, []);
  useEffect(() => {
    if (location.pathname === "/") {
      setNotInHome(false);
    } else setNotInHome(true);
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname === "/") {
      document.getElementById("root").style.marginBlock = 0 + "px";
      window.addEventListener("scroll", onScrollHome);
    } else {
      document.getElementById("root").style.marginTop =
        pagesNotHaveNavbar.includes(location.pathname)
          ? 0 + "px"
          : navbar?.current?.clientHeight + 5 + "px";

      window.removeEventListener("scroll", onScrollHome);
      setWhiteNavbar(true);
    }
    return () => {
      window.removeEventListener("scroll", onScrollHome);
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  function changeLangAction() {
    i18next.changeLanguage(lang);
    dispatch(changeLang());
  }

  useEffect(() => {
    if (
      location.pathname !== ROUTES.HOME &&
      location.pathname !== ROUTES.BANNER
    ) {
      setWhiteNavbar(true);
    } else {
      setWhiteNavbar(false);
    }
  }, [location]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          toggleSearchBarStatus(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onSearchMovieClick = ({ key }) => {
    if (isAuth) {
      const selectedVideoDetails = searchResults.filter(
        (result) => result.key === key,
      );
      let data =
        selectedVideoDetails[0].video.videoHeaderId +
        "-" +
        selectedVideoDetails[0].video._id +
        "-" +
        (selectedVideoDetails[0].video.isSeries ? 1 : 0);

      const watchNowDetails = {
        qp: data,
      };
      if (isPaid) {
        navigate({
          pathname: ROUTES.WATCH_NOW,
          search: `?${createSearchParams(watchNowDetails)}`,
        });
      } else {
        updateIsSubscribeNowModalVisible(true);
      }
    } else {
      toggleModalVisible(true);
    }
  };

  function Box({ children }) {
    return (
      <div className='d-flex align-items-center' style={{ height: "100px" }}>
        {children}
      </div>
    );
  }
  const onSearchValueChange = () => {
    let data = {
      lang,
      search: searchValue,
      userAccountId: "",
    };
    const loadingNode = [
      {
        key: 1,
        label: (
          <div className='text-center no-results-found'>
            <Skeleton count={3} wrapper={Box} height={100} />
          </div>
        ),
      },
    ];
    updateSearchResults(loadingNode);

    getSearchResults(
      data,
      (success) => {
        let AllVideos = success.data;
        const newVideosArr = [];
        AllVideos.forEach((video) => {
          newVideosArr.push({
            key: video._id,
            video,
            label: (
              <div className='d-flex align-items-center'>
                <img
                  src={baseUrl + video.imgPath}
                  alt='movie-img'
                  className='movie-cover'
                />
                <div className='title mx-2'>{video.title}</div>
              </div>
            ),
          });
        });
        if (newVideosArr.length) {
          updateSearchResults(newVideosArr);
        } else {
          const noResultsNode = [
            {
              key: 1,
              label: (
                <div className='text-center no-results-found'>
                  <div className='title m-2'>{t("noDataFound")}</div>
                  <img src={NoResultsIcon} alt='no-result' className='my-2' />
                </div>
              ),
            },
          ];
          updateSearchResults(noResultsNode);
        }
      },
      (fail) => {},
    );
  };
  useEffect(() => {
    if (searchValue.length) {
      onSearchValueChange();
    }
    // eslint-disable-next-line
  }, [searchValue]);

  const handleClickOnGifts = () => {
    if (isAuth) {
      navigate(ROUTES.GIFTS);
    } else {
      toggleModalVisible(true);
    }
  };

  const isPaid = authorization?.isPaid?.isPaid;
  if (pagesNotHaveNavbar.includes(location.pathname)) {
    return <></>;
  }
  return (
    <nav
      ref={navbar}
      className={`amuse-nav ${whiteNavbar ? "amuse-nav--white" : ""} ${
        notInHome ? "amuse-nav--white" : ""
      }`}
    >
      <Container className='amuse-nav__container'>
        <div className='amuse-nav__logo'>
          <Link to={ROUTES.HOME}>
            <img src={amuseLogo} alt='amuse the kids' />
          </Link>
        </div>
        <div className='amuse-nav__menus'>
          <ul className='amuse-nav__ul-center'>
            <li className='item'>
              <Link to={ROUTES.HOME}>{t("home")}</Link>
            </li>
            {authorization?.token ? (
              <li className='item'>
                <Link to={ROUTES.MY_LIST}>{t("wishlist")}</Link>
              </li>
            ) : (
              <li className='item'>
                <Link to={ROUTES.SIGNIN}>{t("signin")}</Link>
              </li>
            )}
          </ul>
          <ul
            className={`amuse-nav__ul-end ${
              searchBarStatus ? "d-flex flex-1" : ""
            }`}
            // ref={wrapperRef}
          >
            <li className={"item d-flex flex-1 flex-column"}>
              <AntDropdown
                menu={{
                  items: searchResults,
                  // onClick: onSearchMovieClick,
                }}
                overlayClassName='navbar-search-dropdown py-2'
                open={searchValue.length > 0 && searchBarStatus}
              >
                <div
                  className={`${
                    searchBarStatus
                      ? "amuse-nav__search-container d-flex flex-1 mx-2 px-2 py-1"
                      : ""
                  }`}
                >
                  <Input
                    value={searchValue}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    type='search'
                    className={`${searchBarStatus ? "d-block" : "d-none"}`}
                  />

                  <img
                    src={whiteNavbar || searchBarStatus ? search : searchWhite}
                    alt={"search in amuse"}
                    className={`search-icon`}
                    role='button'
                    onClick={() => toggleSearchBarStatus(true)}
                  />
                </div>
              </AntDropdown>
            </li>

            {authorization?.online?.id && (
              <>
                <li className='item'>
                  <Dropdown
                    className={`amuse-nav__menu ${
                      whiteNavbar ? "white-nav" : ""
                    }`}
                  >
                    <Dropdown.Toggle>
                      {t(LANG_PARAMS.manageAccount)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => navigate(ROUTES.WATCHERS)}>
                        {t(LANG_PARAMS.switchProfile)}
                      </Dropdown.Item>

                      {authorization?.online?.isParent && (
                        <Dropdown.Item
                          onClick={() => navigate(ROUTES.ACCOUNT_PROFILE)}
                        >
                          {t(LANG_PARAMS.profile)}
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item onClick={() => navigate(ROUTES.LOGOUT)}>
                        {t(LANG_PARAMS.logout)}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>

                <li className='item'>
                  <Image
                    wrapperClassName='amuse-nav__avatar'
                    src={baseUrl + authorization?.online?.avatar}
                  />
                </li>
              </>
            )}

            <li className='item'>
              {!authorization?.token || !isPaid ? (
                <Button
                  className='amuse-nav__btn button-primary'
                  onClick={() => {
                    if (!authorization?.token) {
                      navigate(ROUTES.SIGNIN);
                    } else {
                      navigate(ROUTES.SUBSCRIPTION);
                    }
                  }}
                >
                  {t("subscribeNowWithout")}
                </Button>
              ) : null}
            </li>
            <li className='item'>
              {/* <img
								src={giftIcon}
								alt="amuse gifts"
								className="cursor-pointer"
								// onClick={handleClickOnGifts}
							/> */}
              <div>
                <GifPlayer gif={gifIcon} autoplay />
              </div>
            </li>
            <li className='item'>
              {lang === "en" ? (
                <img
                  className={`${lang === "en"}`}
                  src={localizationAr}
                  alt='localization-ar'
                  role={"button"}
                  // onClick={changeLangAction}
                />
              ) : (
                <img
                  src={localizationEn}
                  alt='localization-en'
                  role={"button"}
                  // onClick={changeLangAction}
                />
              )}
            </li>
          </ul>
        </div>
      </Container>

      <SignInModal
        isModalVisible={isModalVisible}
        onCancel={() => {
          toggleModalVisible(false);
        }}
      />
      <SubscripeNowModal
        isModalVisible={isSubscribeNowModalVisible}
        onCancel={() => {
          updateIsSubscribeNowModalVisible(false);
        }}
      />
      <JoinToCompetitionModal
        isModalVisible={joinToCompetitionModalVisible}
        onCancel={() => {
          setJoinToCompetitionModalVisible(false);
        }}
      />
    </nav>
  );
};

export default Navbar;
// // import { useState, useEffect, useRef } from "react";
// // import { useSelector, useDispatch } from "react-redux";
// // import {
// // 	createSearchParams,
// // 	Link,
// // 	useLocation,
// // 	useNavigate,
// // } from "react-router-dom";
// // import { changeLang } from "store/lang";
// // import { useTranslation } from "react-i18next";
// // import { Container, Dropdown } from "react-bootstrap";
// // import { Button, Input, Dropdown as AntDropdown } from "antd";
// // import { Image } from "react-image-and-background-image-fade";
// // import i18next from "localization/i18next";
// // import amuseLogo from "assets/images/amuse-logo.svg";
// // import giftIcon from "assets/icons/gift-box.svg";
// // import localizationAr from "assets/icons/localization-ar.svg";
// // import localizationEn from "assets/icons/localization-en.svg";
// // import search from "assets/icons/search.svg";
// // import searchWhite from "assets/icons/search-white.svg";
// // import NoResultsIcon from "assets/icons/NoResultsIcon.svg";
// // import ROUTES, { pagesNotHaveNavbar } from "constants/_routes";
// // import { baseUrl } from "services";
// // import { LANG_PARAMS } from "localization/translation-params";
// // import { getSearchResults } from "./network";
// // import SignInModal from "./SignInModal";
// // import Skeleton from "react-loading-skeleton";
// // import SubscripeNowModal from "modules/Subscription/models/SubscripeNowModal/SubscripeNowModal";
// // //import { requestForToken } from "../../firebase";
// // const Navbar = () => {
// // 	const [searchValue, setSearchValue] = useState("");
// // 	const [searchBarStatus, toggleSearchBarStatus] = useState(false);
// // 	const { authorization } = useSelector((state) => state.auth);
// // 	const [searchResults, updateSearchResults] = useState([]);
// // 	const [isModalVisible, toggleModalVisible] = useState(false);
// // 	const location = useLocation();
// // 	const [notInHome, setNotInHome] = useState(location.pathname === "/");
// // 	const [whiteNavbar, setWhiteNavbar] = useState(!location.pathname === "/");
// // 	const [isSubscribeNowModalVisible, updateIsSubscribeNowModalVisible] =
// // 		useState(false);
// // 	const isAuth = authorization?.token;
// // 	const navbar = useRef(null);
// // 	const dispatch = useDispatch();
// // 	const navigate = useNavigate();
// // 	const { lang } = useSelector((state) => state.lang);
// // 	const { t } = useTranslation();

// // 	function onScrollHome() {
// // 		if (location.pathname === "/") {
// // 			if (window.scrollY >= navbar?.current?.clientHeight) {
// // 				setWhiteNavbar(true);
// // 			} else setWhiteNavbar(false);
// // 			// reset because of not in home
// // 		} else {
// // 			setWhiteNavbar(false);
// // 		}
// // 	}
// // 	// useEffect(() => {
// // 	// 	requestForToken();
// // 	// }, []);
// // 	useEffect(() => {
// // 		if (location.pathname === "/") {
// // 			setNotInHome(false);
// // 		} else setNotInHome(true);
// // 	}, [location.pathname]);

// // 	useEffect(() => {
// // 		if (location.pathname === "/") {
// // 			document.getElementById("root").style.marginBlock = 0 + "px";
// // 			window.addEventListener("scroll", onScrollHome);
// // 		} else {
// // 			document.getElementById("root").style.marginTop =
// // 				pagesNotHaveNavbar.includes(location.pathname)
// // 					? 0 + "px"
// // 					: navbar?.current?.clientHeight + 5 + "px";

// // 			window.removeEventListener("scroll", onScrollHome);
// // 			setWhiteNavbar(true);
// // 		}
// // 		return () => {
// // 			window.removeEventListener("scroll", onScrollHome);
// // 		};
// // 		// eslint-disable-next-line
// // 	}, [location.pathname]);

// // 	function changeLangAction() {
// // 		i18next.changeLanguage(lang);
// // 		dispatch(changeLang());
// // 	}

// // 	useEffect(() => {
// // 		if (
// // 			location.pathname !== ROUTES.HOME &&
// // 			location.pathname !== ROUTES.BANNER
// // 		) {
// // 			setWhiteNavbar(true);
// // 		} else {
// // 			setWhiteNavbar(false);
// // 		}
// // 	}, [location]);
// // 	const wrapperRef = useRef(null);
// // 	useOutsideAlerter(wrapperRef);

// // 	function useOutsideAlerter(ref) {
// // 		useEffect(() => {
// // 			/**
// // 			 * Alert if clicked on outside of element
// // 			 */
// // 			function handleClickOutside(event) {
// // 				if (ref.current && !ref.current.contains(event.target)) {
// // 					toggleSearchBarStatus(false);
// // 				}
// // 			}
// // 			// Bind the event listener
// // 			document.addEventListener("mousedown", handleClickOutside);
// // 			return () => {
// // 				// Unbind the event listener on clean up
// // 				document.removeEventListener("mousedown", handleClickOutside);
// // 			};
// // 		}, [ref]);
// // 	}

// // 	const onSearchMovieClick = ({ key }) => {
// // 		if (isAuth) {
// // 			const selectedVideoDetails = searchResults.filter(
// // 				(result) => result.key === key
// // 			);
// // 			let data =
// // 				selectedVideoDetails[0].video.videoHeaderId +
// // 				"-" +
// // 				selectedVideoDetails[0].video._id +
// // 				"-" +
// // 				(selectedVideoDetails[0].video.isSeries ? 1 : 0);

// // 			const watchNowDetails = {
// // 				qp: data,
// // 			};
// // 			if (isPaid) {
// // 				navigate({
// // 					pathname: ROUTES.WATCH_NOW,
// // 					search: `?${createSearchParams(watchNowDetails)}`,
// // 				});
// // 			} else {
// // 				updateIsSubscribeNowModalVisible(true);
// // 			}
// // 		} else {
// // 			toggleModalVisible(true);
// // 		}
// // 	};
// // 	function Box({ children }) {
// // 		return (
// // 			<div className="d-flex align-items-center" style={{ height: "100px" }}>
// // 				{children}
// // 			</div>
// // 		);
// // 	}
// // 	const onSearchValueChange = () => {
// // 		let data = {
// // 			lang,
// // 			search: searchValue,
// // 			userAccountId: "",
// // 		};
// // 		const loadingNode = [
// // 			{
// // 				key: 1,
// // 				label: (
// // 					<div className="text-center no-results-found">
// // 						<Skeleton count={3} wrapper={Box} height={100} />
// // 					</div>
// // 				),
// // 			},
// // 		];
// // 		updateSearchResults(loadingNode);

// // 		getSearchResults(
// // 			data,
// // 			(success) => {
// // 				let AllVideos = success.data;
// // 				const newVideosArr = [];
// // 				AllVideos.forEach((video) => {
// // 					newVideosArr.push({
// // 						key: video._id,
// // 						video,
// // 						label: (
// // 							<div className="d-flex align-items-center">
// // 								<img
// // 									src={baseUrl + video.imgPath}
// // 									alt="movie-img"
// // 									className="movie-cover"
// // 								/>
// // 								<div className="title mx-2">{video.title}</div>
// // 							</div>
// // 						),
// // 					});
// // 				});
// // 				if (newVideosArr.length) {
// // 					updateSearchResults(newVideosArr);
// // 				} else {
// // 					const noResultsNode = [
// // 						{
// // 							key: 1,
// // 							label: (
// // 								<div className="text-center no-results-found">
// // 									<div className="title m-2">{t("noDataFound")}</div>
// // 									<img src={NoResultsIcon} alt="no-result" className="my-2" />
// // 								</div>
// // 							),
// // 						},
// // 					];
// // 					updateSearchResults(noResultsNode);
// // 				}
// // 			},
// // 			(fail) => {
// //
// // 			}
// // 		);
// // 	};
// // 	useEffect(() => {
// // 		if (searchValue.length) {
// // 			onSearchValueChange();
// // 		}
// // 		// eslint-disable-next-line
// // 	}, [searchValue]);

// // 	const handleClickOnGifts = () => {
// // 		if (isAuth) {
// // 			navigate(ROUTES.GIFTS);
// // 		} else {
// // 			toggleModalVisible(true);
// // 		}
// // 	};
// // 	const isPaid = authorization?.isPaid?.isPaid;
// // 	if (pagesNotHaveNavbar.includes(location.pathname)) {
// // 		return <></>;
// // 	}
// // 	return (
// // 		<nav
// // 			ref={navbar}
// // 			className={`amuse-nav ${whiteNavbar ? "amuse-nav--white" : ""} ${
// // 				notInHome ? "amuse-nav--white" : ""
// // 			}`}>
// // 			<Container className="amuse-nav__container">
// // 				<div className="amuse-nav__logo">
// // 					<Link to={ROUTES.HOME}>
// // 						<img src={amuseLogo} alt="amuse the kids" />
// // 					</Link>
// // 				</div>
// // 				<div className="amuse-nav__menus">
// // 					<ul className="amuse-nav__ul-center">
// // 						<li className="item">
// // 							<Link to={ROUTES.HOME}>{t("home")}</Link>
// // 						</li>
// // 						{authorization?.token ? (
// // 							<li className="item">
// // 								<Link to={ROUTES.MY_LIST}>{t("wishlist")}</Link>
// // 							</li>
// // 						) : (
// // 							<li className="item">
// // 								<Link to={ROUTES.SIGNIN}>{t("signin")}</Link>
// // 							</li>
// // 						)}
// // 					</ul>
// // 					<ul
// // 						className={`amuse-nav__ul-end ${
// // 							searchBarStatus ? "d-flex flex-1" : ""
// // 						}`}
// // 						ref={wrapperRef}>
// // 						<li className={"item d-flex flex-1 flex-column"}>
// // 							<AntDropdown
// // 								menu={{
// // 									items: searchResults,
// // 									onClick: onSearchMovieClick,
// // 								}}
// // 								overlayClassName="navbar-search-dropdown py-2"
// // 								open={searchValue.length > 0 && searchBarStatus}>
// // 								<div
// // 									className={`${
// // 										searchBarStatus
// // 											? "amuse-nav__search-container d-flex flex-1 mx-2 px-2 py-1"
// // 											: ""
// // 									}`}>
// // 									<Input
// // 										value={searchValue}
// // 										onChange={(e) => {
// // 											setSearchValue(e.target.value);
// // 										}}
// // 										type="search"
// // 										className={`${searchBarStatus ? "d-block" : "d-none"}`}
// // 									/>

// // 									<img
// // 										src={whiteNavbar || searchBarStatus ? search : searchWhite}
// // 										alt={"search in amuse"}
// // 										className={`search-icon`}
// // 										role="button"
// // 										onClick={() => toggleSearchBarStatus(true)}
// // 									/>
// // 								</div>
// // 							</AntDropdown>
// // 						</li>

// // 						{authorization?.online?.id && (
// // 							<>
// // 								<li className="item">
// // 									<Dropdown
// // 										className={`amuse-nav__menu ${
// // 											whiteNavbar ? "white-nav" : ""
// // 										}`}>
// // 										<Dropdown.Toggle>
// // 											{t(LANG_PARAMS.manageAccount)}
// // 										</Dropdown.Toggle>
// // 										<Dropdown.Menu>
// // 											<Dropdown.Item onClick={() => navigate(ROUTES.WATCHERS)}>
// // 												{t(LANG_PARAMS.switchProfile)}
// // 											</Dropdown.Item>

// // 											{authorization?.online?.isParent && (
// // 												<Dropdown.Item
// // 													onClick={() => navigate(ROUTES.ACCOUNT_PROFILE)}>
// // 													{t(LANG_PARAMS.profile)}
// // 												</Dropdown.Item>
// // 											)}

// // 											<Dropdown.Item onClick={() => navigate(ROUTES.LOGOUT)}>
// // 												{t(LANG_PARAMS.logout)}
// // 											</Dropdown.Item>
// // 										</Dropdown.Menu>
// // 									</Dropdown>
// // 								</li>

// // 								<li className="item">
// // 									<Image
// // 										wrapperClassName="amuse-nav__avatar"
// // 										src={baseUrl + authorization?.online?.avatar}
// // 									/>
// // 								</li>
// // 							</>
// // 						)}

// // 						<li className="item">
// // 							{!authorization?.token || !isPaid ? (
// // 								<Button
// // 									className="amuse-nav__btn button-primary"
// // 									onClick={() => {
// // 										if (!authorization?.token) {
// // 											navigate(ROUTES.SIGNIN);
// // 										} else {
// // 											navigate(ROUTES.SUBSCRIPTION);
// // 										}
// // 									}}>
// // 									{t("subscribeNowWithout")}
// // 								</Button>
// // 							) : null}
// // 						</li>
// // 						<li className="item">
// // 							<img
// // 								src={giftIcon}
// // 								alt="amuse gifts"
// // 								className="cursor-pointer"
// // 								onClick={handleClickOnGifts}
// // 							/>
// // 						</li>
// // 						<li className="item">
// // 							{lang === "en" ? (
// // 								<img
// // 									className={`${lang === "en"}`}
// // 									src={localizationAr}
// // 									alt="localization-ar"
// // 									role={"button"}
// // 									onClick={changeLangAction}
// // 								/>
// // 							) : (
// // 								<img
// // 									src={localizationEn}
// // 									alt="localization-en"
// // 									role={"button"}
// // 									onClick={changeLangAction}
// // 								/>
// // 							)}
// // 						</li>
// // 					</ul>
// // 				</div>
// // 			</Container>

// // 			<SignInModal
// // 				isModalVisible={isModalVisible}
// // 				onCancel={() => {
// // 					toggleModalVisible(false);
// // 				}}
// // 			/>
// // 			<SubscripeNowModal
// // 				isModalVisible={isSubscribeNowModalVisible}
// // 				onCancel={() => {
// // 					updateIsSubscribeNowModalVisible(false);
// // 				}}
// // 			/>
// // 		</nav>
// // 	);
// // };

// // export default Navbar;
