import { Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import closeIcon from "assets/icons/close.svg";
import { useTranslation } from "react-i18next";

function CannotChangePlanModal({ open, onCancel }) {
  const { t, i18n } = useTranslation();

  return (
    <Modal
      centered
      open={open}
      width={498}
      onCancel={onCancel}
      className='cannotChangePlanModal'
      footer={false}
      closeIcon={
        <img
          src={closeIcon}
          alt='icon'
          className=''
          width='20px'
          height='20px'
        />
      }
    >
      <div className=''>
        <p className='para'>
          {t(
            "you are already a subscripted user and can’t change your plan unless your current plan is ended or subscription is canceled.",
          )}
        </p>
      </div>
    </Modal>
  );
}

export default CannotChangePlanModal;
