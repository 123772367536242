import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import {
  getRelatedSeriesSessons,
  getRelatedMovieParts,
  checkVideoFree,
} from "./network";
import WebsiteTitle from "helpers/WebsiteTitle";
import VideoDetailsWrapper from "./components/VideoDetialsWrapper";
import Sidebar from "./components/Sidebar";
import { toastError } from "helpers/toasters";
import { Button } from "antd";
import { useTranslation } from "react-i18next";
import AmuseLogo from "../../assets/images/amuse-logo.svg";
import mobileIcon from "../../assets/icons/getTheAppIcon.svg";
import googleplay from "../../assets/icons/googleplay.svg";
import appstore from "../../assets/icons/appstore.svg";
import { isMobile, isAndroid } from "react-device-detect";

const WatchNow = () => {
  const [relatedPartsLoading, setRelatedPartsLoading] = useState(true);
  const [relatedPartsData, setRelatedPartsData] = useState([]);
  const [selectedPart, setSelectedPart] = useState(null);
  const [isEpisode, setIsEpisode] = useState(false);
  const [currentSeasonId, updateCurrentSeasonId] = useState(null);
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state?.auth);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [isFree, setIsFree] = useState(false);
  const [hasFreeVideo, setHasFreeVideo] = useState(true);
  const params = Object.fromEntries([...searchParams]);
  const urlparams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const isOffline = !urlparams.get("isOffline")
    ? false
    : urlparams.get("isOffline");

  const [videoHeaderId, videoId, isSeries, showFullScreen] =
    params?.qp?.split?.("-");

  const isSubscribed = authorization?.isPaid?.isPaid;

  useEffect(() => {
    // Scroll to the top when location changes (component mounts or navigates)
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    setIsEpisode(!isSeries);
  }, [isSeries]);
  useEffect(() => {
    if (navigator.onLine) {
      checkVideoFree(videoId, (success) => {
        if (success.data.isFree) {
          setIsFree(success.data.isFree);
        }
        // else {
        //   navigate("/404", { replace: true })
        // }
      });
    }
  }, []);
  useEffect(() => {
    const payload = {
      lang,
      videoHeaderId,
      videoId,
      // isFree,
    };

    if (navigator.onLine) {
      if (Number(isSeries)) {
        getRelatedSeriesSessons(
          payload,
          (success) => {
            setRelatedPartsData(success.data);
            setRelatedPartsLoading(false);
          },
          (fail) => {
            if (fail?.data?.err_msg) {
              toastError(fail.data.err_msg);
              setRelatedPartsLoading(false);
            } else {
              toastError();
            }
          }
        );
      } else {
        getRelatedMovieParts(
          payload,
          (success) => {
            setSelectedPart(success.data[0]);
            setRelatedPartsData(success.data);
            const foundFreeVideo = success.data.find(
              (video) => video.isFree === true
            );
            setHasFreeVideo(foundFreeVideo);
            setRelatedPartsLoading(false);
          },
          (fail) => {
            if (fail?.data?.err_msg) {
              toastError(fail.data.err_msg);
              setRelatedPartsLoading(false);
            } else {
              toastError();
              setRelatedPartsLoading(false);
            }
          }
        );
      }
    }

    // eslint-disable-next-line
  }, [isSeries, lang, isFree, videoHeaderId]);

  const getCurrentSeasonId = (currentSeasonId) => {
    updateCurrentSeasonId(currentSeasonId);
  };

  return (
    // <div style={{ marginTop: "85px" }}>
    <>
      {isMobile ? (
        <div className="watch-now-onMobile">
          <div className="mt-4 getTheApp_title text-center">
            {t("getTheApp")}
          </div>
          <div className="text-center mt-4">
            <img src={mobileIcon} alt="mobileIcon" />
            <a
              href={
                isAndroid
                  ? "https://play.google.com/store/apps/details?id=com.amusethekids&pli=1"
                  : "https://apps.apple.com/eg/app/amuse-the-kidz/id6447695668"
              }
              className="my-4 d-block"
            >
              {isAndroid ? (
                <img src={googleplay} alt="googleplay" />
              ) : (
                <img src={appstore} alt="appstore" />
              )}
            </a>
          </div>
        </div>
      ) : (
        <>
          <WebsiteTitle title={"Watch Now"} />
          <section className="main-section subscribtion">
            <div className="subscribtion__content">
              {/* {!isSubscribed && !hasFreeVideo && (
                <div className='subscribtion__view '>
                  <div className='subscribtion__logo my-4'>
                    <Link to='/'>
                      <img
                        className='subscribtion__logo-img my-4'
                        src={AmuseLogo}
                        alt='amuse the kids'
                      />
                    </Link>
                  </div>
                  <div className='subscribtion__title my-4'>
                    <p className='subscribtion__parag'>{t("subscibeTitle")}</p>
                  </div>
                  <div className='subscribtion__btnn my-4'>
                    <Button className='button-primary' htmlType='submit'>
                      {t("subscribeNow")}
                    </Button>
                  </div>
                </div>
              )} */}
              {(hasFreeVideo || authorization?.online?.id) && (
                <VideoDetailsWrapper
                  seriesOrParts={relatedPartsData}
                  data={{
                    videoId,
                    videoHeaderId,
                    isSeries: Number(isSeries),
                    isFree,
                    isSubscribed,
                  }}
                  relatedPartsSeries={relatedPartsData}
                  getCurrentSeasonId={getCurrentSeasonId}
                />
              )}
              {/* {(isSubscribed || hasFreeVideo) && ( */}
              <Sidebar
                isDataLoading={relatedPartsLoading}
                data={relatedPartsData}
                setSelectedVideo={setSelectedPart}
                currentSeasonId={currentSeasonId}
              />
              {/* )} */}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default WatchNow;
