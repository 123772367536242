import { baseUrl } from "services";

export const createBackgroundImage = (imgPath, isOffline) => {
  if (isOffline) {
    return encodeURI(imgPath)?.replace(/%5C/g, "//");
  } else {
    // Handle blob-type images to convert them to URL
    if (imgPath instanceof Blob) {
      return URL.createObjectURL(imgPath);
    } else {
      return encodeURI(baseUrl + imgPath)?.replace(/%5C/g, "//");
    }
  }
};
