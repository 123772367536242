import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource } from "services";

export function getBannerDetails(
  { lang, videoHeaderId, isSeries, userAccountId },
  onSuccess,
  onFail
) {
  const bannerParams = {
    isSeries,
    videoHeaderId,
    ...(userAccountId && { userAccountId }),
  };

  const path = PATHS(PATHS_PARAMS.getBannerDetails, lang, bannerParams);

  getResource(path, onSuccess, onFail);
}

export function getRelatedMovieParts(
  { lang, videoHeaderId, videoId },
  onSuccess,
  onFail
) {
  const moviesPartsParams = {
    videoHeaderId,
    videoId,
  };
  const path = PATHS(PATHS_PARAMS.getMovieParts, lang, moviesPartsParams);

  getResource(path, onSuccess, onFail);
}
export function getRelatedSeriesSessons(
  { lang, videoHeaderId, videoId },
  onSuccess,
  onFail
) {
  const seriesSessonsParams = {
    videoHeaderId,
    videoId,
  };
  const path = PATHS(PATHS_PARAMS.getSeriesSessons, lang, seriesSessonsParams);

  getResource(path, onSuccess, onFail);
}

export function getBannerById({ lang, bannerId, userId }, onSuccess, onFail) {
  // ?userId=62c60cf284a63990583d4c72&lang=en&bannerId=62f10e54f95695c24891db39
  const bannerParams = {
    ...(userId && { userId }),
    bannerId,
  };

  const path = PATHS(PATHS_PARAMS.getBannerById, lang, bannerParams);

  getResource(path, onSuccess, onFail);
}
