import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getPrivacyPolicy } from "./network";

function PrivacyKorean() {
  const [privacy, updatePrivacy] = useState("");
  const { i18n, t } = useTranslation();
  useEffect(() => {
    getPrivacyPolicy(
      "ko",
      (success) => {
        updatePrivacy(success.data[0].privacyPolicy["ko"]);
      },
      (fail) => {}
    );
  }, [i18n.language]);
  const privacyChunks = privacy.split("\n");
  return (
    <div className="p-2" dir="ltr">
      <div className="privacyPolicy mt-4 px-4">
        <h5 className="title text-center my-2">개인정보 보호정책</h5>
        <div>
          {privacyChunks.map((sentence) => {
            return (
              <>
                <div>{sentence}</div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default PrivacyKorean;
