import React from "react";
import { Col } from "antd";
import { Image } from "react-image-and-background-image-fade";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { baseUrl } from "services";

const VideoCast = ({ isLoading, castData }) => {
  const { t } = useTranslation();

  return (
    <div className={castData?.length ? "video-details__cast" : "d-none"}>
      <header className="video-details__cast__header">
        <h5>{t("cast")}</h5>
      </header>
      <div className="cast__members">
        {isLoading
          ? [null, null, null, null].map((item, _) => (
              <Col className="cast__member" key={_}>
                <Skeleton
                  className="mb-2"
                  width={"7.5rem"}
                  height={"7.5rem"}
                  circle
                />
                <Skeleton width={"5rem"} height={"1rem"} />
              </Col>
            ))
          : castData?.map((cast) => (
              <div className="cast__member" key={cast.id}>
                <Image
                  wrapperClassName="image-wrapper"
                  src={baseUrl + cast.imgPath}
                  alt={cast.name}
                  title={cast.name}
                />
                <h6>{cast.name}</h6>
              </div>
            ))}
      </div>
    </div>
  );
};

export default VideoCast;
