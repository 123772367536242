import { useSelector } from "react-redux";

import bw10Icon from "assets/video-controllers/bw10.svg";
import fw10Icon from "assets/video-controllers/fw10.svg";
import pauseIcon from "assets/video-controllers/pause.svg";
import playIcon from "assets/video-controllers/play.svg";
import { useState } from "react";
import ReplayIcon from "assets/video-controllers/ReplayIcon";

function CenterControllers({
  videoRef,
  isPlaying,
  setIsPlaying,
  isEnded,
  skipBackward,
  skipForward,
  isBuffering,
  handleReplayVideo,
}) {
  const [backWard, setBackWard] = useState(false);
  const [forWard, setForWard] = useState(false);
  const [playPause, setPlayPause] = useState(false);

  const { dir } = useSelector((state) => state.lang);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    } else {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const playStyle = {
    position: "absolute",
    top: "50%",
    transition: ".15s",
  };

  return (
    <>
      {!isEnded && (
        <img
          style={{
            ...playStyle,
            transform: `translateY(-50%) ${
              backWard ? "scale(1.12)" : "scale(1)"
            }`,
            // left: "25%",
            left: "20%",
          }}
          role="button"
          onClick={skipBackward}
          src={bw10Icon}
          alt="plus 10 seconds"
          onMouseEnter={() => setBackWard(true)}
          onMouseLeave={() => setBackWard(false)}
        />
      )}

      {!isEnded && (
        <img
          style={{
            ...playStyle,
            left: "50%",
            transform: `translate(-50%, -50%) ${
              playPause ? "scale(1.12)" : "scale(1)"
            }`,
          }}
          className="controllers__playing"
          role="button"
          src={isPlaying ? pauseIcon : playIcon}
          alt="pause/play"
          onClick={handlePlayPause}
          onMouseEnter={() => setPlayPause(true)}
          onMouseLeave={() => setPlayPause(false)}
        />
      )}

      {isEnded && (
        <span
          style={{
            ...playStyle,
            left: "50%",
            transform: `translate(-50%, -50%)`,
            cursor: "pointer",
          }}
          onClick={handleReplayVideo}
        >
          <ReplayIcon />
        </span>
      )}

      {!isEnded && (
        <img
          style={{
            ...playStyle,
            // right: "25%",
            right: "20%",
            transform: `translateY(-50%) ${
              forWard ? "scale(1.12)" : "scale(1)"
            }`,
          }}
          role="button"
          onClick={skipForward}
          src={fw10Icon}
          alt="plus 10 seconds"
          onMouseEnter={() => setForWard(true)}
          onMouseLeave={() => setForWard(false)}
        />
      )}
    </>
  );
}

export default CenterControllers;
