import loadingSpinner from "../../assets/animated-images/loading_ring_medium.json";
import Lottie from "react-lottie";

const Spinner = ({
  width = 120,
  height = 120,
  isNotFullPage,
  submitButton,
  customStyle,
}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingSpinner,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const submittingStyle = {
    position: "absolute",
    top: "50%", // Adjust as needed
    left: "50%", // Adjust as needed
    transform: "translate(-50%, -62%)",
  };
  const mergedStyle = submitButton
    ? { ...customStyle, ...submittingStyle }
    : customStyle;

  return (
    <div className={`${!isNotFullPage ? "spinner" : ""} fade-in`}>
      <Lottie
        isClickToPauseDisabled={true}
        options={defaultOptions}
        width={width}
        height={height}
        style={mergedStyle}
      />
    </div>
  );
};

export default Spinner;
