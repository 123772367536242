import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTermsAndConditions } from "./network";

function TermsAndConditions() {
  const [terms, updateTerms] = useState("");
  const { i18n, t } = useTranslation();
  useEffect(() => {
    if (i18n.language) {
      getTermsAndConditions(
        i18n.language,
        (success) => {
          updateTerms(success.data[0].termsAndConditions[i18n.language]);
        },
        (fail) => {}
      );
    }
  }, [i18n.language]);
  const termsChunks = terms.split("\n");
  return (
    <div className="termsAndConditions mt-4 px-4">
      <h5 className="title text-center my-2">{t("termsAndConditions")}</h5>
      <div>
        {termsChunks.map((sentence) => {
          return (
            <>
              <div>{sentence}</div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default TermsAndConditions;
