import React from "react";
import bg from "assets/icons/static_background.svg";
import laptop from "assets/icons/amuse_laptop.png";
import devices from "assets/icons/static_devices.svg";
import momKid from "assets/icons/static_mom_kid.svg";

function Content() {
	const landingSectionStyles = {
		backgroundImage: `url(${bg})`,
		backgroundSize: "cover",
		color: "#364067",
		textAlign: "center",
		padding: "0.75rem",
	};
	return (
		<div className="static__content">
			<div style={landingSectionStyles}>
				<div className="py-4">
					<div className="fw-700 f-31 p-4">
						Enjoy the best VOD service for kids with premium originals,
						exclusive series, movies & much more.
					</div>
				</div>

				<img src={laptop} alt="laptop" className="laptop" />
			</div>
			<div className="p-4">
				<div className="d-flex align-items-center p-4 flex-wrap">
					<img src={devices} alt="devices" className="me-4" />
					<div className="fw-700 f-31 d-flex flex-1">
						Enjoy watching your favorite movies and series in any device
						Website, tablet, mobile app and TV
					</div>
				</div>
			</div>
			<div className="p-4 kidMomSection">
				<div className="d-flex align-items-center p-4 flex-wrap">
					<div className="fw-700 f-31 d-flex flex-1">
						Keep your kids safe with our intuitive parental controls
					</div>
					<img src={momKid} alt="momKid" className="me-4" />
				</div>
			</div>
		</div>
	);
}

export default Content;
