import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { deleteResource, getResource, postResource } from "services";

export function getPackages(lang, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getPackages, lang, lang);
  getResource(path, onSuccess, onFail);
}

export function fawryPay(data, queryData, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.fawryPay, queryData, queryData);
  postResource(path, data, onSuccess, onFail);
}

export function getMyPackages(lang, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getMyPackages, lang, lang);
  getResource(path, onSuccess, onFail);
}
export function getSavedCards(onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getSavedCard);
  getResource(path, onSuccess, onFail);
}
export function deleteSavedCard(data, onSuccess, onFail) {
  const { cardId } = data;
  const path = PATHS(PATHS_PARAMS.deleteSavedCard, null, { cardId });
  deleteResource(path, onSuccess, onFail);
}

export function payByCreditcard(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.placeorder);
  postResource(path, data, onSuccess, onFail);
}
export function setDefaultPaymenCard(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.setDefaultCard, null, data);
  postResource(path, onSuccess, onFail);
}

export function getFreeDaysForUser(onSuccess, onFail) {
  // const path = `api/userAccounts/user`;
  const path = PATHS(PATHS_PARAMS.getUserFreeDays);
  getResource(path, onSuccess, onFail);
}

export const enterCouponCode = (data, onSuccess, onFail) => {
  const path = PATHS(PATHS_PARAMS.enterCouponCode);
  postResource(path, data, onSuccess, onFail);
};
