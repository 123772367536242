import React, { useEffect } from "react";
import bw10Icon from "assets/video-controllers/bw10.svg";
import fw10Icon from "assets/video-controllers/fw10.svg";
import replayIcon from "assets/video-controllers/replay-icon.svg";
import pauseIcon from "assets/video-controllers/pause.svg";
import playIcon from "assets/video-controllers/play.svg";
import { useSelector } from "react-redux";
import { Button } from "antd";
import { BsFillReplyAllFill } from "react-icons/bs";
import ReplayIcon from "assets/video-controllers/ReplayIcon";
const CenterControls = ({
  isEnded,
  isPlaying,
  setIsPlaying,
  handlePlayPause,
  videoRef,
  handleReplayVideo,
}) => {
  const { dir } = useSelector((state) => state.lang);

  const seekingTime = 10;

  function handlePlus10Sec() {
    if (
      videoRef?.current?.getDuration() - videoRef?.current?.getCurrentTime() >
      10
    ) {
      videoRef?.current?.seekTo(
        videoRef?.current?.getCurrentTime() + seekingTime,
        "seconds",
      );
      // audioElement.currentTime = videoElement.currentTime;
    }
    if (
      videoRef?.current?.getDuration() - videoRef?.current?.getCurrentTime() <=
      10
    ) {
      videoRef?.current?.seekTo(videoRef?.current?.getDuration(), "seconds");
      // audioElement.currentTime = videoElement.currentTime;
    }
  }

  function handleMinus10Sec() {
    if (videoRef?.current?.getCurrentTime() > 10) {
      videoRef?.current?.seekTo(
        videoRef?.current?.getCurrentTime() - seekingTime,
        "seconds",
      );
      return;
      // audioElement.currentTime = videoElement.currentTime;
    }
    if (videoRef?.current?.getCurrentTime() <= 10) {
      videoRef?.current?.seekTo(0, "seconds");
      // audioElement.currentTime = videoElement.currentTime;
    }
  }

  useEffect(() => {
    const handleKeydown = (e) => {
      // Check if the spacebar is pressed and the video player component is focused
      if (e.code === "Space" && document.activeElement.tagName !== "INPUT") {
        e.preventDefault();
        handlePlayPause();
      }
      // Add other keydown event handlers here
    };

    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [isPlaying]);

  return (
    <div
      className='video-controller__upper__center'
      style={{ flexDirection: dir === "rtl" ? "row-reverse" : "row" }}
    >
      {!isEnded && (
        <img
          src={bw10Icon}
          role='button'
          onClick={handleMinus10Sec}
          alt='minus 10 seconds'
        />
      )}

      {!isEnded && (
        <img
          role='button'
          src={isPlaying ? pauseIcon : playIcon}
          alt='pause/play'
          onClick={handlePlayPause}
        />
      )}
      {/* 
      {isEnded && (
        <span onClick={handleReplayVideo}>
          <ReplayIcon />
        </span>
      )} */}

      {!isEnded && (
        <img
          role='button'
          onClick={handlePlus10Sec}
          src={fw10Icon}
          alt='plus 10 seconds'
        />
      )}
    </div>
  );
};

export default CenterControls;
