import { useState, useEffect } from "react";
import { Button } from "antd";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import amuseLogo from "assets/images/amuse-logo.svg";
import { useTranslation } from "react-i18next";
import {
  confirmCode,
  ConfirmCodeForPinCode,
  forgetPassword,
  forgetThePinCode,
} from "../network";
import Countdown from "antd/lib/statistic/Countdown";
import { toastError } from "helpers/toasters";
import ROUTES from "constants/_routes";
import Spinner from "modules/Shared/Spinner";
import { OTPInputComponent } from "../components/OTPInput/Index";

const ForgetPinCode = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState("");
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [showTimer, setShowTimer] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { phone } = location?.state;
  const lastTwoDigits = phone.toString().slice(-2);
  const [timer, setTimer] = useState(Date.now() + 1000 * 5 * 12);

  useEffect(() => {
    if (location?.state?.phone) {
      setShowTimer(true);
    } else {
      navigate(ROUTES.VERIFY_PARENTAL_CODE);
    }
  }, [location, navigate]);

  useEffect(() => {
    if (code?.length === 5) setShowErrMsg(false);
  }, [code?.length]);

  function onSubmit() {
    if (code.length === 5) {
      setLoading(true);
      let data = {
        code,
      };
      ConfirmCodeForPinCode(
        data,
        (success) => {
          setLoading(false);
          setCode("");
          if (success.isSuccess && success.data.isConfirmed) {
            navigate(ROUTES.CREATE_PARENTAL_CODE, {
              state: {
                phone: location.state.phone,
                mode: "forgetPinCode",
              },
            });
          }
        },
        (fail) => {
          setLoading(false);
          setCode("");

          if (fail.data.err_msg) {
            toastError(fail.data.err_msg);
          }
        },
      );
    } else if (code.length === 0) {
      toastError(t("emptyOtpCode"));
    } else {
      // setShowErrMsg(true);
      toastError(t("forgetOtpCodeError"));
    }
  }

  function onfinishTimer() {
    setShowTimer(false);
  }

  function resendCode() {
    setIsLoading(true);
    setCode("");
    forgetThePinCode(
      (success) => {
        if (success?.isSuccess) {
          setShowTimer(true);
          setTimer(Date.now() + 1000 * 5 * 12);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          toastError(success.err_msg);
        }
      },
      (fail) => {
        if (fail.data.err_msg) {
          setIsLoading(false);
          toastError(fail.data.err_msg);
        } else {
          toastError();
          setIsLoading(false);
        }
      },
    );
  }

  return (
    <>
      {isLoading && <Spinner />}
      <section className='auth main-section '>
        <Container>
          <div className='logo-center mt-4'>
            <Link to='/'>
              <img src={amuseLogo} alt='amuse the kids' />
            </Link>
          </div>
          <div className='auth__orange-title text-orange-header text-center'>
            <span>{t("enter5digitsOtpNumber")}</span> <br />
            <span dir='ltr'>{`*********${lastTwoDigits}`}</span>
          </div>
          <div className='box my-2'>
            {" "}
            <Countdown
              className='text-center auth__timer'
              value={timer}
              format='mm:ss'
              onFinish={onfinishTimer}
            />
          </div>

          <div className='otp__container  text-center mt-2'>
            <div className='d-flex justify-content-center' dir='ltr'>
              <OTPInputComponent
                length={5}
                isNumberInput
                value={code}
                autoFocus
                className='otpContainer'
                inputClassName='otpInput'
                onChangeOTP={setCode}
              />
              {/* <OtpInput
                className='otp__single-container mb-2'
                value={code}
                onChange={setCode}
                isInputNum
                numInputs={5}
                shouldAutoFocus
              /> */}
            </div>
            {/* <div
            className="error-message"
            style={{
              opacity: showErrMsg ? 1 : 0,
            }}
          >
            {t("forgetOtpCodeError")}
          </div> */}
          </div>
          <div className='text-center'>
            <Button
              loading={loading}
              className='button-primary button-primary--forget-pin-page  '
              htmlType='submit'
              onClick={onSubmit}
            >
              {t("confirmCode")}
            </Button>
            <div className='auth__sub-text'>
              <span>{t("didntReceiveCode")}</span>
              {/* <button className="resend-code-button"> */}
              <span
                role={showTimer ? "" : "button"}
                disabled={showTimer}
                onClick={showTimer ? null : resendCode}
                className={`auth__forget-pin-code-resend-code ${
                  showTimer ? "resend-code-inActive" : ""
                }`}
              >
                {t("resendOtpCode")}
              </span>
              {/* </button> */}
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ForgetPinCode;
