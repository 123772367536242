import ROUTES from "constants/_routes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getOperatorDailyCost,
  getQueryParamsForTpayScriptUrl,
  sendSmsToSubscribedUser,
  subscriptionCallback,
} from "./network";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "./CustomSelect";
import { startedTime } from "store/timeSlice";
import { toastError, toastSuccess } from "helpers/toasters";
import { Button } from "antd";
import axios from "axios";
import { baseUrl } from "services";
import { formatTime } from "./TpayVerification";

const TPAY_ORANGE_CODE = "60201";
const TPAY_VODAFONE_CODE = "60202";
const TPAY_ETISALAT_CODE = "60203";
const TPAY_WE_CODE = "60204";

const TPAY_ORANGE_CATALOG = "AMUSE-Orange-Egypt";
const TPAY_VODAFONE_CATALOG = "AMUSE-Vodafone-Egypt";
const TPAY_ETISALAT_CATALOG = "AMUSE-Etisalat-Egypt";
const TPAY_WE_CATALOG = "AMUSE-We-Egypt";
const _subscriptionPlanId = 2356; // integr
const _productSku = "AMUZE_THE_KIDZ@GIFT_MODULE"; // string
var _enriched = ""; // value will get assigned when "TPay.HeaderEnrichment.enriched()" function called.
var _operatorCode = ""; // will get assigned a value when user choose operator

const options = [
  { label: "Vodafone", value: TPAY_VODAFONE_CODE },
  { label: "We", value: TPAY_WE_CODE },
  { label: "Etisalat", value: TPAY_ETISALAT_CODE },
  { label: "Orange", value: TPAY_ORANGE_CODE },
];

const operatorCompatibility = [
  { label: "Vodafone", value: "010" },
  { label: "We", value: "015" },
  { label: "Etisalat", value: "011" },
  { label: "Orange", value: "012" },
];

function TpaySubscription() {
  const { lang, dir } = useSelector((state) => state.lang);

  const { t } = useTranslation();
  const { authorization } = useSelector((state) => {
    return state.auth;
  });

  const { subscriptionRequest } = useSelector((state) => {
    return state.subscriptionRequest;
  });

  const currentTimeInSeconds = Math.floor(Date.now() / 1000);
  const location = useLocation();

  const dispatch = useDispatch();
  const [selected, setSelected] = useState("");

  const navigate = useNavigate();

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [subscriptionCost, setSubscriptionCost] = useState(null);

  const [operatorCode, setOperatorCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [subsCostData, setSubsCostData] = useState([]);
  const [phoneNumberError, setPhoneNumberError] = useState(""); // Add state for error message
  const [showTimer, setShowTimer] = useState(false);
  console.log("🚀 ~ TpaySubscription ~ showTimer:", showTimer);
  // const phoneNumberPattern = /^201\d{9}$/;
  const isInputDisabled = !operatorCode;
  const inputClass = isInputDisabled ? "disabled-input" : "";
  const phoneNumberPattern = /^(011|012|010|015)\d{8}$/;

  const dropdownChangeHanlder = (selectedLabel, selectedValue) => {
    setPhoneNumber("");
    setPhoneNumberError("");
    setSelected(selectedLabel);
    setOperatorCode(selectedValue);
    // const operatorCost = getSelectedOperatorCost(selectedValue, allOperators);
    // setOperatorCost(operatorCost)
    if (selectedValue === "60201") {
      set_productCatalog(TPAY_ORANGE_CATALOG);
    } else if (selectedValue === "60202") {
      set_productCatalog(TPAY_VODAFONE_CATALOG);
    } else if (selectedValue === "60203") {
      set_productCatalog(TPAY_ETISALAT_CATALOG);
    } else if (selectedValue === "60204") {
      set_productCatalog(TPAY_WE_CATALOG);
    }

    // setOperator();
  };

  const handleKeyPress = (e) => {
    const functionalKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Delete",
      "Tab",
    ];
    const allowedActions = ["c", "x", "v", "z"]; // Keys associated with copy, cut,undo, and paste

    // Allow Ctrl + C (Copy), Ctrl + X (Cut), Ctrl + V (Paste) and functional keys
    if (
      (e.ctrlKey || e.metaKey) &&
      allowedActions.includes(e.key.toLowerCase())
    ) {
      return; // Allow Copy, Cut, Paste actions
    }

    // Allow functional keys and Backspace
    if (
      functionalKeys.includes(e.key) || // Allow functional keys
      (e.key >= "0" && e.key <= "9") // Allow numeric keys
    ) {
      return; // Allow these keys without preventing default action
    }

    e.preventDefault(); // Prevent entering non-numeric characters for other keys
  };

  const phoneNumberhandler = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
    setPhoneNumber(value);

    let errorMessage = "";

    const checkCompatibility = (operator) => {
      let code = "010";
      if (operator === "We") {
        code = "015";
      } else if (operator === "Etisalat") {
        code = "011";
      } else if (operator === "Orange") {
        code = "012";
      }

      if (
        selected === operator &&
        value.slice(0, 3) !== code &&
        value.length >= 3
      ) {
        return true;
        // setPhoneNumberError("operatorCompatibility");
        // setIsPhoneNumberValid(false);
      } else {
        return false;
      }
    };

    if (value.length === 11) {
      if (phoneNumberPattern.test(value) && !checkCompatibility(selected)) {
        setPhoneNumberError(""); // Clear the error message
        setIsPhoneNumberValid(true); // Phone number is valid
        checkCompatibility(selected);
      } else if (
        phoneNumberPattern.test(value) &&
        checkCompatibility(selected)
      ) {
        setPhoneNumberError("operatorCompatibility");
        setIsPhoneNumberValid(false);
      }
    } else {
      if (checkCompatibility(selected)) {
        setPhoneNumberError("operatorCompatibility");
        setIsPhoneNumberValid(false);
      } else {
        setPhoneNumberError("Phonenumbermustbeexactly11digits");
        setIsPhoneNumberValid(false);
      }
    }

    // if (value.length > 11) {
    //   // if (!checkCompatibility(selected)) {
    //   setPhoneNumberError("Enteravalidnumberstartingwith011012010or015");
    //   setIsPhoneNumberValid(false);
    //   // }
    // }
  };
  const [_productCatalog, set_productCatalog] = useState("");
  const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000)); // Initialize with the current time in seconds
  const period = currentTime - subscriptionRequest.timestart;

  useEffect(() => {
    const interval = setInterval(() => {
      const now = Math.floor(Date.now() / 1000);
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Refresh page every 10 minutes
  useEffect(() => {
    // Set interval to refresh page every 10 minutes (600,000 ms)
    const interval = setInterval(() => {
      window.location.reload();
    }, 10 * 60 * 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, []);

  var TPay = window.TPay || {};
  useEffect(() => {
    if (operatorCode) {
      setOperator();
    }
  }, [operatorCode]);

  function onPageLoadFunctions() {
    _enriched = TPay?.HeaderEnrichment?.enriched();

    if (_enriched) {
      // when enriched = true
      TPay?.HeaderEnrichment?.hasSubscription(
        _productSku,
        function (result, subId) {
          if (result) {
            // this means the user is already sunscribed.
            // send an SMS using the contract id returned in the second parameter "subId"
            // give service access to the user.
          } else {
            // user is not subscribed.
            // do nothing and wait for user to choose operator and enter msisdn

            return;
          }
        }
      );
    } else {
      // when enriched = false

      // do nothing and wait for user to choose operator and enter msisdn
      return;
    }
  }

  function injectScript(script) {
    // injects TPAY Script (pass the URL generate in your backend as a parameter when calling this function)
    var TPayScript = document.createElement("script");
    TPayScript.setAttribute("src", script);
    TPayScript.setAttribute("id", `tpaylib`);
    TPayScript.setAttribute("async", ``);

    document.head.appendChild(TPayScript);

    TPayScript.onload = function () {
      onPageLoadFunctions();
      // TPAY's on page load functions will be called after successfull injection.

      // setOperator();
    };
  }

  async function confirmationCallback(status, refCode, contract) {
    // setShowTimer(true);
    setShowTimer(false);
    if (status) {
      console.log("🚀 ~ confirmationCallback ~ status:", status);

      if (refCode) {
        console.log("🚀 ~ confirmationCallback ~ refCode:", refCode);

        console.table(contract);
        console.log("🚀 ~ confirmationCallback ~ contract:", contract);
      } else {
        if (contract?.operationStatusCode == 0) {
          // setShowTimer(true);
          subscriptionCallback(
            contract,
            (response) => {
              if (response?.isSuccess) {
                toastSuccess(response?.message);
              }
              dispatch(
                startedTime({
                  timestart: Math.floor(Date.now() / 1000),
                  phoneNumber: phoneNumber,
                })
              );
            },
            (fail) => {
              setShowTimer(true);
            }
          );
          localStorage.setItem("tpayTimer", Date.now() + 120000);
          navigate(ROUTES.TPAYVERIFICATION, {
            state: {
              transactionId: contract.transactionId,
              subscriptionContractId: contract.subscriptionContractId,
              productCatalog: _productCatalog,
              phoneNumber: phoneNumber,
              operatorCode: operatorCode,
            },
            replace: true,
          });
        } else {
          var errorMsg = contract?.errorMessage;
          if (
            errorMsg === "This user already subscribed to the given product"
          ) {
            // setShowTimer(false);
            toastSuccess(t(errorMsg));
            const payload = {
              operatorCode: String(operatorCode),
              subscriptionContractId: String(contract.subscriptionContractId),
              messageBody:
                `${t("Yourmobilenumber")}` +
                `${phoneNumber}` +
                `${t("isalreadysubscribedtoAmusecompetition")}`,
            };
            sendSmsToSubscribedUser(
              payload,
              (success) => {},
              (fail) => {}
            );
            navigate(ROUTES.GIFTS, {
              replace: true,
              state: { phoneNumberWithCode: `2${phoneNumber}` },
            });
          } else {
            // setShowTimer(true);
            if (
              errorMsg.includes(
                "request to api svc/internal/TPaySubscription.svc/AddSubscriptionContractRequest with request identifier"
              )
            ) {
              toastError(t("pleaseWaitTwoMinutes"));
            } else {
              toastError(t(errorMsg));
            }
          }
        }
      }
    } else {
      console.table(contract);

      console.log("🚀 ~ confirmationCallback ~ contract:", contract);
    }
  }

  function callConfirm() {
    dispatch(
      startedTime({
        timestart: Math.floor(Date.now() / 1000),
        phoneNumber: phoneNumber,
      })
    );
    // dispatch(startedTime(Math.floor(Date.now() / 1000)));
    // const isValidPhoneNumber = /^\d{11}$/.test(phoneNumber);
    // if (isValidPhoneNumber && phoneNumberPattern.test(phoneNumber)) {
    // Phone number is valid, proceed with the subscription confirmation
    var subscriptionInfo = {
      subscriptionPlanId: _subscriptionPlanId,
      productCatalog: _productCatalog,
      productSku: _productSku,
      customerAccountNumber: TPay?.HeaderEnrichment?.msisdn(),
      msisdn: TPay?.HeaderEnrichment?.msisdn(),
      operatorCode: TPay?.HeaderEnrichment?.operatorCode(),
    };

    if (!_enriched) {
      subscriptionInfo.customerAccountNumber = `2${phoneNumber}`; // the mobile number obtained from the user
      subscriptionInfo.msisdn = `2${phoneNumber}`; // the mobile number obtained from the user
      subscriptionInfo.operatorCode = operatorCode; // the operator Code chosen by the user.
    }
    TPay.HeaderEnrichment.confirm(subscriptionInfo, confirmationCallback);
    // } else {
    //   // Display an error message or handle the invalid phone number here
    //   setPhoneNumberError(
    //     "Invalid phone number. Please enter a valid 11-digit number starting with 011, 012, 010, or 015.",
    //   );
    //   setIsPhoneNumberValid(false);
    // }
  }

  function setOperator() {
    // obtains the choosen operator by the user and calls the Mandatory "init()" function
    if (TPay?.HeaderEnrichment) {
      TPay.HeaderEnrichment.init({ operatorCode: operatorCode });
    } else {
    }
  }

  useEffect(() => {
    // Check if _enriched is false and operatorCode is not empty before calling setOperator
    if (!_enriched && operatorCode) {
      setOperator();
    }
  }, [operatorCode, _enriched]);

  useEffect(() => {
    getQueryParamsForTpayScriptUrl(
      lang,
      (success) => {
        injectScript(
          `https://enrichment.tpay.me/idxml.ashx/v2/js?date=${success.data.date}&lang=${success.data.lang}&digest=${success.data.digest}`
        );
      },
      (fail) => {}
    );
  }, []);

  useEffect(() => {
    if (selected && !subsCostData.length) {
      axios
        .get(baseUrl + "api/tpay/getOperatorsCodesForTpay")
        .then((res) => {
          if (!res?.data?.isSuccess) {
            throw new Error("An error occured");
          }
          setSubsCostData(res?.data?.data);
        })
        .catch((err) => {
          toastError("An error occured");
        });
    }
  }, [selected]);

  useEffect(() => {
    console.log(selected);
    if (subsCostData.length && selected) {
      const cost = subsCostData.filter((cost) => {
        return cost.operatorName.toLowerCase() === selected.toLowerCase();
      });

      setSubscriptionCost(cost[0]?.cost);
    }
  }, [subsCostData, selected]);

  return (
    <div className="main-section  text-center">
      <h2 className="text-primary-header" style={{ marginBottom: "2.5rem" }}>
        {t("JoiningCompetition")}
      </h2>
      <div className="selector_container">
        <label htmlFor="operators" className="selector_container_label">
          {t("Chooseyournetworkoperator")}
        </label>

        <CustomSelect
          id="operators"
          selected={selected}
          dropdownChangeHanlder={dropdownChangeHanlder}
          options={options}
        />
        {/* <select
          id='operator'
          name='Operator Selection'
          className='selector_container_selector'
          placeholder='Choose the operator'
          onChange={changeOperatorHandler}
        >
          <option value={TPAY_VODAFONE_CODE}>Egypt-Vodafone</option>
          <option value={TPAY_ORANGE_CODE}>Egypt-Orange</option>
          <option value={TPAY_ETISALAT_CODE}>Egypt-Etisalat</option>
          <option value={TPAY_WE_CODE}>Egypt-WE</option>
        </select> */}
        <div className="phoneinput_container">
          <label className="selector_container_label" htmlFor="MSISDN">
            {t("EnterYourPhoneNumber")}
          </label>

          <input
            type="text"
            id="MSISDN"
            className={`selector_container-phoneinput selector_container-phoneinput_page ${
              phoneNumberError
                ? "error-input transition-border"
                : "transition-border"
            } ${inputClass}`}
            value={phoneNumber}
            onChange={phoneNumberhandler}
            placeholder="01XXXXXXXXX"
            pattern="[0-9]*" // This restricts input to numbers only
            inputMode="numeric"
            disabled={isInputDisabled}
            onKeyDown={handleKeyPress} // Attach keydown event to prevent 'e' input
            autoComplete="off" // Disable autofill
            onPaste={(e) => e.preventDefault()} // Prevent pasting
            onCut={(e) => e.preventDefault()} // Prevent cutting
          />
          {phoneNumberError ? (
            <p
              className="error-message"
              style={{ display: "flex", direction: dir }}
            >
              {t(phoneNumberError)}
            </p>
          ) : (
            <p className="error-message" style={{ direction: dir }}>
              &nbsp;
            </p>
          )}
          {/* {phoneNumberWarning ? (
            <p
              className='error-message error-message--warning'
              style={{ display: "flex", direction: dir }}
            >
              {t(phoneNumberWarning)}
            </p>
          ) : (
            <p className="error-message" style={{ direction: dir }}>
              &nbsp;
              <br></br>&nbsp;
            </p>
          )} */}

          {/* {showTimer &&
            period > 120 &&
            !(phoneNumber !== subscriptionRequest.phoneNumber) && (
              <p
                className="error-message"
                style={{
                  textAlign: dir === "ltr" ? "left" : "right",
                  // marginTop: "-1rem",
                  fontSize: ".9rem",
                  color: "rgba(0,0,0,.85)",
                  fontWeight: 400,
                  opacity: showTimer ? 1 : 0,
                }}
              >
                {`${t("Pleasewait")} `}
                <span
                  style={{
                    textAlign: "center",
                    width: "40px",
                    display: "inline-block",
                    // backgroundColor: "red",
                  }}
                >
                  {formatTime(120 - period)}
                </span>
                {` ${t(
                  "secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked"
                )}`}
              </p>
            )} */}
        </div>
      </div>
      <p className="warning_text">
        {subscriptionCost && (
          <span
            style={{
              display: "block",
              backgroundColor: "white",
              borderRadius: "4px",
              color: "#356AB3",
              fontWeight: "600",
            }}
          >
            {/* {t("Subscriptioncosts10EGPday")} */}
            {lang === "en"
              ? `Subscription costs ${subscriptionCost} EGP/day`
              : `الإشتراك في هذه الخدمة
            يكلف ${subscriptionCost} جنيهات يومياً`}
          </span>
        )}

        {selected === "Vodafone" && (
          <span
            style={{
              // marginBottom: "1rem",
              display: "block",
              backgroundColor: "white",
              borderRadius: "4px",
              fontSize: "12px",
              fontWeight: "600",
              lineHeight: "12px",
            }}
          >
            {t("vodafoneisnotsupportedyet")}
          </span>
        )}

        <div
          style={{
            display: "block",
            backgroundColor: "white",
            borderRadius: "4px",
            fontSize: "12px",
            fontWeight: "600",
            lineHeight: "12px",
            padding: ".5rem",
            // backgroundColor: "red",
          }}
        >
          <p style={{ color: "#ED2024", marginBottom: "0.5rem" }}>
            {t("Tocancel")}
          </p>{" "}
          <div
            style={{
              width: "50%",
              margin: "0 auto",
              textAlign: "start",
              display: "flex",
              flexDirection: "column",
              gap: ".5rem",
            }}
          >
            <span style={{ display: "block", backgroundColor: "white" }}>
              {t("VodafonesendStopSNto2303")}
            </span>
            <span style={{ display: "block", backgroundColor: "white" }}>
              {t("OrangesendStopSNto5030")}
            </span>
            <span style={{ display: "block", backgroundColor: "white" }}>
              {t("EtisalatsendStopSNto1722")}
            </span>
            <span style={{ display: "block", backgroundColor: "white" }}>
              {t("wesendStopSNto4041")}
            </span>
          </div>
        </div>
      </p>

      <Button
        type="button"
        className={`subscribe-btn  subscribe-btn_verify ${
          !isPhoneNumberValid ? "disabled" : ""
        }`}
        onClick={callConfirm}
        disabled={
          !(
            isPhoneNumberValid &&
            (period > 120 || phoneNumber !== subscriptionRequest.phoneNumber)
          ) && showTimer
        }
      >
        {t("Subscribe")}
      </Button>
    </div>
  );
}

export default TpaySubscription;
