import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { Row, Col } from "antd";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { getCategoriesByGenreId } from "../network";
import VideoListItem from "modules/Shared/VideoListItem";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import shortid from "shortid";

function GeneriesVideos({ selectedGenereId }) {
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showArrow, setShowArrow] = useState(false);
  const arr = new Array(2).fill(0);
  const arrOfVideos = new Array(4).fill(0);
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);
  const showHideArrow = (state) => {
    if (state === "show") {
      setShowArrow(true);
    } else {
      setShowArrow(false);
    }
  };
  //
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1921 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1920, min: 1200 },
      // breakpoint: { max: 3000, min: 1440 },
      // breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    desktopSm: {
      breakpoint: { max: 1199, min: 768 },
      // breakpoint: { max: 1439, min: 768 },
      // breakpoint: { max: 1023, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 767, min: 481 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const payload = {
      lang,
      genreId: selectedGenereId,
      ...(authorization?.online?.id && {
        accountId: authorization?.online?.id,
      }),
    };

    setIsLoading(true);

    getCategoriesByGenreId(
      payload,
      (success) => {
        if (success.isSuccess) {
          const allCategories = [...success.data];
          // allCategories.forEach((cat, index) => {
          // 	if (allCategories[index].headers.length > 4) {
          // 		allCategories[index].headers.length = 4;
          // 	}
          // });

          setCategories(allCategories);
          setIsLoading(false);
        }
      },
      (fail) => {
        setIsLoading(false);
      }
    );
  }, [selectedGenereId, lang, authorization?.online?.id]);
  return (
    <section className="generies-videos">
      <Container>
        <div className="generies-videos__container ">
          {!isLoading ? (
            categories.length ? (
              categories.map((category, i) => {
                //
                return (
                  <div
                    className="generies-videos__item "
                    //FIXME category id is undefined on the first genre
                    key={i}
                  >
                    <Container>
                      <header className="main-header item__header">
                        <h2>{category.category}</h2>
                        {/* <p
											role={"button"}
											onClick={() =>
												navigate(`/see-all/${category.categoryId}`, {
													state: {
														title: category.category,
													},
												})
											}
											className="see-all">
											{t("seeAll")}
										</p> */}
                      </header>

                      <div
                        // className="item__container"
                        style={{ height: "auto" }}
                        // onMouseEnter={() => showHideArrow("show")}
                        // onMouseLeave={() => {
                        //   showHideArrow("hide");
                        // }}
                      >
                        {category?.headers?.length ? (
                          <Carousel
                            rtl={lang === "ar" ? true : false}
                            arrows={true}
                            responsive={responsive}
                            keyBoardControl
                            showDots
                            containerClass="generies-videos__carousel-container"
                            dotListClass="generies-videos__dots"
                            // showDots={false}
                            infinite={false}
                            // renderDotsOutside
                          >
                            {category.headers.map((header, _) => (
                              <div
                                className="video-list-item__home-item-container"
                                key={header._id}
                              >
                                <VideoListItem
                                  details={{
                                    header,
                                    isContinueWatching:
                                      category.isContinueWatching,
                                  }}
                                />
                              </div>
                            ))}
                          </Carousel>
                        ) : (
                          <></>
                        )}
                      </div>
                    </Container>
                  </div>
                );
              })
            ) : (
              <div className="generies-videos__no-item">{t("noVedios")}</div>
            )
          ) : (
            arr.map((_, index) => (
              //FIXME THE KEY MUST BE UNIQU SO THE INDEX IS NOT UNIQUE BUT THIS TEMP SOL
              <div className="generies-videos__item" key={index}>
                <Container>
                  <header className="generies-videos__header d-flex justify-content-between align-items-center">
                    <h2>
                      <Skeleton height={"1em"} width={148} />
                    </h2>
                    <p>
                      <Skeleton height={"1rem"} width={53} />
                    </p>
                  </header>
                  <div className="item__container">
                    <Row gutter={[20, 20]} className="item__video">
                      {arrOfVideos?.length
                        ? arrOfVideos?.map((video, i) => (
                            <Col xs={24} md={6} key={i}>
                              <Skeleton
                                height={232}
                                width={"100%"}
                                borderRadius={25}
                              />
                            </Col>
                          ))
                        : null}
                    </Row>
                  </div>
                </Container>
              </div>
            ))
          )}
        </div>
      </Container>
    </section>
  );
}

export default GeneriesVideos;
