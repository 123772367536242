import { createSlice } from "@reduxjs/toolkit";

export const timeSlice = createSlice({
  name: "timeSlice",
  initialState: {
    subscriptionRequest: { timestart: 0, phoneNumber: "" },
  },
  reducers: {
    startedTime: (state, action) => {
      state.subscriptionRequest = {
        ...state.subscriptionRequest,
        ...action.payload,
      };
    },
    // endTime: (state, action) => {
    //   state.childData = null;
    // },
    resetTime: (state, action) => {
      state.timestart = 0;
    },
  },
});

const { reducer, actions } = timeSlice;
export const { startedTime, resetTime } = actions;
export default reducer;
