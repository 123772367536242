import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout } from "store/authorization";
import { useNavigate } from "react-router-dom";
import { logoutUserAccount } from "./network";
import ROUTES from "constants/_routes";
import { toastError } from "helpers/toasters";

const Logout = () => {
  const {
    authorization: { deviceId, _id: userId, online },
  } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // deviceId: authorization.deviceId,
  // userId: authorization?._id,
  // accountId: authorization?.online?._id,

  useEffect(() => {
    const payload = {
      deviceId,
      userId,
      accountId: online?._id,
    };

    if (online) payload.accountId = online._id;

    if (online?._id) {
      logoutUserAccount(
        payload,
        (success) => {
          dispatch(logout());
          navigate(ROUTES.SIGNIN);
        },
        (fail) => {
          dispatch(logout());
          navigate(ROUTES.SIGNIN);
          if (fail?.data?.err_msg) {
            toastError(fail.data.err_msg);
          } else {
            toastError();
          }
        },
      );
    } else {
      dispatch(logout());
      navigate(ROUTES.SIGNIN);
    }
  }, [dispatch, navigate, online, deviceId, userId]);

  return <></>;
};

export default Logout;
