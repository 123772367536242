import { useSelector } from "react-redux";

import CenterControllers from "./CenterControllers";
import BottomControllers from "./BottomControllers";
import SeekBar from "./SeekBar";
import { LoadingSpinner } from "../VideoComponents/LoadingSpinner";
import VideoTitle from "../VideoComponents/VideoTitle";
import ReplayIcon from "assets/video-controllers/ReplayIcon";

function Controllers({
  videoData,
  videoRef,
  isPlaying,
  setIsPlaying,
  isEnded,
  skipBackward,
  skipForward,
  duration,
  currentTime,
  seekTo,
  isBuffering,
  changeAudio,
  changeSubtitle,
  changeQuality,
  mutePlayer,
  volume,
  setVolume,
  fullScreenChange,
  isFullScreen,
  setViewOverlay,
  viewOverlay,
  selectPrevVideo,
  selectNextVideo,
  isNotLastVideoInSeries,
  isNotFirstVideoInSeries,
  handleReplayVideo,
  selectedQuality,
  setSelectedQuality,
  selectedAudio,
  setSelectedAudio,
  selectedSub,
  setSelectedSub,
}) {
  const { dir } = useSelector((state) => state.lang);

  return (
    <>
      <div
        className="controllers"
        onMouseEnter={() => setViewOverlay(true)}
        onMouseLeave={() => setViewOverlay(false)}
        style={{
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          height: "100%",
          zIndex: "5",
          flexDirection: dir === "rtl" ? "row-reverse" : "row",
        }}
      >
        {isFullScreen && (
          <div
            style={{
              padding: "1rem",
            }}
          >
            <VideoTitle videoTitle={videoData.title} />
          </div>
        )}
        {!isBuffering && (
          <>
            {!isFullScreen && (
              <div
                style={{
                  padding: "1rem",
                }}
              >
                <VideoTitle videoTitle={videoData.title} />
              </div>
            )}
            <CenterControllers
              videoRef={videoRef}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              isEnded={isEnded}
              skipBackward={skipBackward}
              skipForward={skipForward}
              isBuffering={isBuffering}
              handleReplayVideo={handleReplayVideo}
            />
          </>
        )}
      </div>

      {!isBuffering && (
        <div className="bottom-controls">
          <BottomControllers
            videoData={videoData}
            changeAudio={changeAudio}
            changeSubtitle={changeSubtitle}
            changeQuality={changeQuality}
            mutePlayer={mutePlayer}
            volume={volume}
            setVolume={setVolume}
            fullScreenChange={fullScreenChange}
            isFullScreen={isFullScreen}
            isNotFirstVideoInSeries={isNotFirstVideoInSeries}
            isNotLastVideoInSeries={isNotLastVideoInSeries}
            selectNextVideo={selectNextVideo}
            selectPrevVideo={selectPrevVideo}
            selectedQuality={selectedQuality}
            setSelectedQuality={setSelectedQuality}
            selectedAudio={selectedAudio}
            setSelectedAudio={setSelectedAudio}
            selectedSub={selectedSub}
            setSelectedSub={setSelectedSub}
          />
          <SeekBar
            currentTime={currentTime}
            seekTo={seekTo}
            duration={duration}
          />
        </div>
      )}
    </>
  );
}

export default Controllers;
