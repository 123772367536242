import React from "react";
import nextIcon from "assets/video-controllers/next.svg";
const NextVideoBtn = ({ isNotLastVideoInSeries, selectNextVideo }) => {
  return (
    isNotLastVideoInSeries && (
      <img
        role="button"
        onClick={selectNextVideo}
        src={nextIcon}
        alt="previous video"
      />
    )
  );
};

export default NextVideoBtn;
