import React from "react";
import fbIcon from "assets/icons/facebook.svg";
import twIcon from "assets/icons/twitter.svg";
import instaIcon from "assets/icons/instagram.svg";
import tiktok from "assets/icons/tiktok.svg";
import googlePlay from "assets/icons/googleplay.svg";
import appStore from "assets/icons/appstore.svg";

function Footer() {
	return (
		<div className="static__footer p-4">
			<div className="px-4">
				<div className="d-flex justify-content-between flex-wrap align-items-center">
					<div>
						<div className="f-18 fw-600">Stay in touch with us</div>
						<ul className="d-flex my-4">
							<li className="me-2">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.facebook.com/profile.php?id=100089472698973&mibextid=LQQJ4d">
									<img src={fbIcon} alt="facebook-link" />
								</a>
							</li>
							<li className="me-2">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://twitter.com/amusethekidz?s=11&t=VAdxUp3THKfs9_p2DaNnyA">
									<img src={twIcon} alt="twitter-link" />
								</a>
							</li>
							<li className="me-2">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://instagram.com/amusethekidz?igshid=YmMyMTA2M2Y=">
									<img src={instaIcon} alt="instagram-link" />
								</a>
							</li>
							<li className="me-2">
								<a
									target="_blank"
									rel="noreferrer"
									href="https://www.tiktok.com/@amusethekidz?_t=8a5j0NVKGij&_r=1">
									<img src={tiktok} alt="tiktok-link" />
								</a>
							</li>
						</ul>
					</div>
					<div>
						<div className="f-18 fw-600">Download the app</div>
						<ul className="d-flex my-4 flex-wrap">
							<li className="me-2 mt-2">
								<a target="_blank" rel="noreferrer" href="/">
									<img src={googlePlay} alt="googlePlay" />
								</a>
							</li>
							<li className="me-2 mt-2">
								<a target="_blank" rel="noreferrer" href="/">
									<img src={appStore} alt="appStore" />
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div className="my-4 d-flex align-items-center justify-content-center">
					All rights reserved &copy; Amuse the Kidz 2023
				</div>
			</div>
		</div>
	);
}

export default Footer;
