import { getResource } from "services";
import { PATHS, PATHS_PARAMS } from "constants/PATHS";

export function getWishlist({ lang, accountId }, success, fail) {
  const params = {
    userAccountId: accountId,
  };
  const path = PATHS(PATHS_PARAMS.getWishList, lang, params);
  getResource(path, success, fail);
}
