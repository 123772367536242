import { createSlice } from "@reduxjs/toolkit";

export const addChildSlice = createSlice({
  name: "addChild",
  initialState: {
    childData: {},
    addFrom: "/watcher",
  },
  reducers: {
    addChildData: (state, action) => {
      state.childData = action.payload;
    },
    resetChildData: (state, action) => {
      state.childData = null;
    },
    addChildFrom: (state, action) => {
      state.addFrom = action.payload;
    },
  },
});

const { reducer, actions } = addChildSlice;
export const { addChildData, resetChildData, addChildFrom } = actions;
export default reducer;
