import { postResource, putResource } from "../../services";
import store from "../../store";

const getCurrentLang = () => store.getState().lang.lang;

export function getSearchResults(data, onSuccess, onFail) {
  const { lang } = data;
  const path = `api/search?lang=${getCurrentLang()}`;
  postResource(path, data, onSuccess, onFail);
}

export function changeLanguage(data, onSuccess, onFail) {
  const { lang } = data;
  const path = `api/userAccounts/language?lang=${getCurrentLang()}`;
  putResource(path, data, onSuccess, onFail);
}
