import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { baseUrl } from "services";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import ROUTES from "constants/_routes";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import lockIcon from "../../../assets/images/lockIcon.svg";
// import SubscripeNowModal from "modules/Subscription/models/SubscripeNowModal/SubscripeNowModal";
import SubscribenowModal from "./SubscribenowModal";
// import freeIconeng from "../../../assets/images/freeEngIcon.svg";
import freeEN from "../../../assets/images/free badge.png";
import freeAr from "../../../assets/images/free badgeAr.png";

const Sidebar = ({ isDataLoading, data, currentSeasonId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  // eslint-disable-next-line
  const [videoHeaderId, videoId, isSeries] = params.qp.split("-");
  const [currentSeasonIndex, updateCurrentSeasonIndex] = useState(null);
  const [showSubscribeNowModal, setshowSubscribeNowModal] = useState(false);
  const { authorization } = useSelector((state) => state.auth);
  const isPaid = authorization?.isPaid?.isPaid;
  const { t } = useTranslation();
  const { lang, dir } = useSelector((state) => state.lang);

  const engLng = lang === "en" ? true : false;

  const urlparams = new URLSearchParams(location.search);

  const isOffline = !urlparams.get("isOffline")
    ? false
    : urlparams.get("isOffline");
  const relatedPartsData =
    isSeries === "1"
      ? currentSeasonIndex
        ? data[currentSeasonIndex - 1].videos
        : []
      : data;

  // function selectVideo(videoId) {
  //   const video = relatedPartsData.find((item) => item.videoId === videoId);
  //   if (video.isFree || isPaid) {
  //     let qpData = [videoHeaderId, videoId, isSeries].join("-");
  //     let qp = {
  //       qp: qpData,
  //     };
  //     navigate({
  //       pathname: ROUTES.WATCH_NOW,
  //       search: `?${createSearchParams(qp)}`,
  //     });
  //   } else {
  //     setshowSubscribeNowModal(true);
  //   }
  // setSelectedVideo(videoId);
  function selectVideo(selectedVideoId) {
    if (navigator.onLine) {
      const video = relatedPartsData.find(
        (item) => item.videoId === selectedVideoId
      );

      // setSelectedVideo(videoId);
      if (videoId === selectedVideoId) return;
      if (video.isFree || isPaid) {
        let qpData = [videoHeaderId, selectedVideoId, isSeries].join("-");
        let qp = {
          qp: qpData,
        };
        navigate({
          pathname: ROUTES.WATCH_NOW,
          search: `?${createSearchParams(qp)}`,
        });
      } else {
        setshowSubscribeNowModal(true);
      }
    } else {
      console.log(location);
      console.log(location.pathname, {});
      navigate(ROUTES.NOINTERNET, {
        state: {
          from: `${location.pathname}${location.search}`,
        },
      });
    }
  }

  useEffect(() => {
    const seasonIndex = data.find((season) => season._id === currentSeasonId);
    updateCurrentSeasonIndex(seasonIndex?.seasonIndex);
    // eslint-disable-next-line
  }, [currentSeasonId]);
  console.log(navigator.onLine, "navigatorela;djfkljaskld");

  return (
    <>
      {!isOffline ? (
        <ul className="subscribtion__list ">
          {isDataLoading &&
            new Array(4).fill(null).map((item, _) => (
              <li className="subscribtion__video-content " key={_}>
                <figure className="subscribtion__figure">
                  <Skeleton width={"100%"} height={"100%"} />
                </figure>
                <div className="subscribtion__details">
                  <h1
                    className="subscribtion__vid-name"
                    role={"button"}
                    onClick={selectVideo}
                  >
                    <Skeleton width={"45%"} />
                  </h1>
                  <p className="subscribtion__vid-details">
                    <Skeleton width={"95%"} />
                  </p>
                </div>
              </li>
            ))}
          {relatedPartsData.map((video, index) => {
            const videoImg = isSeries === "1" ? video.videoImage : video.image;
            const title = isSeries === "1" ? video.videoTitle : video.name;
            const duration =
              isSeries === "1" ? video.videoDuration : video.duration;
            return (
              <li
                className={`subscribtion__video-content ${
                  !isPaid
                    ? video.isFree
                      ? `subscribtion__video-content__isFree${
                          dir == "ltr" ? "--en" : "--ar"
                        }`
                      : "subscribtion__video-content__isFree--notfree"
                    : null
                } ${videoId === video.videoId ? "active" : null}`}
                key={index}
                role={"button"}
                onClick={(e) => selectVideo(video.videoId)}
              >
                <figure className="subscribtion__figure">
                  {!video.isFree && !isPaid && (
                    <div className="subscribtion__figure__overlay">
                      {" "}
                      {/* Add appropriate styles for the overlay */}
                      <img src={lockIcon} />{" "}
                      {/* Text or content for the overlay */}
                    </div>
                  )}
                  <img
                    className="subscribtion__image"
                    src={baseUrl + videoImg}
                    alt="alternate"
                  />
                  <div className="duration">{duration}</div>
                </figure>
                <div className="subscribtion__details">
                  <h1 className="subscribtion__vid-name">{title}</h1>
                  <p className="subscribtion__vid-details fw-600">
                    {isSeries === "1" ? t("episode") : t("part")}{" "}
                    {video.videoIndex}
                  </p>
                </div>
                {video.isFree && !isPaid && (
                  <p
                    style={{
                      margin: `${
                        dir == "ltr" ? "0 0 0 auto" : "0px 0px auto 0px"
                      }`,
                      marginRight: `${dir == "ltr" ? "0" : "auto"}`,
                      position: "relative",
                      height: "max-content",
                    }}
                  >
                    <img
                      // src={freeIconeng}
                      src={engLng ? freeEN : freeAr}
                      alt=""
                      style={{
                        height: "32px",
                        width: "32px",
                        display: "block",
                      }}
                    />
                    {/* <span
                      style={{
                        position: "absolute",
                        left: "50%",
                        top: "50%",
                        transform: "translate(-50%,-50%)",
                        fontSize: "10px",
                        color: "#fff",
                        fontWeight: "700",
                        lineHeight: "1",
                      }}
                    >
                      {" "}
                      {t("free")}
                    </span> */}
                  </p>
                )}
              </li>
            );
          })}
        </ul>
      ) : null}

      <SubscribenowModal
        open={showSubscribeNowModal}
        onCancel={() => setshowSubscribeNowModal(false)}
      />
    </>
  );
};

export default Sidebar;
