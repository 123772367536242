import React from "react";
import amuseLogo from "assets/images/amuse-logo.svg";
import world from "assets/icons/world.svg";
import { Select } from "antd";
import ROUTES from "constants/_routes";
import { Link } from "react-router-dom";

function Header() {
	const handleChangeLanguage = (value) => {};
	return (
		<div className="static__header p-4 ">
			<div className="px-4 d-flex justify-content-between aligm-items-center">
				<Link to={ROUTES.STATIC}>
					<img src={amuseLogo} alt="amuseLogo" />
				</Link>
				<div>
					<img src={world} alt="world" />
					<Select
						defaultValue="English"
						style={{
							width: 120,
						}}
						onChange={handleChangeLanguage}
						options={[
							{
								value: "english",
								label: "English",
							},
							{
								value: "arabic",
								label: "Arabic",
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
}

export default Header;
