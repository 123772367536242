import React, { useState, useEffect } from "react";
import { getPackages } from "modules/Subscription/network";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { toastError } from "helpers/toasters";
import ROUTES from "constants/_routes";
import savingBadge from "../../../../assets/icons/payment_discount_percent.svg";
import Spinner from "modules/Shared/Spinner";
import ProceedWithCodeModal from "../ProceedWithCodeModal";
import CannotChangePlanModal from "./CannotChangePlanModal";
function ChoosePlan() {
  const [packages, updatePackages] = useState([]);
  const { authorization } = useSelector((state) => state.auth);
  const isPaid = authorization?.isPaid?.isPaid;
  const [proceedWithCodeModal, setProceedWithCodeModal] = useState(false);
  const [disableChangePlanModal, setdisableChangePlanModal] = useState(isPaid);
  const [selectedPackage, updateSelectedPackage] = useState(null);
  const { lang } = useSelector((state) => state.lang);
  const [isLoading, updateIsLoading] = useState(true);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    updateIsLoading(true);
    getPackages(
      { lang: lang },
      (success) => {
        updatePackages(success.data);
        updateIsLoading(false);
      },
      (fail) => {},
    );
  }, [lang]);
  const handleSelectPackage = (id) => {
    updateSelectedPackage(id);
  };
  const title = location.search.split("title=");
  return (
    <>
      {/* {isLoading ? (
        <Spinner isNotFullPage={true} />
      ) : ( */}
      <div className='choose-plan text-center'>
        <h5 className='main-title'>
          {title.length && title[0]?.length ? t(title[1]) : t("choosePlan")}
        </h5>
        <div className='d-flex flex-wrap justify-content-around text-center'>
          {isLoading ? (
            <Spinner isNotFullPage={true} />
          ) : (
            packages.map((packageDetail) => {
              const {
                packageName,
                amountPerMonth,
                amount,
                billedEvery,
                id,
                discount,
              } = packageDetail;

              return (
                <div
                  key={id} // Don't forget to add a unique key when rendering arrays
                  className={`package-card m-4 ${
                    selectedPackage === id ? "selected" : ""
                  } ${i18n.language !== "ar" && "px-4"}`}
                  onClick={() => handleSelectPackage(id)}
                >
                  {discount !== 0 && (
                    <div className='discount-box'>
                      <div className='discount-container'>
                        <img
                          src={savingBadge}
                          alt='savingBadge'
                          className='saving-badge'
                        />
                        <div className='text'>
                          {t("savedPercent")} {discount}%
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='package-name'>{packageName}</div>
                  <div className='package-amount'>
                    <span className='amount'>{`${amount} ${t("EGP")}`}</span>
                  </div>
                  <div className='package-billedEvery'>{`${billedEvery} - ${amountPerMonth} / ${t(
                    "month",
                  )}`}</div>
                </div>
              );
            })
          )}
        </div>
        <div className='d-flex flex-column align-items-center justify-content-center'>
          <Button
            className='button-primary mt-4 px-1 w-20'
            // disabled={isPaid}
            onClick={() => {
              if (!isPaid) {
                if (selectedPackage) {
                  navigate(
                    ROUTES.SUBSCRIPTION +
                      `?page=payment&packageId=${selectedPackage}`,
                  );
                } else {
                  toastError(t("selectPackage"));
                }
              } else {
                setdisableChangePlanModal(true);
              }
            }}
          >
            {t("proceedToPayment")}
          </Button>
          <div
            className='mt-4 d-flex flex-column justify-content-center align-items-center '
            style={{ width: "20%", marginBottom: "2rem !important" }}
          >
            <button
              // disabled={isPaid}
              className={
                `button-secondary btn-plan bg-white`
                //  ${isPaid ? "disabledButton disabledButton--proceedcoupon" : ""}`
              }
              style={{
                paddingRight: "0",
                paddingLeft: "0",
                display: "flex",
                minWidth: "13.75rem",
                // width: "20%",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
              onClick={() => {
                if (!isPaid) {
                  setProceedWithCodeModal(true);
                } else {
                  setdisableChangePlanModal(true);
                }
              }}
            >
              <span> {t("proceedwithcode")}</span>
            </button>
          </div>
        </div>

        {proceedWithCodeModal && (
          <ProceedWithCodeModal
            open={proceedWithCodeModal}
            onCancel={() => setProceedWithCodeModal(false)}
          />
        )}
      </div>
      <CannotChangePlanModal
        open={disableChangePlanModal}
        onCancel={() => setdisableChangePlanModal(false)}
      />
    </>
  );
}

export default ChoosePlan;
