import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource } from "services";

export function getAllVideosByCategoryId(
  { lang, categoryId, accountId },
  onSuccess,
  onFail
) {
  const path =
    PATHS(PATHS_PARAMS.getAllVideosByCategoryId) +
    `/${categoryId}?lang=${lang}`;
  getResource(path, onSuccess, onFail);
}
