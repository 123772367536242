import { createSlice } from "@reduxjs/toolkit";

export const authorizationSlice = createSlice({
  name: "authorization",
  initialState: {
    authorization: {},
  },
  reducers: {
    login: (state, action) => {
      state.authorization = action.payload;
    },
    logout: (state, action) => {
      state.authorization = {};
    },
    changeAuthData: (state, action) => {
      state.authorization = { ...state.authorization, ...action.payload };
    },
    changeAuthImg: (state, action) => {
      state.authorization.imagePath = action.payload;
    },
    onlineUser: (state, action) => {
      state.authorization.online = action.payload;
    },
    resetOnlineUser: (state, action) => {
      delete state.authorization.online;
    },
    addUserAccount: (state, action) => {
      state.authorization.userAccounts = [
        ...state.authorization.userAccounts,
        action.payload,
      ];
    },
    addPaymentState: (state, action) => {
      state.authorization.isPaid = action.payload;
    },
    editUserAccountR: (state, action) => {
      const copyOfState = state.authorization.userAccounts;
      copyOfState.filter((item, index) => {
        if (item.id === action.payload.id) {
          copyOfState[index] = action.payload;
        }
        return item;
      });
      state.authorization.userAccounts = copyOfState;
    },
  },
});

const { reducer, actions } = authorizationSlice;
export const {
  login,
  logout,
  changeAuthImg,
  changeAuthData,
  onlineUser,
  resetOnlineUser,
  addUserAccount,
  editUserAccountR,
  addPaymentState,
} = actions;
export default reducer;
