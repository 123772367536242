import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { getUserAccounts } from "modules/WatchNow/network";
import AmuseLogo from "assets/images/amuse-logo.svg";
import addAcount from "assets/images/addAccount.svg";
import { baseUrl } from "services";
import ROUTES from "constants/_routes";
import editIcon from "assets/icons/pencil.png";
import deleteIcon from "assets/icons/trash.png";
import { toastError } from "helpers/toasters";
import DeleteModal from "./DeleteModal";
import DetectWindowSize from "helpers/DetectWindowSize";
import Skeleton from "react-loading-skeleton";
import { resetChildData } from "store/addChild";
import { addTokenToMedia } from "helpers/addTokenToMedia";

const ManageAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  const [hoveredUser, setHoveredUser] = useState(null);
  const [userAccounts, setUserAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [breakPointData, setBreakPointData] = useState();

  const {
    auth: { authorization },
    lang: { lang },
  } = useSelector((state) => ({
    auth: state.auth,
    lang: state.lang,
  }));

  const getAllUsers = () => {
    const payload = {
      userId: authorization._id,
      lang,
    };

    setIsLoading(true);

    getUserAccounts(
      payload,
      (success) => {
        setUserAccounts(success.data.userAccounts);
        setIsLoading(false);
      },
      (fail) => {
        setIsLoading(false);
        if (fail?.data?.error) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      }
    );
  };

  useEffect(() => {
    getAllUsers();
    //eslint-disable-next-line
  }, [authorization?._id, lang]);

  function handleonMouseOver(userId) {
    setHoveredUser(userId);
  }

  function handleonMouseLeave() {
    setHoveredUser(null);
  }

  function openDeleteModal(user) {
    if (!user.isParent) {
      setIsDeleteModalOpened(true);
      setSelectedUser(user);
    }
  }

  return (
    <>
      <section className="manage-account">
        <Container>
          <DetectWindowSize
            getBreakPointData={(data) => setBreakPointData(data)}
          />
          <div className="single-centered-logo ">
            {/* <Link to={ROUTES.HOME}>
              <img src={AmuseLogo} alt="amuse the kids" />
            </Link> */}
          </div>
          <div className="single-centered-title">{t("manageYourAccount")}</div>
          <div className={`manage-account__allAvatars  justify-content-evenly`}>
            {isLoading ? (
              <>
                {[null, null, null].map((item, _idx) => {
                  return (
                    <div className="watchers__avatar" key={_idx}>
                      <figure className="watchers__figure">
                        <Skeleton width={"100%"} height={"100%"} circle />
                      </figure>
                      <Skeleton
                        className="mt-4"
                        height={"1.25rem"}
                        width={"100px"}
                      />
                    </div>
                  );
                })}
              </>
            ) : (
              userAccounts?.map((user) => {
                return (
                  <div
                    className="manage-account__avatar text-center"
                    key={user._id}
                  >
                    <div
                      {...{
                        role: "button",
                      }}
                      onMouseOver={() => handleonMouseOver(user._id)}
                      onMouseLeave={() => handleonMouseLeave()}
                    >
                      <figure
                        // className={`manage-account__figure ${
                        //   user._id === hoveredUser ? "show-edit" : ""
                        // }`}
                        className={"manage-account__figure show-edit"}
                        onClick={() => {
                          navigate(ROUTES.ADD_CHILDS + "/" + user?._id, {
                            state: {
                              user,
                              isParent: user.isParent,
                              mode: "edit",
                            },
                          });
                        }}
                      >
                        {!user.isParent && (
                          <img
                            className="delete-icon"
                            src={deleteIcon}
                            alt="delete"
                            onClick={(e) => {
                              e.stopPropagation();
                              openDeleteModal(user);
                            }}
                          />
                        )}
                        <img className="edit-icon" src={editIcon} alt="edit" />

                        <img
                          className="manage-account__img"
                          src={addTokenToMedia(baseUrl + user?.avatar)}
                          alt="Avatar"
                        />
                      </figure>
                    </div>

                    <h3
                      className={`manage-account__avatar__name ${
                        user.id === hoveredUser ? "active" : null
                      }`}
                      {...(!user?.isParent && {
                        role: "button",
                        onMouseOver: () => handleonMouseOver(user.id),
                        onMouseLeave: () => handleonMouseLeave(),
                      })}
                      onClick={() =>
                        navigate(ROUTES.ADD_CHILDS + "/" + user?._id, {
                          state: {
                            user,
                            isParent: user.isParent,
                            mode: "edit",
                          },
                        })
                      }
                    >
                      {t(`${user.name}`)}
                    </h3>
                  </div>
                );
              })
            )}

            {!isLoading && (
              <div
                className="manage-account__avatar text-center"
                onClick={() => {
                  if (userAccounts?.length >= 5) {
                    toastError(t("cantAddMoreThanFour"));
                  }
                }}
              >
                <figure role={"button"} className="manage-account__figure">
                  {userAccounts.length < 5 ? (
                    <Link
                      to={ROUTES.ADD_CHILDS}
                      state={{
                        mode: "add",
                        isParent: false,
                        from: "/manage-account",
                      }}
                    >
                      <img
                        className="w-100"
                        src={addAcount}
                        alt="Avatar"
                        onClick={() => dispatch(resetChildData())}
                      />
                    </Link>
                  ) : (
                    <img src={addAcount} alt="Avatar" />
                  )}
                </figure>

                <h3
                  className="manage-account__avatar__name"
                  role={"button"}
                  onClick={
                    userAccounts.length <= 5
                      ? () => dispatch(resetChildData())
                      : () => toastError(t("cantAddMoreThanFour"))
                  }
                >
                  {userAccounts.length < 5 ? (
                    <Link to={ROUTES.ADD_CHILDS} state={{ mode: "edit" }}>
                      {t("Add")}
                    </Link>
                  ) : (
                    <div>{t("Add")}</div>
                  )}
                </h3>
              </div>
            )}
          </div>
        </Container>
      </section>

      {isDeleteModalOpened && (
        <DeleteModal
          isModalVisible={isDeleteModalOpened}
          onCancel={() => {
            setIsDeleteModalOpened(false);
            // getAllUsers();
          }}
          user={selectedUser}
          userAccounts={userAccounts}
          setUserAccounts={setUserAccounts}
        />
      )}
    </>
  );
};

export default ManageAccount;
