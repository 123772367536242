export const ar = {
  signin: "تسجيل الدخول",
  login: "تسجيل الدخول",
  signup: "إنشاء حساب",
  mobileNumber: "رقم الهاتف",
  password: "كلمة المرور",
  newPassword: "كلمة المرور الجديدة",
  forgetPassword: "نسيت كلمة المرور؟",
  dontHaveAccount: "ليس لديك حساب حتى الآن؟",
  subscribeNow: "إشترك الآن",
  signupNow: "إشترك الآن!",
  name: "الإسم",
  fullName: "الإسم بالكامل",
  email: "البريد الإلكتروني",
  mobile: "رقم الهاتف",
  confirmNewPassword: "تأكيد كلمة المرور الجديدة",
  confirmPassword: "تأكيد كلمة المرور",
  passwordChangedSuccessfully: "تم تغيير كلمة المرور بنجاح",
  age: "العمر",
  gender: "النوع",
  alreadySubscribe: "مشترك بالفعل؟",
  provideName: "من فضلك أدخل الإسم",
  providePhoneNumber: "من فضلك أدخل رقم الهاتف",
  provideValidPhoneNumber: "من فضلك أدخل رقم هاتف صحيح",
  provideEmail: "من فضلك أدخل البريد الإلكتروني",
  provideMessage: "من فضلك أدخل رسالتك",
  providePassword: "من فضلك أدخل كلمة المرور",
  provideValidEmail: "من فضلك أدخل بريد إلكتروني صحيح",
  passwordLength: "يجب أن تكون كلمة المرور على الأقل 6 أحرف",
  provideConfirmPassword: "من فضلك أدخل تأكيد كلمة المرور",
  confirmPasswordDoesntMatch: "تأكيد كلمة المرور غير متطابقة",
  provideFullName: "من فضلك، أدخل اسمك الكامل",
  home: "الرئيسية",
  contactUs: "اتصل بنا",
  help: "المساعده",
  applications: "التطبيقات",
  termsAndConditions: "الشروط والأحكام",
  privacyPolicy: "سياسة الخصوصية",
  rights: "جميع الحقوق محفوظة © Amuse the Kidz 2021",
  forgetPasswordHeader: "نسيت كلمة المرور؟",
  changePasswordHeader: "تغيير كلمة المرور",
  enterMobileNumberToRecieveOTP: "أدخل رقم هاتفك المحمول ليصلك رمز التحقق",
  sendCode: "إرسال رمز",
  rememberPassword: "تذكرت كلمة المرور؟",
  signinNow: "تسجيل الدخول الآن!",
  verifyOtpCode: "تحقق من الرمز ",
  enterOtpNumber: "أدخل رمز التحقق الذي تم إرساله إلى رقم هاتفك المحمول",
  enter5digitsOtpNumber:
    "أدخل الرمز المكون من 5 أرقام الذي تم إرساله إلى رقم هاتفك",
  otpCodeError: "من فضلك أدخل رمز التحقق الصحيح",
  forgetOtpCodeError: "من فضلك أدخل رمز التحقق الصحيح",
  reachedMaxNumPerDay:
    "عفواً! لقد تجاوزت العدد المسموح به لإعادة تعيين رقم التعريف الشخصي الخاص بك اليوم. حاول مرة أخرى غداً",
  emptyOtpCode: "من فضلك أدخل رمز التحقق ",
  didntGetCode: "لم تحصل على رمز؟ ",
  didntReceiveCode: "لم تحصل على رمز؟ ",
  resendCode: "إعادة إرسال الرمز",
  resendOtpCode: "إعادة إرسال الرمز",
  forgetPinCode: "نسيت رمز التحقق؟",
  personalInformation: "المعلومات الشخصية",
  saveChanges: "حفظ التغييرات",
  chooseWhoWatchNow: "اختر من يشاهد الآن",
  selectUser: "إختر مستخدم",
  manageYourAccount: "إدارة حساباتك",
  selectAvatar: "اختر الصورة",
  pay: "ادفع",
  or: "أو",
  availableLang: "اللغات المتاحه",
  part: "الجزء",
  episode: "الحلقه",
  personalInfoEditSuccess: "تم تحديث المعلومات الشخصية بنجاح",
  accountCreatedSuccess: "تم إنشاء الحساب بنجاح",
  confirmCode: "تأكيد الرمز",
  wishList: "قائمتي",
  subscribeNowWithout: "إشترك الآن",
  renewSubscription: "تجديد الإشتراك",
  seeAll: "عرض الكل",
  manageYourAccounts: "إدارة حساباتك",
  Add: "إضافة",
  Parent: "الأب",
  Kid1: "طفل 1",
  Kid2: "طفل 2",
  myList: "قائمة التشغيل الخاصه بي",
  Send: "إرسال",
  YourMessage: "رسالتك",
  EnterYourMobileNumber: "من فضلك ادخل رقم الهاتف",
  ProvideAtLeastTenNumbers: "من فضلك الحد الأدنى 10 أرقام",
  ProvideAtLeastElevenNumber: "من فضلك الحد الأدنى 11 رقم",
  MaximumNumbers11: "من فضلك الحد الأقصى 11 رقما",
  MaximumNumbers10: "من فضلك الحد الأقصى 10 أرقام",
  resetPassword: "إعادة تعيين كلمة المرور",
  confirm: "تأكيد",
  passwordResetedSuccessfully: "تم إعادة تعيين كلمة المرور بنجاح",
  parentalControlCode: "تعديل رمز الرقابة الأبوية",
  editPinCode: "تعديل رقم التعريف الشخصي",
  pinCode: "رقم التعريف الشخصي",
  verifyParentalControlCode: "تحقق من  رمز الرقابة الأبوية",
  createParentalCode: "قم بإنشاء رقم التعريف الشخصي الخاص بك للتحكم في حسابك",
  enterPinCode: "ادخل رقم التعريف الشخصي الخاص بك للتحكم في حسابك ",
  save: "حفظ",
  savedPercent: "وفر",
  saved: "وفرت",
  parentalCodeError: "خطأ في كود تعريف الأب",
  successPinCode: "الكود صحيح",
  errorPinCode: "خطأ ف الكود",
  pinCodeCreatedSuccess: "تم إنشاء كود الأب بنجاح",
  pinUpdateFaild: "لم يتم التحديث",
  verify: "ادخل رمز الامان",
  enter: "ادخل",
  watchNow: "شاهد الأن",
  changePassword: "تغيير كلمة المرور",
  changePersonalInformation: "تعديل المعلومات الشخصية",
  editeParentalCode: "تغيير رمز الرقابة الأبوية",
  history: "السجل",
  mySubscription: "خطة الإشتراك",
  oldPassword: "كلمة المرور القديمة",
  NewPassword: "كلمة المرور الجديدة",
  newPassword: "كلمة المرور الجديدة",
  provideNewPassword: "من فضلك أدخل كلمة المرور الجديدة",
  editFourDigitCode: "يمكنك تعديل رقم التعريف الشخصي الخاص بك للتحكم في حسابك",
  pinCodeUpdatedSuccess: " تم تحديث رقم التعريف الشخصي بنجاح",
  noChanges: "لم تقم بأي تعديلات",
  ageMustBePlus18: "غير مسموح اقل من 18 عام",
  provideChildAge: "ادخل عمر الطفل",
  provideChildName: "ادخل اسم الطفل",
  provideLanguage: "ادخل اللغه",
  fieldDoesNotAcceptNumbers: "من فضلك لا يمكن ادخال ارقام",
  accountCreatedSuccessfully: "تم اضافة حساب جديد بنجاح",
  addChild: "اضافة طفل",
  chooseLanguage: "اختر اللغة",
  cast: "الممثلين",
  movieParts: "أجزاء الفيلم",
  episods: "الحلقات",
  userDataUpdatedSuccessfully: "تم تعديل بيانات المستخدم بنجاح",
  datasentSuccessfully: "تم ارسال البيانات بنجاح",
  search: "البحث",
  manageAccount: "إدارة الحساب",
  switchProfile: "تغيير الملف الشخصي",
  selectLang: "اختر اللغه",
  addLetters: "من فضلك اكتب حروف فقط",
  enterValidName: "من فضلك ادخل اسم صحيح",
  ageNotAllowed: "عمرك غير مسموح به",
  chooseAvatar: "اختيار الصورة الرمزية",
  chooseGender: "من فضلك، اختر النوع",
  profile: " إعدادات الملف الشخصي",
  logout: "تسجيل الخروج",
  audio: "الصوت",
  audios: "الأصوات",
  subtitles: "الترجمات",
  subtitle: "الترجمة",
  quality: "الجودة",
  volume: "درجة الصوت",
  off: "إغلاق",
  playerOffKey: "إيقاف",
  default: "إفتراضي",
  tvshow: "عروض تلفزيونية",
  movies: "أفلام",
  anime: "أنيمي",
  tvProgram: "برامج تليفيزيونية",
  funStore: "تسوق",
  filter: "فلتر",
  oopsNotFound: "عفوًا ، الصفحة غير موجودة",
  backToHome: "العودة إلى  الصفحة الرئيسية",
  enterValidCode: "من فضلك أدخل رمز صحيح.",
  haveReachedMaxCapcity: "لقد وصلت إلى أقصى سعة للجهاز!",
  alreadyHas4Devices:
    "لديك بالفعل 4 أجهزة تم تسجيل الدخول إليها في نفس الوقت ، إذا كنت تريد تسجيل الدخول ، فعليك تحديد جهاز واحد لتسجيل الخروج.",
  logItOut: "تسجيل الخروج",
  areYouSureYouWantToDeleteThisUser: "هل أنت متأكد أنك تريد حذف هذا الطفل ؟",
  addAvatar: "إضافة أفاتار",
  editAvatar: "اختيار الصورة الرمزية",
  youAreA: "أنت",
  boy: "ولد",
  girl: "بنت",
  delete: "حذف",
  cancel: "الغاء",
  noDataFound: "عفوااا , لا توجد بيانات!",
  noConnection: "!عفوااا , تم قطع الاتصال",
  tryAgain: "حاول مره اخري",
  signinTitle: "من فضلك سجل الدخول او ابدأ عضويتك الان!",
  subscibeTitle:
    "استمتع ب Amuse the kids الاصلي, مسلسلات و افلام حصريه و المزيد",
  cantAddMoreThanFour: "عفوا لا يمكنك اضافة اكتر من 5 حسابات!",
  submit: "ارسل",
  selectAnswer: "من افضلك اختر اجابه",
  choosePlan: "اختر خطة الاشتراك",
  month: "شهر",
  proceedToPayment: "متابعة الدفع",
  selectPackage: "من فضلك اختر الباقه",
  choosePaymentMethod: "اختر وسيلة الدفع الخاصة بك",
  total: "المبلغ الكلي: ",
  YourFawryCode: " كود فوري هو",
  paymentApproved: "Payment Approved",
  thanks: "Thank you for using the online payment service.",
  somethingWentWrong: "Something went wrong",
  pleaseTryAgain: "There is something wrong in your payment, Please try again",
  expirationDate: "* ينتهي الكود بعد",
  shareDigits: "من فضلك شارك الكود مع متجر فوري لإتمام عملية الدفع الخاصة بك.",
  subscriptionPlan: "خطة الاشتراك",
  myPlan: "خطة الاشتراك",
  myCreditCards: "بطاقات الدفع",
  startDate: "بداية الاشتراك:",
  endDate: "نهاية الاشتراك:",
  from: "من:",
  to: "إلى:",
  paymentDetails: "تفاصيل الدفع",
  myPlanIs: "خطتي هي",
  renewSubscriptionAutomatically: "جدد باقتك تلقائيا",
  changePlan: "تغيير خطة الإشتراك",
  AllPlans: "جميع خطط الإشتراك",
  chooseYourPlan: "اختر خطة إشتراكك",
  cardDetails: "بيانات بطاقتي",
  noCard: "لا توجد بطاقة ائتمان حتى الآن",
  expiresIn: "ينتهي في",
  makeDefaultCard: "اجعلها البطاقة المستخدمة في الدفع التلقائي",
  deleteCardValidation: "هل انت متأكد من انك تريد حذف هذه البطاقة؟",
  deleteCardNote: "اذا تم الغائها لا يمكنك استعادتها مره اخري",
  clearFilter: "مسح الفلتر",
  pleaseSubscribeFirstParent:
    "للإستمتاع بمشاهدة المحتوى يجب عليك الإشتراك أولاً",
  pleaseSubscribeFirstChild:
    "للإستمتاع بمشاهدة المحتوى يجب عليك الإشتراك أولاً من خلال حساب الأب!",
  sureToLogout: "هل انت متأكد من تسجيل الخروج؟",
  pleaseLoginFirst: "من فضلك سجل الدخول اولا لتبدأ",
  wishlist: "قائمتي",
  myDownloads: "التنزيلات الخاصة بي",
  goToDownloads: "اذهب الي التنزيلات",
  download: "تنزيل",
  chooseToDownload: "لتنزيل هذا الفيديو , يرجي اختيار",
  ok: "تم",
  offf: "إيقاف",
  downloading: "...تنزيل",
  downloadingSuccess: "تم التنزيل بنجاح",
  noVideosInDownload: "لا يوجد فيديوهات في تنزيلاتك ",
  noVideosInList: "لا يوجد فيديوهات في قائمتك",
  goHomeToAddInDownloads: "أذهب الي الرئيسيه لاضافه فيديوهات في قائمه تنزيلاتك",
  goHomeToAddInList: "أذهب الى الصفحة الرئيسية لإضافة فيديوهات في قائمتك الآن",
  getTheApp: "أحصل علي التطبيق",
  loginFirst: " للاستمتاع بمشاهدة المحتوى يجب عليك تسجيل الدخول اولاً",
  subscribeFirst: "للاستمتاع بمشاهدة المحتوى يجب عليك الإشتراك اولاً",
  soon: "قريباً",
  noVedios: "نعمل على إضافة مقاطع فيديو جديدة وستكون جاهزة قريبًا جدًا",
  // EGP: "جنيه مصرى",
  EGP: "جنيه",
  freeDays: "أيام مجانية",
  freeDay: "يوم مجاني",
  changeMyPlanNote:
    "اذا اشتركت في خطة اخرى، سوف تبدأ بعد انتهاء الاشتراك الحالي",
  auto: "تلقائي",
  hd: "جودة مثالية",
  low: "جودة منخفضة",
  Getyouroffer: "احصل على العرض!",
  enteryourcode: "أدخل الرمز الخاص بك واستمتع بالمشاهدة",
  entercode: "من فضلك ادخل الرمز",
  proceed: "استمر",
  proceedwithcode: "استخدم الكود",
  emptycodemessage: "من فضلك أدخل الرمز",
  oldParentalCode: "رمز الرقابة الأبوية القديم",
  next: "التالى",
  LeaveCompetition: "مغادرة المسابقة",
  Chooseyournetworkoperator: "من فضلك اختر مشغل الشبكة",
  choosetheoperator: "من فضلك اختر مشغل الشبكة",
  JoiningCompetition: "إنضم للمسابقة",
  EnterYourPhoneNumber: "أدخل رقم الموبايل",
  Subscribe: "إشترك",
  Enteravalidnumberstartingwith011012010or015:
    "أدخل رقم صحيح يبدأ بـ 011 , 012 , 010, 015",
  Phonenumbermustbeexactly11digits: " رقم الهاتف يجب ان يكون 11 رقم ويبدأ بـ01",
  EnterThePinCode: "أدخل كود التحقق المكون من 6 ارقام",
  verify: "تأكيد",
  Subscriptioncosts2EGPday: "تكلفة الخدمة 10 جنيهات / يومياً",
  Tocancel: " لإلغاء الخدمة:",
  OrangesendStopSNto5030: "-  أورنج, أرسل 'stop ATK' إلي  5030 ",
  EtisalatsendStopSNto1722: "-  إتصالات, أرسل 'stop ATK' إلي  1722 ",
  VodafonesendStopSNto2303: "-  فودافون, أرسل 'stop ATK' إلي  2303 ",
  wesendStopSNto4041: "-  وي, أرسل 'stop ATK' إلي  4041 ",
  vodafoneisnotsupportedyet: "هذه الخدمة غير متاحة لشركة فودافون حتي الآن",
  Incaseyoudonthaveenoughcredit:
    " في حالة عدم وجود رصيد كافي من المحتمل دفع ثمن الخدمة بأثر رجعي",
  Arabic: "اللغة العربية",
  English: "اللغة الإنجليزية",
  French: "اللغة الفرنسية",
  downloaded: "تم تنزيله",
  Subscriptioncosts10EGPday: "الإشتراك في هذه الخدمة يكلف 10 جنيهات يومياً",
  "This user already subscribed to the given product":
    "انت بالفعل مشترك في خدمة AmuseTheKidz",
  "This mobile number is blacklisted": "هذا الرقم غير متاح للإشتراك",
  Thisuseralreadysubscribedtothegivenproduct:
    "هذا المستخدم مشترك بالفعل في الخدمة",
  youarealreadysubscribedtoAmuseTheKidzservice:
    "انت بالفعل مشترك في خدمة AmuseTheKidz",
  WelcomeToAmuseTheKidzCompetition: "مرحباً بك في مسابقة AmuzeTheKidz",
  Pleaseenteranumberstartingwith012forOrange:
    "ملحوظة : ينبغي ان تدخل رقم يبدأ بـ 012 لشركة أورنج",
  Pleaseenteranumberstartingwith010forVodafone:
    "ملحوظة : ينبغي ان تدخل رقم يبدأ بـ 010 لشركة فودافون",
  Pleaseenteranumberstartingwith011forEtisalat:
    "ملحوظة : ينبغي ان تدخل رقم يبدأ بـ 011 لشركة إتصالات",
  Pleaseenteranumberstartingwith015forWE:
    "ملحوظة : ينبغي ان تدخل رقم يبدأ بـ 015 لشركة وي",
  Makesuremobilenumberyouenteredismatchingtheoperator:
    "تأكد من أن رقم الموبايل متوافق مع مشغل الشبكة",

  Pleasewait: " من فضلك إنتظر",
  secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked:
    "ثانية قبل ان تشترك بـ نفس الرقم لمفاداة الحظر ",
  Vodafone: "فودافون",
  We: "وي",
  Etisalat: "إتصالات",
  Orange: "أورنج",
  Yourmobilenumber: " رقم الموبايل",
  isalreadysubscribedtoAmusecompetition: " مشترك بالفعل في مسابقة أميوز",
  Enteryourmobilenumbertoconfirmjoiningthecompetition:
    "أدخل رقم موبايلك لتأكيد الإنضمام للمسابقة",
  Join: "إنضم",
  "you are subscriped to Amuse competition. To cancel subscription send “STOP ATK“ to":
    "أنت الان مشترك في مسابقة أميوز . لإلغاء الاشتراك ارسل “STOP ATK“ إلى",
  welcome: "مرحباً",
  "Failed To Charge User": "لا يوجد رصيد يكفي لإتمام الاشتراك",
  "Failed To Send Verification SMS": "فشل في إعادة إرسال  رسالة كود التأكيد",
  InvalidPinCodeOrPassword: "خطأ في رمز التحقق ",
  "you are already a subscripted user and can’t change your plan unless your current plan is ended or subscription is canceled.":
    "أنت مشترك بالفعل. يمكنك تغيير الباقة فقط إذا انتهت الباقة الحالية أو تم إلغاء الاشتراك",
  "To enjoy watching the content you have to subscribe first!":
    "لكي تستمتع بمشاهدة المحتوي ينبغي ان تشترك أولاً",
  free: "مجاناً",
  maximumLength20: "الحد الأقصى للإسم 20 حرف ",
  unavailableOffline: "غير متاح أوفلاين",
  YouareCurrentlyOfflinePleaseCheckYourInternetConnection:
    "أنت ألآن منقطع عن شبكة الإنترنت. من فضلك تفحص إتصال الإنترنت لديك",

  operatorCompatibility: "تأكد من أن رقم الموبايل متوافق مع مشغل الشبكة",
  anErrorOccured: "حدث خطأ",
  waitForNewQuestion: "لقد أجبت على سؤال اليوم، انتظر سؤال جديد في وقت لاحق",
  pleaseWaitTwoMinutes: ".يرجى الانتظار لمدة دقيقتين قبل اجراء طلب أخر",
  enterOTPCode: "من فضلك, أدخل رمز التحقق",
  TheSubscriptionCostWillBeDeterminedAfterSelectingTheOperator: "سوف يتم تحديد تكلفة الاشتراك بعد اختيار مشغل الشبكة",
  SubscriptionCosts: "الإشتراك في هذه الخدمة يكلف",
  EGPPerDay: "جنيه/يوم"
};
