import React, { useEffect, useState } from "react";
import boy from "../../assets/images/boy.png";
import camel from "../../assets/images/camel.svg";
import beboBelal from "../../assets/images/bebo_belal.png";
import { Col, Row, Modal, Button } from "antd";
import closeIcon from "assets/icons/close.svg";
import { getGiftQuestion, postQuestAnswer } from "./network";
import submitBtn from "../../assets/images/gift_submit_btn.png";
import oneLabel from "../../assets/icons/one_label.svg";
import twoLabel from "../../assets/icons/two_label.svg";
import threeLabel from "../../assets/icons/three_label.svg";
import fourLabel from "../../assets/icons/four_label.svg";
import { useTranslation } from "react-i18next";
import { toastError } from "helpers/toasters";
import ResponseModal from "./Components/ResponseModal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ROUTES from "constants/_routes";
import JoinToCompetitionModal from "modules/components/JoinToCompetitionModal";
import { hasSubscription } from "modules/TpaySubscription/network";
import { useDispatch, useSelector } from "react-redux";
import giftScreenLg from "../../assets/images/giftScreenLg.png";
import { changeLanguage } from "modules/components/network";
import { changeLang } from "store/lang";
// import { getCurrentLanguageToService } from "services";

function Gifts() {
  // const [searchParams, setSearchParams] = useSearchParams();
  // const { authorization } = useSelector((state) => state.auth);
  // const dispatch = useDispatch();
  // const language = !searchParams.get("lang") ? null : searchParams.get("lang");

  // function changeLangAction() {
  //   i18n.changeLanguage(language);
  //   dispatch(changeLang());
  //   getCurrentLanguageToService(language);
  // }
  // useEffect(() => {
  //   if (language) {
  //     changeLangAction();
  //   }
  // }, []);
  const [questionAnswers, updateQuestionsAnswers] = useState({});
  const [selectedAnswer, updateSelectedAnswer] = useState(null);
  const [isModalVisible, updateIsModalVisible] = useState(null);
  const [isAnswerSuccess, updateIsAnswerSuccess] = useState(null);
  const [showJoinToCompetition, setshowJoinToCompetition] = useState(false);
  const labelsList = [oneLabel, twoLabel, threeLabel, fourLabel];
  const [loading, setLoading] = useState(false);

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const phoneNumberPattern = /^(011|012|010|015)\d{8}$/;
  const { lang, dir } = useSelector((state) => state.lang);
  // const dispatch = useDispatch();
  // const [searchParams, setSearchParams] = useSearchParams();
  // const language = !searchParams.get("lang") ? lang : searchParams.get("lang");

  // function changeLangAction() {
  //   i18n.changeLanguage(language);
  //   dispatch(changeLang());
  //   getCurrentLanguageToService(language);
  // }
  // useEffect(() => {
  //   if (language) {
  //     changeLangAction();
  //   }
  // }, [language]);
  // const closeJoinModalHandler = () => {
  //   setJoinToCompetitionModalVisible(false);
  // };
  const phoneNumberhandler = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");

    setPhoneNumber(value);

    // Check if the entered phone number is valid in real-time
    if (value.length === 11) {
      if (phoneNumberPattern.test(value)) {
        setPhoneNumberError(""); // Clear the error message
        setIsPhoneNumberValid(true); // Phone number is valid
      } else {
        setPhoneNumberError("Enteravalidnumberstartingwith011012010or015");
        setIsPhoneNumberValid(false);
      }
    } else {
      setPhoneNumberError("Phonenumbermustbeexactly11digits");
      setIsPhoneNumberValid(false);
    }
  };

  const handleJoinButtonClick = () => {
    if (phoneNumber.length === 11 && phoneNumberPattern.test(phoneNumber)) {
      setLoading(true);
      // Phone number is valid, you can proceed with your logic
      let phoneNumberWithCode = "2" + phoneNumber;

      hasSubscription(
        phoneNumberWithCode,
        (success) => {
          if (success.data.hasTpaySubscription) {
            navigate(ROUTES.GIFTS, {
              state: {
                phoneNumberWithCode: phoneNumberWithCode,
              },
            });

            setshowJoinToCompetition(false);
            setLoading(false);
          } else {
            navigate(ROUTES.TPAYSUBSCRIPTION);

            setshowJoinToCompetition(false);
            setLoading(false);
          }
        },
        (fail) => {
          // navigate(ROUTES.TPAYSUBSCRIPTION);
          setshowJoinToCompetition(false);
          setLoading(false);
        }
      );
    } else {
      setPhoneNumberError("Enteravalidnumberstartingwith011012010or015");
      setIsPhoneNumberValid(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location.state?.phoneNumberWithCode) {
      setshowJoinToCompetition(true);
    } else {
      getGiftQuestion(
        (success) => {
          updateQuestionsAnswers(success.data);
        },
        (fail) => {
          if (fail?.status === 403) {
            updateIsModalVisible(true);

            console.log(fail);

            if (
              fail.data.message ===
              "you answered a question today, wait for a new question later."
            ) {
              updateIsAnswerSuccess("answered");
            } else {
              updateIsAnswerSuccess("noQuestionAndNoPreviousAnswer");
            }
          } else {
            updateIsModalVisible(true);
            updateIsAnswerSuccess("answered");
          }
        },
        location.state?.phoneNumberWithCode
      );
      setshowJoinToCompetition(false);
    }
  }, [location.state?.phoneNumberWithCode]);

  const handleSubmit = () => {
    if (selectedAnswer) {
      setLoading(true);
      let data = {
        questionId: questionAnswers.questionId,
        answerId: selectedAnswer,
        phone: location.state.phoneNumberWithCode,
      };

      postQuestAnswer(
        data,
        (success) => {
          document.body.style.overflow = "hidden";
          setLoading(false); // re-enable the button
          if (success.data.isSuccess) {
            console.log(success.data.message);
            updateIsModalVisible(true);
            updateIsAnswerSuccess("answered");
          } else {
            if (success.data.isCOrrect) {
              updateIsModalVisible(true);
              updateIsAnswerSuccess("success");
            } else {
              updateIsModalVisible(true);
              updateIsAnswerSuccess("fail");
            }
          }
        },
        (fail) => {
          setLoading(false);
          console.log("🚀 ~ handleSubmit ~ fail:", fail);
        }
      );
    } else {
      toastError(t("selectAnswer"));
    }
  };
  return (
    <div className="gifts-page">
      <div className="gifts-page__question-box">
        <div className="gifts-page__question-box__red_box pt-2">
          <div className="gifts-page__question-box__redImage">
            {/* <img src={giftScreenLg} alt='' /> */}
          </div>
          <Row>
            {/* transform: rotate(-11deg); position: absolute; top: 13%; */}
            {/* <Col xs={6} className='d-flex justify-content-end align-items-end'>
              <div className='gifts-page__camelImg'>
                <img src={camel} alt='camel' className='camel_img' />
              </div>
            </Col> */}
            <Col xs={12} style={{ margin: "0 auto" }}>
              <div className="d-flex justify-content-center align-items-start gifts-page__beboAskBelalImg main-section">
                <img
                  src={beboBelal}
                  alt="beboBelal"
                  className="bebo_belal_img"
                />
              </div>
              <div className="question_box text-center">
                {questionAnswers.question}
              </div>
            </Col>
            {/* <Col xs={6} className='d-flex align-items-end '>
              <div className='gifts-page__boyImg'>
                <img src={boy} alt='boy' className='boy_img' />
              </div>
            </Col> */}
          </Row>
        </div>
        {/* <div className='gifts-page__question-box__red_box py-2'></div> */}
        {/* <div
          className='gifts-page__question-box__white_box'
          style={{ overflow: "hidden", backgroundColor: "transparent" }}
        ></div> */}
      </div>
      <div className="gifts-page__answers-box d-flex justify-content-center">
        <Row
          style={{
            flexDirection: "row-reverse",
            marginTop: "2rem",
            width: "600px",
          }}
        >
          {questionAnswers?.answers?.map((answer, index) => {
            return (
              <Col
                xs={24}
                sm={12}
                className="text-center gifts-page__answers-box--col "
              >
                <div
                  onClick={() => {
                    updateSelectedAnswer(answer._id);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                  className={`${
                    answer._id === selectedAnswer ? "selected" : ""
                  } gifts-page__answers_box__container my-4 py-2 px-2`}
                >
                  <span
                    className={`${i18n.language === "en" ? "pe-4" : "ps-4"}`}
                    style={{ lineHeight: "1" }}
                  >
                    {answer.answer}
                  </span>
                  <img src={labelsList[index]} alt="labelIndex" style={{}} />
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="text-center gifts-page__submit_btn">
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={handleSubmit}
          disabled={loading}
        >
          <img
            src={submitBtn}
            alt="gifts-page__submit_btn_img"
            className="cursor-pointer"
            // onClick={handleSubmit}
          />
          <div
            className="gifts-page__submit_btn__text cursor-pointer"
            // onClick={handleSubmit}
          >
            {t("submit")}
          </div>
        </button>
      </div>
      <ResponseModal
        isModalVisible={isModalVisible}
        onCancel={() => {
          document.body.style.overflow = "";
          updateIsModalVisible(false);
          navigate(ROUTES.HOME);
        }}
        status={isAnswerSuccess}
      />
      {showJoinToCompetition && (
        <Modal
          centered
          open={showJoinToCompetition}
          onCancel={() => {
            if (location.state?.phoneNumberWithCode) {
              setshowJoinToCompetition(false);
            }
          }}
          className="joinCompetition-modal modal-container text-center"
          footer={false}
          closeIcon={<p>&nbsp;</p>}
        >
          <h2
            className="text-primary-header text-primary-header--joincompetition "
            style={{ marginBottom: "2.5rem" }}
          >
            {t("Enteryourmobilenumbertoconfirmjoiningthecompetition")}
          </h2>
          <div className="modal-input-container">
            <input
              type="text"
              id="MSISDN"
              className={`selector_container-phoneinput ${
                phoneNumberError
                  ? "error-input transition-border"
                  : "transition-border"
              }`}
              value={phoneNumber}
              onChange={phoneNumberhandler}
              placeholder="01XXXXXXXXX"
              pattern="[0-9]*" // This restricts input to numbers only
              inputMode="numeric"
            />
            {phoneNumberError ? (
              <p
                className="error-message--joinCompetition"
                style={{
                  display: "flex",
                  direction: dir,
                  marginBottom: ".5rem",
                }}
              >
                {t(phoneNumberError)}
              </p>
            ) : (
              <p className="error-message--joinCompetition">&nbsp;</p>
            )}
            <div className=" text-center m-auto">
              <Button
                loading={loading}
                className="button-primary-100vw button-primary-100vw--joinCompetition"
                onClick={handleJoinButtonClick}
              >
                {t("Join")}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Gifts;
