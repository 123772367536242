import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { deleteResource } from "services";

export function deleteUserAccount(
  { parentId, userAccountId },
  success,
  fail,
) {
  const data = {
    parentId,
    userAccountId,
  };
  const path = PATHS(PATHS_PARAMS.deleteUserAccount);
  deleteResource(path, success, fail, data);
}
