import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { getResource, postResource } from "services";

export function getQueryParamsForTpayScriptUrl(data, onSuccess, onFail) {
  const { lang } = data;
  const path = PATHS(PATHS_PARAMS.getQueryParamsForTpayScriptUrl, lang);
  getResource(path, onSuccess, onFail);
}
export function verifySubscriptionContract(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.verifySubscriptionTpay);
  postResource(path, data, onSuccess, onFail);
}
export function subscriptionCallback(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.subscriptionCallback);
  postResource(path, data, onSuccess, onFail);
}

export function hasSubscription(data, onSuccess, onFail) {
  const phone = data;

  const path = PATHS(PATHS_PARAMS.hasSubscription, null, { phone });
  getResource(path, onSuccess, onFail);
}
export function resendSubscriptionContractVerificationSMS(
  data,
  onSuccess,
  onFail,
) {
  const path = PATHS(PATHS_PARAMS.resendSubscriptionContractVerificationSMS);
  postResource(path, data, onSuccess, onFail);
}

export function sendSmsToSubscribedUser(data, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.sendSmsToAlreadySubscribedUser);
  postResource(path, data, onSuccess, onFail, false);
}
export function getOperatorDailyCost(onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.operatorDailyCost);
  getResource(path, onSuccess, onFail);
}