import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MainBanner from "./components/MainBanner";
import Generies from "./components/Generies";
import GeneriesVideos from "./components/GeneriesVideos";
import WebsiteTitle from "helpers/WebsiteTitle";
import {
  enterUserAccount,
  getAllLanguages,
  logoutUserAccount,
} from "../Authentications/network";
import MoreThanUsersAccountsModal from "modules/Shared/MoreThanUsersAccountsModal";
import { toastError } from "helpers/toasters";
import { checkPayment } from "./network";
import {
  addPaymentState,
  changeAuthData,
  onlineUser,
} from "store/authorization";
import Spinner from "modules/Shared/Spinner";
import SplashScreen from "modules/SplashScreen/SplashScreen";
import useSyncDeleteDownloads from "modules/MyDownloads/useSyncDeleteDownloads";
import { getUserAccounts } from "modules/WatchNow/network";
import { changeLanguage } from "modules/components/network";
import i18n from "localization/i18next";
import { changeLang } from "store/lang";
import { getCurrentLanguageToService } from "services";

const Home = () => {
  const dispatch = useDispatch();
  const { authorization } = useSelector((state) => state.auth);
  const { state } = useLocation();
  const { lang, userChangeLng } = useSelector((state) => state.lang);

  const [selectedGenereId, setSelectedGenreId] = useState(null);
  const [isMoreThanAccountsOpened, setIsMoreThanAccountsOpened] =
    useState(false);
  const [enteredUsers, setEnteredUsers] = useState([]);
  const [showSplash, setShowSplash] = useState(true);
  const [isLoading, updateIsLoading] = useState(state?.splashScreen);
  useSyncDeleteDownloads(authorization);

  useEffect(() => {
    if (authorization?.token && !state?.selectedUser) {
      checkPayment(
        (success) => {
          dispatch(addPaymentState(success.data));
        },
        (fail) => {}
      );
    }

    if (state?.selectedUser && authorization?.token) {
      const payload = {
        accountId: state.online._id,
        deviceId: authorization.deviceId,
        deviceName: authorization.browserName,
        userToken: authorization.token,
      };

      enterUserAccount(
        payload,
        (success) => {
          setIsMoreThanAccountsOpened(false);
          dispatch(onlineUser(state.online));
          dispatch(changeAuthData({ isEnteredUserAccount: true }));

          checkPayment(
            (success) => {
              dispatch(addPaymentState(success.data));
              updateIsLoading(false);
            },
            (fail) => {
              updateIsLoading(false);
            }
          );
        },
        (fail) => {
          if (fail?.data) {
            if (fail.status === 403) {
            } else {
              setIsMoreThanAccountsOpened(true);
              setEnteredUsers(fail.data.enteredUsers);
            }
            updateIsLoading(false);
          } else {
            toastError();
            updateIsLoading(false);
          }
        }
      );
    }
    if (!state?.selectedUser && !authorization?.token) {
      setTimeout(() => {
        updateIsLoading(false);
      }, 1500);
    }
  }, [state?.selectedUser, authorization?.token]);

  useEffect(() => {
    if (state?.scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [state]);

  // useEffect(() => {
  //   if (isLoading) {
  //     document.body.classList.add("splash-overflow-hidden");
  //   } else {
  //     document.body.classList.remove("splash-overflow-hidden");
  //   }
  //   return () => {
  //     document.body.classList.remove("splash-overflow-hidden");
  //   };
  // }, [isLoading]);

  // useEffect(() => {
  //   if (
  //     authorization?.online &&
  //     authorization?.online?.preferedLanguage &&
  //     !userChangeLng
  //   ) {
  //     console.log("FROM HOME ABBBBB");
  //     getAllLanguages(
  //       { lang },
  //       (success) => {
  //         const [language] = success.data.filter((language) => {
  //           return language.id === authorization?.online.preferedLanguage;
  //         });
  //         console.log("🚀~ language:", language);

  //         let userLanguage =
  //           language.name === "Arabic" || language.name === "اللغة العربية"
  //             ? "ar"
  //             : "en";

  //         let data = {
  //           accountId: authorization?.online?.id,
  //           lang: userLanguage,
  //           accountAppLanguage: userLanguage,
  //         };
  //         console.log("🚀~ DATA:", data);
  //         if (navigator.onLine) {
  //           changeLanguage(
  //             data,
  //             (success) => {},
  //             (fail) => {}
  //           );
  //         }

  //         i18n.changeLanguage(userLanguage);
  //         dispatch(changeLang(userLanguage));
  //         getCurrentLanguageToService(userLanguage);
  //         // dispatch(changeLang(userLanguage === "en" ? "ar" : "en"));
  //       },
  //       (fail) => {
  //         // setAllLanguages("No Data");
  //       }
  //     );
  //   }
  // }, [authorization?.online?.preferedLanguage, userChangeLng]);

  return (
    <>
      <div className={isLoading ? "splash-screen" : "splash-screen hidden"}>
        <SplashScreen />
      </div>

      <>
        <section className="main-section home-section">
          <WebsiteTitle />
          <MainBanner />
          <Generies setSelectedGenreId={setSelectedGenreId} />
          <GeneriesVideos selectedGenereId={selectedGenereId} />
        </section>

        {isMoreThanAccountsOpened && (
          <MoreThanUsersAccountsModal
            isVisible={isMoreThanAccountsOpened}
            onCancel={() => setIsMoreThanAccountsOpened(false)}
            enteredUsers={enteredUsers}
          />
        )}
      </>
    </>
  );
};

export default Home;
