export default function ReplayIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54.445"
      height="61.618"
      viewBox="0 0 54.445 61.618"
    >
      <g
        id="Group_24960"
        data-name="Group 24960"
        transform="translate(-770.737 -322.382)"
      >
        <g
          id="Group_5670"
          data-name="Group 5670"
          transform="translate(770.737 322.382)"
        >
          <g id="reload" transform="translate(0)">
            <g id="Group_1730" data-name="Group 1730">
              <path
                id="Path_4774"
                data-name="Path 4774"
                d="M84.25,34.4a27.222,27.222,0,0,1-54.445,0,1.615,1.615,0,0,1,3.229,0A23.993,23.993,0,1,0,57.027,10.4h-2.8l4.005,4.484a1.615,1.615,0,1,1-2.408,2.151L50.1,10.629a2.761,2.761,0,0,1,0-3.681L55.823.539A1.615,1.615,0,1,1,58.232,2.69L54.226,7.174h2.8A27.253,27.253,0,0,1,84.25,34.4Z"
                transform="translate(-29.805)"
                fill="#fafafa"
              />
            </g>
          </g>
        </g>
        <g
          id="Group_1735"
          data-name="Group 1735"
          transform="translate(337 123)"
        >
          <path
            id="Path_19240"
            data-name="Path 19240"
            d="M166,139.755V122.1a1.1,1.1,0,0,1,.611-.973,1.2,1.2,0,0,1,1.187.055l13.872,8.826a1.076,1.076,0,0,1,0,1.836L167.8,140.673A1.16,1.16,0,0,1,166,139.755Z"
            transform="translate(287.727 103.071)"
            fill="#f5f5f5"
          />
        </g>
      </g>
    </svg>
  );
}
