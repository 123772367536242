import { createSlice } from "@reduxjs/toolkit";

export const authorizationSlice = createSlice({
  name: "language",
  initialState: {
    lang: "en",
    dir: "ltr",
    userChangeLng: false,
  },
  reducers: {
    changeLang: (state, action) => {
      state.lang = action.payload || "en"; // Set language to payload if not null, otherwise default to "en"
      state.dir = state.lang === "ar" ? "rtl" : "ltr"; // Update direction accordingly

      // To fix bug 681
      document.documentElement.setAttribute("lang", state.lang);
      document.documentElement.setAttribute("dir", state.dir);
    },
    changeLangOnPayload: (state, action) => {
      state.lang = action.payload;
      state.dir = action.payload === "ar" ? "rtl" : "ltr";
    },
    // changeLangByUser: (state, action) => {
    //   state.userChangeLng = action.payload;
    // },
  },
});

const { reducer, actions } = authorizationSlice;
export const { changeLang, changeLangOnPayload } = actions;
export default reducer;
