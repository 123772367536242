import { t } from "i18next";
import React, { useEffect, useState } from "react";
import visa from "../../../../assets/icons/visa.svg";
import mastercard from "../../../../assets/icons/mastercard(2).svg";
import meeza from "assets/icons/meeza.png";
import noCredit from "../../../../assets/animated-images/no-credit-card.json";
import Lottie from "react-lottie";
import {
  deleteSavedCard,
  getSavedCards,
  setDefaultPaymenCard,
} from "modules/Subscription/network";
import { toastSuccess } from "helpers/toasters";
import { Radio, Switch } from "antd";
import ActionsModal from "modules/ActionsModal/ActionsModal";
import garbage from "assets/animated-images/garbage.json";
import Spinner from "modules/Shared/Spinner";

function PaymentDetails() {
  const [paymentDetails, updatePaymentDetails] = useState([]);
  const [defaultCard, updateDefaultCard] = useState(null);
  const [selectedCardId, updateSelectedCardId] = useState(null);
  const [isDeleteModalVis, updateDeleteModalVis] = useState(false);
  const [isLoading, updateIsLoading] = useState(true);

  const onDefaultCardChange = (e) => {
    const cardId = e.target.value;
    let data = {
      cardId,
    };
    updateDefaultCard(e.target.value);
    setDefaultPaymenCard(
      data,
      (success) => {},
      (fail) => {},
    );
  };

  const getAllSavedCards = () => {
    updateIsLoading(true);
    getSavedCards(
      (success) => {
        updateIsLoading(false);
        let allCards = [...success.data];
        updatePaymentDetails(allCards);
        const defaultCardId = allCards.filter((card) => card.isDefault);
        updateDefaultCard(defaultCardId[0]._id);
      },
      (fail) => {},
    );
  };

  useEffect(() => {
    getAllSavedCards();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: noCredit,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleDelete = () => {
    const cardId = selectedCardId;
    let data = {
      cardId,
    };
    deleteSavedCard(
      data,
      (success) => {
        if (success.isSuccess) {
          toastSuccess(success.message);
          getAllSavedCards();
        }
      },
      (fail) => {},
    );
  };

  return (
    <div className='payment-details my-4 py-4'>
      {!isLoading ? (
        // If isLoading is true
        paymentDetails?.length ? ( // Checking if paymentDetails exists and has a length
          <Radio.Group onChange={onDefaultCardChange} value={defaultCard}>
            {paymentDetails.map((card, index) => (
              <div className='my-2' key={card._id}>
                {/* Uncomment this div if needed */}
                {/* <div
            className={`payment-details__title my-2 ${
              index !== 0 && "d-none"
            }`}
          >
            {t("cardDetails")}
          </div> */}
                <div className='bg-white d-flex align-items-center justify-content-center ccContainer m-auto '>
                  <img
                    src={
                      card.cardType.toLowerCase() === "visa"
                        ? visa
                        : card.cardType.toLowerCase() === "mastercard"
                        ? mastercard
                        : meeza
                    }
                    alt='creditCard'
                    className='ccImage'
                  />
                  <span className='number'>{card.maskedPan}</span>
                  <div className='divider'></div>
                  <span
                    className='delete_btn cursor-pointer'
                    onClick={() => {
                      updateSelectedCardId(card._id);
                      updateDeleteModalVis(true);
                    }}
                  >
                    {t("delete")}
                  </span>
                </div>
                <Radio className='mt-4 d-flex f-16 fw-500' value={card._id}>
                  {t("makeDefaultCard")}
                </Radio>
              </div>
            ))}
          </Radio.Group>
        ) : (
          // If paymentDetails don't exist or have length 0
          <div className='py-4'>
            <Lottie
              isClickToPauseDisabled={true}
              width={180}
              height={180}
              options={defaultOptions}
            />
            <div className='payment-details__noCard py-4'>{t("noCard")}</div>
          </div>
        )
      ) : (
        // If isLoading is false
        <Spinner isNotFullPage={true} />
      )}
    </div>
  );
}

export default PaymentDetails;
