import React, { useState } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import closeIcon from "assets/icons/close.svg";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../constants/_routes.js";
function LogoutModal({ isModalVisible, onCancel }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const titleStyles = {
    color: "#356AB3",
    fontSize: "25px",
    fontWeight: "bold",
    width: "90%",
    margin: "auto",
  };

  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={onCancel}
      className='subscripe-now-modal modal-container text-center'
      footer={false}
      closeIcon={<img src={closeIcon} alt='icon' style={{ width: "24px" }} />}
    >
      <div style={titleStyles} className='my-4 py-4'>
        {t("sureToLogout")}
      </div>

      <Button
        className='button-primary button-primary--signIn'
        onClick={() => {
          navigate(ROUTES.LOGOUT);
          onCancel();
        }}
      >
        {t("logout")}
      </Button>
    </Modal>
  );
}

export default LogoutModal;
