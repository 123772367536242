import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import pageNotFound from "assets/images/not-found.svg";
import ROUTES from "constants/_routes";
import { Button } from "antd";

function PageNotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleGoToDownloads = () => {
    navigate(ROUTES.MY_DOWNLOADS);
  };
  return (
    <section className='main-section PageNotFound'>
      <Container>
        <div className='PageNotFound__content'>
          <div className='PageNotFound__title text-blue-header '>
            {t("oopsNotFound")}
          </div>

          <figure className='PageNotFound__fig '>
            <img src={pageNotFound} alt='this page not found' />
          </figure>
          {/* <div className='text-center m-auto'>
            <Button
              className='button-primary blue'
              onClick={handleGoToDownloads}
            >
              {t("goToDownloads")}
            </Button>
          </div> */}
          <Link to='/' role='button' className='PageNotFound__btn'>
            {t("backToHome")}
          </Link>
        </div>
      </Container>
    </section>
  );
}
export default PageNotFound;
