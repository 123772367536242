import { useLayoutEffect, useState } from "react";
import useSyncDeleteDownloads from "./useSyncDeleteDownloads";
import { dbInfo, deleteSpecificDataFromDB } from "helpers/indexdb";
import { deleteVideoFromDownloads } from "modules/WatchNow/network";
import useIsDownloaded from "./useIsDownloaded";

const useDeleteVideo = (authorization, videoId) => {
  const {
    isDownloaded,
    updateDownloadedState, // Return the update function
  } = useIsDownloaded(authorization, videoId);

  const [isDeleting, setIsDeleting] = useState(false);
  const [downloaded, setDownloaded] = useState(isDownloaded);
  const [isDeleted, setIsDeleted] = useState(false); // New state for isDeleted

  useLayoutEffect(() => {
    setDownloaded(isDownloaded);
  }, [isDownloaded]);

  const deleteVideo = () => {
    if (navigator.onLine && videoId) {
      setIsDeleting(true);
      const payload = {
        userAccountId: authorization.online._id,
        videoId: videoId,
        isDeleted: true,
      };
      deleteSpecificDataFromDB(
        authorization.online._id,
        dbInfo.dbVersion,
        authorization.online._id,
        videoId,
        () => {
          deleteVideoFromDownloads(
            payload,
            () => {
              updateDownloadedState();
              setIsDeleting(false);
              setDownloaded(false);
              setIsDeleted(true); // Update isDeleted state when video is deleted
            },
            () => {},
          );
        },
      );
    }
  };

  return { deleteVideo, isDeleting, downloaded, isDeleted }; // Include isDeleted in the return object
};

export default useDeleteVideo;
