const ROUTES = {
  HOME: "/",
  SIGNIN: "/signin",
  SIGNUP: "/signup",
  FORGET_PASSWORD: "/forget-password",
  CHANGE_PASSWORD: "/change-password",
  OTP: "/otp",
  FORGETPINCODE: "/forget-pincode",
  GIFTS: "/gifts",
  RESET_PASSWORD: "/reset-password",
  CREATE_PARENTAL_CODE: "/create-parental-code",
  VERIFY_PARENTAL_CODE: "/verify-parental-code",
  UPDATE_PARENTAL_CODE: "/update-parental-code",
  WATCHERS: "/watchers",
  MANAGE_ACCOUNT: "/manage-account",
  ADD_CHILDS: "/add-childs",
  DELETE_CHILDS: "/delete-childs",
  PERSONAL_INFORMATION: "/personal-information",
  SELECT_AVATAR: "/select-avatar",
  PAYMENT: "/pay",
  MY_LIST: "/my-list",
  MY_DOWNLOADS: "/my-downloads",
  WATCH_NOW: "/watch-now",
  CONTACT_US: "/contact-us",
  ACCOUNT_PROFILE: "/account-profile",
  MOVIE_PARTS: "/movie-parts",
  PAGE_NOT_FOUND: "/page-not-found",
  BANNER: "/banner",
  NUMOFUSERSPOPUPS: "/numOfUsersPopups",
  SEE_ALL: "see-all",
  LOGOUT: "/logout",
  SEARCH: "/search",
  SELECT_GENDER: "/select-gender",
  SUBSCRIPTION: "/subscription",
  SUBSCRIPTIONPLAN: "/subscription-plan",
  TERMSANDCONDITIONS: "/termsandconditions",
  PRIVACYPOLICY: "/privacypolicy",
  PRIVACYPOLICY_KOREAN: "/privacypolicy-korean",
  POSTPAY: "/postpay",
  STATIC: "/amuse",
  TPAYSUBSCRIPTION: "/tpaysubscription",
  TPAYVERIFICATION: "/tpayverification",
  NOINTERNET: "/noInternet",
  NOT_FOUND: "*",
};
export default ROUTES;

export const pagesNotHaveNavbar = [
  // ROUTES.FORGET_PASSWORD,
  ROUTES.WATCHERS,
  ROUTES.CREATE_PARENTAL_CODE,
  ROUTES.VERIFY_PARENTAL_CODE,
  ROUTES.UPDATE_PARENTAL_CODE,
  ROUTES.LOGOUT,
  ROUTES.STATIC,
  ROUTES.POSTPAY,
  ROUTES.FORGETPINCODE,
  ROUTES.MANAGE_ACCOUNT,
  ROUTES.ADD_CHILDS,
  ROUTES.SELECT_GENDER,
  ROUTES.SELECT_AVATAR,
];

export const pagesNotHaveFooter = [ROUTES.STATIC, ROUTES.POSTPAY];
