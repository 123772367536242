import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import closeIcon from "assets/icons/close.svg";
import {
  logoutUserAccount,
  enterUserAccount,
} from "../Authentications/network";
import { baseUrl } from "services";
import { toastError } from "helpers/toasters";
import { addPaymentState, changeAuthData } from "store/authorization";
import { checkPayment } from "modules/Home/network";
import { addTokenToMedia } from "helpers/addTokenToMedia";

const MoreThanUsersAccountsModal = ({
  onCancel,
  isVisible,
  enteredUsers,
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [closable, setClosable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { authorization } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (selectedUser) {
      setIsDisabled(false);
    }
  }, [selectedUser]);

  function handleOnCancle() {
    if (closable) {
      onCancel();
    }
  }

  function selectUser(user) {
    setSelectedUser(user);
  }

  function logUserOut() {
    if (selectedUser) {
      setLoading(true);
      const payload = {
        deviceId: selectedUser.deviceId,
        userId: authorization._id,
        accountId: selectedUser.accountId,
      };

      logoutUserAccount(
        payload,
        (success) => {
          const payload = {
            accountId: authorization.online._id,
            deviceId: authorization.deviceId,
            deviceName: authorization.browserName,
            userToken: authorization.token,
          };
          enterUserAccount(
            payload,
            (success) => {
              dispatch(
                changeAuthData({ isEnteredUserAccount: true }),
              );

              checkPayment(
                (success) => {
                  dispatch(addPaymentState(success.data));
                  setLoading(false);
                  setClosable(true);
                },
                (fail) => {
                  setLoading(false);
                },
              );
              onCancel();
            },
            (fail) => {
              if (fail?.data?.err_msg) {
                toastError(fail.data.err_msg);
              } else {
                toastError();
              }
            },
          );
        },
        (fail) => {
          setLoading(false);
          if (fail?.data?.err_msg) {
            toastError(fail?.data?.err_msg);
          } else {
            toastError();
          }
        },
      );
    }
  }

  return (
    <Modal
      centered
      width={"50vw"}
      visible={isVisible}
      onCancel={handleOnCancle}
      className='logout-modal'
      footer={false}
      closeIcon={<img src={closeIcon} alt='icon' />}
      closable={closable}
    >
      <header className='logout-modal__header'>
        <h4>{t("haveReachedMaxCapcity")}</h4>
      </header>

      <p className='logout-modal__p'>{t("alreadyHas4Devices")}</p>

      <div className='logout-modal__users'>
        {enteredUsers?.map((user, _idx) => (
          <div
            key={_idx}
            role={"button"}
            onClick={() => selectUser(user.deviceDetails)}
            className='logout-modal__user'
          >
            <figure
              className='logout-modal__fig'
              style={{
                border:
                  selectedUser?._id === user.deviceDetails._id
                    ? "2px solid #ED2024"
                    : "2px solid transparent",
                borderRadius: "50%",
                padding: "5px",
                transition: "all 0.5s ease-in-out",
              }}
            >
              <img
                src={addTokenToMedia(
                  baseUrl + user.profileDetails?.avatarPath,
                )}
                alt={user.profileDetails?.profileName}
              />
            </figure>
            <div
              className='logout-modal__name'
              style={{
                color:
                  selectedUser?._id === user.deviceDetails._id
                    ? "#ED2024"
                    : "#c1b7b7",
              }}
            >
              {user.deviceDetails.deviceName}
            </div>
          </div>
        ))}
      </div>
      <Row justify={"center"} className='btn-container'>
        <Col xs={{ span: 20 }} sm={{ span: 16 }} md={{ span: 10 }}>
          <Button
            loading={loading}
            className='logout-modal__btn button-primary-100vw  mx-auto'
            onClick={logUserOut}
            disabled={isDisabled}
          >
            {t("logItOut")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default MoreThanUsersAccountsModal;
