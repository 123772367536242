import { dbInfo, getSpecificDataFromDB } from "helpers/indexdb";
import { useEffect, useState } from "react";

const useIsDownloaded = (authorization, videoId) => {
  const [isDownloaded, setIsDownloaded] = useState(false);

  const updateDownloadedState = () => {
    getSpecificDataFromDB(
      authorization.online._id,
      dbInfo.dbVersion,
      { videoId },
      (videoData) => {
        setIsDownloaded(videoData?.videoId === videoId);
      },
      authorization.online._id,
    );
  };

  useEffect(() => {
    if (videoId && authorization?.online?._id) updateDownloadedState();
  }, [authorization?.online?._id, videoId]);

  return {
    isDownloaded,
    updateDownloadedState, // Return the update function
  };
};

export default useIsDownloaded;
