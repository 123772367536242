import { Progress } from "antd";

export default function DownloadProgress({ percentage, fileInfo }) {
  return (
    <div>
      <p style={{ textAlign: "start" }}>{fileInfo}</p>
      <Progress percent={percentage} />
    </div>
  );
}
