import React from "react";
import prevIcon from "assets/video-controllers/next.svg";

const PreviousVideoBtn = ({ isNotFirstVideoInSeries, selectPrevVideo }) => {
  return (
    isNotFirstVideoInSeries && (
      <img
        role="button"
        onClick={selectPrevVideo}
        src={prevIcon}
        style={{
          transform: "rotate(180deg)",
        }}
        alt="previous video"
      />
    )
  );
};

export default PreviousVideoBtn;
