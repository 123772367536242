import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import success from "assets/icons/payment-checked.svg";
import amuseLogo from "assets/images/amuse-logo.svg";
import fail from "assets/icons/payment-cross.svg";
import { useTranslation } from "react-i18next";
import ROUTES from "constants/_routes";

function PostPay() {
	const location = useLocation();
	const isSuccess =
		location.search.split("success=")[1]?.split("&")[0] === "true"
			? true
			: false;
	const { t } = useTranslation();
	const navigate = useNavigate();
	useEffect(() => {
		setTimeout(() => {
			if (isSuccess) {
				navigate(ROUTES.HOME);
			} else {
				navigate(ROUTES.SUBSCRIPTIONPLAN);
			}
		}, 5000);
	}, []);
	return (
		<div className="d-flex flex-column h-100 flex-1">
			<div className="text-center">
				<img src={amuseLogo} alt="amuseLogo" className="my-4" />
			</div>
			<div className="d-flex justify-content-center align-items-center flex-column flex-1">
				{isSuccess ? (
					<div className="text-center">
						<img src={success} alt="success" />
						<div className="my-4 f-25 fw-600 blue-text">
							{t("paymentApproved")}
						</div>
						<div className="f-22 dark-blue-text fw-600 my-2">{t("thanks")}</div>
					</div>
				) : (
					<div className="text-center">
						<img src={fail} alt="fail" />
						<div className="my-4 f-25 fw-600 blue-text">
							{t("somethingWentWrong")}
						</div>
						<div className="f-22 dark-blue-text fw-600">
							{t("pleaseTryAgain")}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default PostPay;
