import { getResource } from "services";
import { PATHS, PATHS_PARAMS } from "constants/PATHS";

export function getBanner({ lang, accountId }, onSuccess, onFail) {
  const bannerParams = {
    ...(accountId && { userId: accountId }),
    lang,
  };
  const path = PATHS(PATHS_PARAMS.getBanners, lang, bannerParams);
  getResource(path, onSuccess, onFail);
}

export function getGeners({ lang }, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getGenres, { lang: lang });
  getResource(path, onSuccess, onFail);
}

export function getCategoriesByGenreId(
  { lang, genreId, accountId },
  onSuccess,
  onFail
) {
  const data = {
    ...(genreId && { genreId }),
    ...(accountId && { userAccountId: accountId }),
    lang,
  };

  const path = PATHS(PATHS_PARAMS.getCategoriesByGenreId, lang, data);
  getResource(path, onSuccess, onFail);
}

export function getVideosOfCategory({ lang }, onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.getVideosOfCategory, lang);
  getResource(path, onSuccess, onFail);
}
export function checkPayment(onSuccess, onFail) {
  const path = PATHS(PATHS_PARAMS.checkPayment);
  getResource(path, onSuccess, onFail);
}
