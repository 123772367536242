import { baseUrl } from "services";

export const dbInfo = {
  dbName: "videoDBb",
  dbVersion: 1,
};
const createStore = (result, storeName, keyPath, indexList) => {
  const db = result;
  const objectStore = db.createObjectStore(storeName, { keyPath });
  //Create index to search with
  indexList.forEach((indexData) => {
    objectStore.createIndex(
      indexData.indexName,
      // indexData.indexName,
      {
        unique: indexData.unique,
      },
    );
  });
};

export const deleteSpecificDataFromDB = (
  dbName,
  dbVersion,
  userAccountId,
  videoId,
  onFinish,
) => {
  const requestt = window.indexedDB.open(dbName, dbVersion);
  requestt.onsuccess = async (event) => {
    const db = requestt.result;
    const transaction = db.transaction([dbName], "readwrite");
    const objectStore = transaction.objectStore(dbName);
    const request = objectStore.delete(videoId);
    request.onerror = (event) => {
      // Handle errors!
    };
    request.onsuccess = (event) => {
      onFinish();
      // getVideoData(event.target.result);
    };
  };
};
export const deleteAllDataFromDB = () => {
  const requestt = window.indexedDB
    .databases()
    .then((dbs) => {
      dbs.forEach((db) => {
        getAllDataFromDB(db.name, db.version, (data) => {
          const filteredItems = data.filter((item) => {
            const timeDownloaded = item.timeDownloaded;
            const currentTime = Date.now(); // Get the current time in milliseconds

            const differenceInMilliseconds = currentTime - timeDownloaded;
            const differenceInMinutes = Math.floor(
              differenceInMilliseconds / (1000 * 60),
            );

            return differenceInMinutes > 9000000000000;
          });
          filteredItems.map((item) => {
            deleteSpecificDataFromDB(
              db.name,
              db.version,
              db.name,
              item.videoId,
            );
          });
        });
      });
    })
    .catch((error) => {
      console.error("Error getting databases:", error);
    });
  requestt.onsuccess = async (event) => {
    // const db = requestt.result;
    // const transaction = db.transaction(userAccountId, "readonly");
    // const objectStore = transaction.objectStore(userAccountId);
    // const request = objectStore.delete(videoId);
    requestt.onerror = (event) => {
      // Handle errors!
    };
    requestt.onsuccess = (event) => {
      // getVideoData(event.target.result);
    };
  };
};

export const getAllDataFromDB = (
  dbName,
  dbVersion,
  getVideoData,
  userAccountId,
) => {
  const requestt = window.indexedDB.open(dbName, dbVersion);
  requestt.onsuccess = async (event) => {
    const db = requestt.result;
    const transaction = db.transaction([dbName]);
    const objectStore = transaction.objectStore(dbName);
    const request = objectStore.getAll();
    request.onerror = (event) => {
      // Handle errors!
    };
    request.onsuccess = (event) => {
      getVideoData(event.target.result);
    };
  };
};

export const getSpecificDataFromDB = (
  dbName,
  dbVersion,
  videoData,
  getVideoData,
  userAccountId,
) => {
  const requestt = window.indexedDB.open(dbName, dbVersion);
  requestt.onsuccess = async (event) => {
    const db = requestt.result;
    const transaction = db.transaction([userAccountId]);
    const objectStore = transaction.objectStore(userAccountId);
    const request = objectStore.get(videoData?.videoId);
    request.onerror = (event) => {
      // Handle errors!
    };
    request.onsuccess = (event) => {
      getVideoData(event.target.result);
    };
  };
};

const uploadVideoDataToDataBase = async (
  db,
  videoData,
  onFinish,
  userAccountId,
  updateProgressFiles,
) => {
  const uploadedVideoData = {
    title: videoData.title,
    videoId: videoData.videoId,
    videoHeaderId: videoData.videoHeaderId,
    genre: videoData.genre,
    category: videoData.category,
    timeDownloaded: videoData.timeDownloaded,
    audios: videoData.audios,
    quality: videoData.quality,
    videoServerPath: videoData.videoServerPath,
  };

  if (videoData.subtitles) {
    uploadedVideoData.subtitles = videoData.subtitles;
  }

  const promises = [];

  for (const pathDetails of videoData.videoPath) {
    const [type, path] = pathDetails;

    const response = await fetch(path);

    const contentLength = response.headers.get("content-length");

    let loaded = 0;

    const blob = await new Promise((resolve) => {
      const reader = response.body.getReader();

      const chunks = [];

      function read() {
        reader.read().then(({ done, value }) => {
          if (done) {
            resolve(new Blob(chunks));
            return;
          }

          loaded += value.byteLength;
          updateProgressFiles(
            Math.round((loaded / contentLength) * 100),
            response.headers.get("Content-Type"),
          );
          chunks.push(value);
          read();
        });
      }

      read();
    });

    uploadedVideoData[type] = blob;
  }

  try {
    // Now, you can add the data to the window.IndexedDB
    const objectStore = db
      .transaction([userAccountId], "readwrite")
      .objectStore(userAccountId);
    objectStore.put(uploadedVideoData);

    onFinish();
  } catch (error) {
    console.error(error);
  }
};

export const addDataToIndexDB = (
  dbName,
  dbVersion,
  videoData,
  onFinish,
  userAccountId,
  updateProgressFiles,
) => {
  const request = window.indexedDB.open(dbName, dbVersion);
  request.onerror = (event) => {
    console.error(event);
  };
  request.onupgradeneeded = (event) => {
    let storeData = {
      storeName: userAccountId,
      keyPath: "videoId",
      indexList: [{ indexName: "videoId", unique: true }],
    };
    createStore(
      request.result,
      storeData.storeName,
      storeData.keyPath,
      storeData.indexList,
    );
  };
  request.onsuccess = async (event) => {
    const db = request.result;
    uploadVideoDataToDataBase(
      db,
      videoData,
      onFinish,
      userAccountId,
      updateProgressFiles,
    );
  };
};

export const initializeDatabase = (dbName, dbVersion, userAccountId) => {
  const request = window.indexedDB.open(dbName, dbVersion);

  request.onerror = (event) => {
    console.error("Database error: ", event.target.errorCode);
  };

  request.onupgradeneeded = (event) => {
    const db = event.target.result;

    if (!db.objectStoreNames.contains(userAccountId)) {
      const objectStore = db.createObjectStore(userAccountId, {
        keyPath: "videoId",
      });
      // Define indexes if needed
      objectStore.createIndex("videoId", "videoId", { unique: true });
      // Additional indexes can be created if required
    }
  };
  request.onsuccess = (event) => {};
  // indexedDB
  //   .databases()
  //   .then((dbs) => {
  //     dbs.forEach((db) => {
  //
  //       deleteAllDataFromDB(db.name, db.version);
  //       // Perform actions or access the databases as needed
  //       // Please note: You cannot directly access the content of the databases from here.
  //     });
  //   })
  //   .catch((error) => {
  //     console.error("Error getting databases:", error);
  //   });
};

export const deleteDB = (dbName) => {
  var request = window.indexedDB.deleteDatabase(dbName);

  request.onsuccess = function () {};

  request.onerror = function (event) {};
};
