import React from "react";
import { Form, Input, InputNumber } from "antd";

const InputField = ({
  name,
  initialVal,
  handleOnChange,
  rules,
  itemClassName,
  inputClassName,
  inputType,
  placeholder,
  prefix,
  autoComplete,
  ...otherProps
}) => {
  return (
    <Form.Item
      name={name}
      rules={[...rules] || null}
      className={itemClassName}
      {...otherProps}
    >
      {name === "password" ||
      name === "confirm-password" ||
      name === "old-password" ||
      name === "new-password" ? (
        <Input.Password
          value={initialVal}
          onChange={(e) => handleOnChange(e.target.value)}
          className={inputClassName}
          placeholder={placeholder}
          prefix={prefix || null}
          autoComplete={name === "new-password" ? null : autoComplete}
        />
      ) : inputType === "number" ? (
        <InputNumber
          onChange={(value) => {
            handleOnChange(value);
          }}
          className={inputClassName}
          placeholder={placeholder}
          prefix={prefix || null}
          {...otherProps}
        />
      ) : (
        <Input
          type={inputType}
          // defaultValue={initialVal}
          value={initialVal}
          onChange={(e) => {
            handleOnChange(e.target.value);
          }}
          className={inputClassName}
          placeholder={placeholder}
          prefix={prefix || null}
          {...otherProps}
        />
      )}
    </Form.Item>
  );
};

export default InputField;
