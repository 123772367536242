import ROUTES from "constants/_routes";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resendSubscriptionContractVerificationSMS,
  sendSmsToSubscribedUser,
  verifySubscriptionContract,
} from "./network";
import { useDispatch, useSelector } from "react-redux";
import { OTPInputComponent } from "modules/Authentications/components/OTPInput/Index";
import { toastError, toastSuccess } from "helpers/toasters";
import { startedTime } from "store/timeSlice";

const TPAY_ORANGE_CODE = "60201";
const TPAY_VODAFONE_CODE = "60202";
const TPAY_ETISALAT_CODE = "60203";
const TPAY_WE_CODE = "60204";

const TPAY_ORANGE_CATALOG = "AMUSE-Orange-Egypt";
const TPAY_VODAFONE_CATALOG = "AMUSE-Vodafone-Egypt";
const TPAY_ETISALAT_CATALOG = "AMUSE-Etisalat-Egypt";
const TPAY_WE_CATALOG = "AMUSE-We-Egypt";
const _subscriptionPlanId = 2356; // integr
const _productSku = "AMUZE_THE_KIDZ@GIFT_MODULE"; // string
var _enriched = ""; // value will get assigned when "TPay.HeaderEnrichment.enriched()" function called.
var _operatorCode = ""; // will get assigned a value when user choose operator
var TPay = window.TPay || {};
const options = [
  { label: "Vodafone", value: TPAY_VODAFONE_CODE },
  { label: "We", value: TPAY_WE_CODE },
  { label: "Etisalat", value: TPAY_ETISALAT_CODE },
  { label: "Orange", value: TPAY_ORANGE_CODE },
];

const TIMER_DURATION = 120; // 2 minutes in seconds

export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
}

function TpayVerification() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { lang, dir } = useSelector((state) => state.lang);
  const [lastVerificationTime, setLastVerificationTime] = useState(0);
  const [cancelNumber, setCancelNumber] = useState("");
  // const [showTimer, setShowTimer] = useState(true);
  const [disableSubmition, setDisableSubmition] = useState(false);
  const [disableResendBtn, setDisableResendBtn] = useState(true);
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(false);

  const [timer, setTimer] = useState(Date.now() + 1000 * 10 * 12);
  // const [pinCode, setPinCode] = useState("");
  const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000)); // Initialize with the current time in seconds
  const timeDifference = currentTime - lastVerificationTime;

  const [verificatonErrors, setVerificationErrors] = useState(false);
  const [remainingTime, setRemainingTime] = useState(TIMER_DURATION);

  const otpInputRef = useRef();

  useEffect(() => {
    if (timeDifference > 120 && timer / 1000 - currentTime > 0) {
      setDisableSubmition(false); // Enable verification button
      setDisableResendBtn(true); // Disable resend button
    } else {
      setDisableSubmition(true); // Disable verification button
      setDisableResendBtn(false); // Enable resend button
    }
  }, [timer, timeDifference]);

  const TIMER_STORAGE_KEY = "tpayTimer";
  useEffect(() => {
    // Retrieve the timer value from localStorage on component mount
    const storedTimer = localStorage.getItem(TIMER_STORAGE_KEY);
    if (storedTimer) {
      const parsedTimer = parseInt(storedTimer, 10);
      setTimer(parsedTimer); // Set the timer to the stored value
    }
  }, []);

  useEffect(() => {
    // Retrieve the last verification time from localStorage on component mount
    const storedLastVerificationTime = parseInt(
      localStorage.getItem("lastVerificationTime"),
      10
    );
    if (storedLastVerificationTime) {
      setLastVerificationTime(storedLastVerificationTime);
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let handler, timer;

    if (verificatonErrors) {
      handler = setTimeout(() => {
        setVerificationErrors(false);
      }, 120000);

      timer = setInterval(() => {
        setRemainingTime((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setDisableSubmition(true);
            setDisableResendBtn(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => {
      clearTimeout(handler);
      clearInterval(timer);
    };
  }, [verificatonErrors]);

  useEffect(() => {
    if (lang === "en") {
      setWelcomeMessage(
        `Welcome ${location?.state?.phoneNumber} you are subscribed to Amuse competition. To cancel subscription send “STOP ATK“ to ${cancelNumber}`
      );
    } else {
      setWelcomeMessage(
        `مرحبا ${location?.state?.phoneNumber} أنت الان مشترك في مسابقة أميوز . لإلغاء الاشتراك ارسل “STOP ATK“ ${cancelNumber} إلى`
      );
    }
  }, [lang, cancelNumber]);

  // console.log("🚀~ welcomeMessage:", welcomeMessage);

  const getCancelNumber = (code) => {
    let number = "";

    switch (code) {
      case "60201":
        number = "5030"; // Orange
        break;
      case "60202":
        number = "2303"; // Vodafone
        break;
      case "60203":
        number = "1722"; // Etisalat
        break;
      case "60204":
        number = "4041"; // WE
        break;
      default:
        // Handle other cases or invalid input
        break;
    }

    return number;
  };

  useEffect(() => {
    // Check if a timer value exists in localStorage
    setCancelNumber(getCancelNumber(location?.state?.operatorCode));
    const storedTimer = localStorage.getItem(TIMER_STORAGE_KEY);
    if (storedTimer) {
      const parsedTimer = parseInt(storedTimer, 10);
      const currentTime = Date.now();
      const timeDifference = parsedTimer - currentTime;

      if (timeDifference > 0) {
        setTimer(parsedTimer); // Set the timer to the stored value
      }
    }
  }, []); // Run this effect only once on component mount

  useEffect(() => {
    // Save the timer value in localStorage whenever it changes
    localStorage.setItem(TIMER_STORAGE_KEY, timer);
  }, [timer]);

  useEffect(() => {
    if (!location?.state?.productCatalog) {
      navigate(ROUTES.TPAYSUBSCRIPTION);
    }
  }, []);

  const [pinCode, setPinCode] = useState("");
  const handlePinCodeChange = (pincode) => {
    setPinCode(pincode);
  };

  // function onfinishTimer() {
  //   setShowTimer(false);
  //   setDisableSubmition(true);
  // }

  const resendCode = () => {
    // setresendLoading(true);
    const payload = {
      subscriptionContractId: String(location.state.subscriptionContractId),
    };
    resendSubscriptionContractVerificationSMS(
      payload,
      (success) => {
        toastSuccess(success.message);
        setTimer(Date.now() + 1000 * 10 * 12);
        // setShowTimer(true);
      },
      (fail) => {
        // setresendLoading(false);
        if (fail?.data?.err_msg) {
          toastError(fail.data.err_msg);
        }

        console.log(fail);
      }
    );
  };

  const verifySubscriptionHandler = () => {
    setBtnDisabled(true);
    setRemainingTime(120);
    if (timeDifference < 120) {
      // If the time difference is less than 120 seconds, prevent verification
      // You can display a message or handle it accordingly
      // For example:
      console.log(
        "Please wait for 120 seconds before making another verification attempt."
      );
      return;
    }
    const payload = {
      subscriptionContractId: location.state.subscriptionContractId,
      pinCode: pinCode,
      transactionId: location.state.transactionId,
      operatorCode: location.state.operatorCode,
    };
    const smsPayload = {
      operatorCode: String(location.state.operatorCode),
      subscriptionContractId: String(location.state.subscriptionContractId),
      messageBody: welcomeMessage,
    };
    verifySubscriptionContract(
      payload,
      (success) => {
        // otpInputRef.current.resetInput();
        setBtnDisabled(false);
        sendSmsToSubscribedUser(
          smsPayload,
          (success) => {},
          (fail) => {}
        );
        navigate(ROUTES.GIFTS, {
          state: {
            phoneNumberWithCode: `2${location.state.phoneNumber}`,
          },
        });
      },
      (fail) => {
        otpInputRef.current.resetInput();
        setPinCode("");
        setBtnDisabled(false);
        setVerificationErrors(true);
        setLastVerificationTime(currentTime);
        localStorage.setItem("lastVerificationTime", currentTime);
        dispatch(
          startedTime({
            timestart: Math.floor(Date.now() / 1000),
            phoneNumber: location.state.phoneNumber,
          })
        );
        if (fail.data.err_msg) {
          toastError(t(fail.data.err_msg));
        }

        console.log(fail);
      }
    );
  };

  return (
    <div className="p-4 text-center" style={{ marginTop: "4.75rem" }}>
      <div
        className="phoneinput_container"
        style={{
          marginBottom: "6.25rem",
          display: "flex",
          gap: "2.5rem",
        }}
      >
        <label
          className="selector_container_label"
          htmlFor="MSISDN"
          style={{ marginBottom: "0" }}
        >
          {t("EnterThePinCode")}
        </label>{" "}
        <div className="box my-2">
          {" "}
          {/* <Countdown
            className="text-center auth__timer"
            value={timer}
            format="mm:ss"
            onFinish={onfinishTimer}
          /> */}
          {verificatonErrors && (
            <p className="text-center auth__timer">
              {formatTime(remainingTime)}
            </p>
          )}
        </div>
        <OTPInputComponent
          ref={otpInputRef}
          id="MSISDN"
          length={6}
          value={pinCode}
          autoFocus
          isNumberInput
          className="pinCodeContainer"
          inputClassName="PinCodeInput"
          verificatonErrors={verificatonErrors}
          // isSecure
          onChangeOTP={(pinCode) => {
            handlePinCodeChange(pinCode);
          }}
        />
        {verificatonErrors && (
          <p
            className="error-message error-message--verification"
            style={{ textAlign: dir == "ltr" ? "left" : "right" }}
          >
            {" "}
            {t(`Pleasewait`) +
              " " +
              // `${120 - timeDifference}` +
              " " +
              t(
                `secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked`
              )}{" "}
          </p>
        )}
        {/* {timeDifference < 120 && (
          <p
            className="error-message error-message--verification"
            style={{ textAlign: dir == "ltr" ? "left" : "right" }}
          >
            {" "}
            {t(`Pleasewait`) +
              " " +
              // `${120 - timeDifference}` +
              " " +
              t(
                `secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked`
              )}{" "}
          </p>
        )} */}
        {/* {period > 120 &&
          location.state.phoneNumber !== subscriptionRequest.phoneNumber && (
            <p
              className='error-message'
              style={{
                textAlign: dir === "ltr" ? "left" : "right",
                marginTop: "-1rem",
                fontSize: ".9rem",
                color: "rgba(0,0,0,.85)",
                fontWeight: "400",
              }}
            >
              {t(`Pleasewait`) +
                " " +
                `${120 - period}` +
                " " +
                t(
                  `secondsbeforemakinganothersubscriptionrequesttothesamenumbertoavoidbeingblocked`,
                )}
            </p>
          )} */}
        {/* <input
            type='text'
            id='MSISDN'
            // className={`selector_container-phoneinput ${
            //   phoneNumberError ? "error-input" : ""
            // }`}
            value={phoneNumber}
            onChange={phoneNumberhandler}
            placeholder='201XXXXXXXXX'
          /> */}
        {/* {phoneNumberError ? (
            <p className='error-message'>{phoneNumberError}</p>
          ) : (
            <p className='error-message'>&nbsp;</p>
          )} */}
      </div>
      <div>
        <button
          type="button"
          className="subscribe-btn"
          onClick={verifySubscriptionHandler}
          // disabled={disableSubmition}
          disabled={pinCode.length < 6 || verificatonErrors || btnDisabled}
        >
          {t("verify")}
        </button>
        <div className="auth__sub-text mb-4">
          <span>{t("didntGetCode")}</span>
          <button
            // disabled={disableResendBtn}
            onClick={resendCode}
            style={{ border: "none", backgroundColor: "transparent" }}
            // className={`auth__forget-pin-code-resend-code ${
            //   disableResendBtn ? "resend-code-inActive" : ""
            // }`}
            className={`auth__forget-pin-code-resend-code`}
          >
            {t("resendOtpCode")}
          </button>
          {/* {resendLoading && (
            <Spin
              size='small'
              style={{
                lineHeight: "1",
                width: "26px",
                height: "26px",
                fontSize: "24px",
                color: "#fd4885",
              }}
            />
          )} */}
        </div>
      </div>
    </div>
  );
}

export default TpayVerification;
