import React from "react";
import { useTranslation } from "react-i18next";
import WebsiteTitle from "helpers/WebsiteTitle";
import { LANG_PARAMS } from "localization/translation-params";

const Search = () => {
  const { t } = useTranslation();

  return (
    <>
      <WebsiteTitle title={t(LANG_PARAMS.search)} />
      <section className="main-section search">
        <header className="main-header">
          <h2>{t(LANG_PARAMS.search)}</h2>
        </header>
      </section>
    </>
  );
};

export default Search;
