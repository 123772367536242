import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Button, Input } from "antd";
import InputField from "../Authentications/components/InputField";
import InputPhone from "../Authentications/components/InputPhone";
import { contactUs } from "modules/Authentications/network";
import { toastError, toastSuccess } from "helpers/toasters";
import WebsiteTitle from "helpers/WebsiteTitle";
import { useSelector } from "react-redux";

function ContactUs() {
  const { lang } = useSelector((state) => state.lang);
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;
  const [isSubmitted, updateIsSubmitted] = useState(false);
  const [phoneValues, setPhoneValues] = useState({
    code: "20",
    phone: "",
    short: "EG",
  });
  const [name, setName] = useState();
  const [isAfterSubmit, setIsAfterSubmit] = useState(false);
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [textAreaBorder, setTextAreaBorder] = useState("");
  const [showError, setShowError] = useState(false);
  const [form] = Form.useForm();

  function nameValidation(e) {
    if (lang === "en") {
      if (!e.code.includes("Key") && !e.code.includes("Space")) {
        e.preventDefault();
      }
    }

    if (lang === "ar") {
      const allowChar = ["ج", "د", "ط", "ك", "ظ", "ز", "و", "ذ"];
      if (
        !e.code.includes("Key") &&
        !e.code.includes("Space") &&
        !allowChar.includes(e.key)
      ) {
        e.preventDefault();
      }
    }
  }

  function phoneValidation(phoneNumber) {
    setIsPhoneValid(false);
    if (phoneNumber?.length >= 10 && phoneNumber.length < 13) {
      setIsPhoneValid(true);
    }
  }

  const messageOnChange = (e) => {
    setMessage(e.target.value);
    setTextAreaBorder("");
  };

  function onSubmit() {
    setShowError(false);
    phoneValidation(phoneValues.phone);
    var ph;
    if (isPhoneValid) {
      setLoading(true);
      if (message?.length === 0) {
        setTextAreaBorder("error");
      } else {
        setTextAreaBorder("");
      }
      const codeIsEndWithZero = phoneValues?.code.toString().endsWith("0");
      const phoneIsEndWithZero = phoneValues.phone.startsWith("0");
      if (codeIsEndWithZero && phoneIsEndWithZero) {
        ph = phoneValues.phone.slice(1);
      } else {
        ph = phoneValues.phone;
      }

      setLoading(true);
      const payload = {
        name,
        email,
        phone: phoneValues.code + ph,
        message,
      };
      contactUs(
        payload,
        (success) => {
          if (success.isSuccess) {
            setLoading(false);
            toastSuccess(t("datasentSuccessfully"));
            setIsAfterSubmit(true);
            form.resetFields();
            form.resetFields(["phoneValues"]);
            setPhoneValues({
              code: "20",
              phone: "",
              short: "EG",
            });
          }
        },
        (fail) => {
          setLoading(false);
          if (fail.data) {
            toastError(fail.err_msg);
          } else toastError();
        },
      );
    }
  }

  const getErrorMessage = (value) => {};

  const getPhoneValues = (values) => {
    setPhoneValues(values);
  };

  const formRef = useRef(null);
  useEffect(() => {
    if (showError) {
      formRef.current.validateFields();
    }
  }, [i18n.language, showError]);

  return (
    <>
      <WebsiteTitle title={t("contactUs")} />
      <section className='main-section ContactUs'>
        <Container>
          <div className='ContactUs__title text-blue-header mt-5 mb-3'>
            {t("contactUs")}
          </div>
          <Form
            ref={formRef}
            form={form}
            initialValues={(name, email, phoneValues, message)}
            className='ContactUs__form '
            onFinish={onSubmit}
            onFinishFailed={() => {
              setShowError(true);
            }}
          >
            <div className='ContactUs__content '>
              <div className='ContactUs__inputs'>
                <div className='ContactUs__Input name '>
                  <InputField
                    name='name'
                    placeholder={t("name")}
                    initialVal={name}
                    onKeyPress={nameValidation}
                    handleOnChange={setName}
                    itemClassName='auth__form-item'
                    rules={[
                      {
                        required: true,
                        message: t("provideName"),
                      },
                    ]}
                  />
                </div>

                <div className='ContactUs__Input email '>
                  <InputField
                    name='email'
                    itemClassName='auth__form-item'
                    placeholder={t("email")}
                    initialVal={email}
                    handleOnChange={setEmail}
                    rules={[
                      {
                        required: true,
                        message: t("provideEmail"),
                      },
                      {
                        type: "email",
                        message: t("provideValidEmail"),
                      },
                    ]}
                  />
                </div>

                <div className='auth__input-phone'>
                  <InputPhone
                    name='phoneValues'
                    isSubmitted={isSubmitted}
                    itemClassName={"auth__form-item"}
                    getErrorMessage={getErrorMessage}
                    getPhoneValues={getPhoneValues}
                    phoneValues={phoneValues}
                    mode='initial'
                    key={i18n.language}
                    isAfterSubmit={isAfterSubmit}
                  />
                </div>
              </div>

              <div className='ContactUs__message'>
                <Form.Item
                  name={"message"}
                  rules={[
                    {
                      required: true,
                      message: t("provideMessage"),
                    },
                  ]}
                >
                  <TextArea
                    initialVal={message}
                    onChange={messageOnChange}
                    name='textArea'
                    className='ContactUs__messageText'
                    placeholder={t("YourMessage")}
                    style={{
                      color: "#27ABE3",
                    }}
                    autoSize={{
                      minRows: 7,
                    }}
                    status={textAreaBorder}
                  />
                </Form.Item>
              </div>
              {/* <div className="auth__phoneError  bord">{error}</div> */}
            </div>
            <div className='ContactUs__btn-send'>
              <Button
                loading={loading}
                className='button-primary ContactUs__send'
                htmlType='submit'
                type='primary'
                onClick={() => {
                  setIsAfterSubmit(false);
                  updateIsSubmitted(true);
                }}
              >
                {t("Send")}
              </Button>
            </div>
          </Form>
        </Container>
      </section>
    </>
  );
}

export default ContactUs;
