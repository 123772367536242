import { useEffect, useState } from "react";
import { Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAllVideosByCategoryId } from "./network";
import { toastError } from "helpers/toasters";
import Skeleton from "react-loading-skeleton";
import WebsiteTitle from "helpers/WebsiteTitle";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import VideoListItem from "modules/Shared/VideoListItem";
import Navbar from "modules/components/Navbar";

const SeeAll = () => {
  const [allVideosData, setAllVideosData] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const { lang } = useSelector((state) => state.lang);
  const { id: categoryId } = useParams();
  const { authorization } = useSelector((state) => state.auth);
  const location = useLocation();

  useEffect(() => {
    const payload = {
      lang,
      categoryId,
      ...(authorization?.online?.id && {
        accountId: authorization?.online?.id,
      }),
    };
    getAllVideosByCategoryId(
      payload,
      (success) => {
        setAllVideosData(success.data);
        setDataLoading(false);
      },
      (fail) => {
        setDataLoading(false);
        toastError();
      }
    );
  }, [lang, categoryId, authorization?.online?.id]);

  return (
    <>
      <Navbar />
      <section className="main-section see-all-category">
        <Container>
          <div>
            {allVideosData && (
              <WebsiteTitle title={allVideosData[0]?.category} />
            )}
            <header className="see-all-category__header">
              <h4>
                {location.state?.title || allVideosData[0]?.category || (
                  <Skeleton width={150} />
                )}
              </h4>
            </header>
            <Row gutter={[32, 32]}>
              {dataLoading &&
                new Array(4).fill(0).map((item, _) => (
                  <Col xs={24} md={6} key={_}>
                    <Skeleton height={232} width={"100%"} borderRadius={25} />
                  </Col>
                ))}
              {allVideosData &&
                allVideosData.map((video, _) => (
                  <Col xs={24} md={6} key={_}>
                    <VideoListItem
                      details={{
                        header: {
                          imgPath: video.imgPath,
                          title: video.videoHeaderTitle,
                          _id: video.videoHeaderId,
                          isWished: video.isWished,
                          category: video.category,
                          genre: video.genre,
                        },
                      }}
                      key={_}
                    />
                  </Col>
                ))}
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default SeeAll;
