import React, { useEffect, useState } from "react";
import maximizeIcon from "assets/video-controllers/Fullscreen-maximize.svg";
import minimizeIcon from "assets/video-controllers/Fullscreen-minimize.svg";

const FullScreenBtn = ({ isFullScreen, onFullScreenChange }) => {
  return (
    <img
      className="full-screen"
      role={"button"}
      onClick={() => onFullScreenChange()}
      src={isFullScreen ? minimizeIcon : maximizeIcon}
      // src={maximizeIcon}
      alt="full-screen-icon"
    />
  );
};

export default FullScreenBtn;
