import { PATHS, PATHS_PARAMS } from "constants/PATHS";
import { postResource } from "services";

export function addDeleteWishList(
  { accountId, videoId, isDeleted },
  success,
  fail
) {
  const data = {
    userAccountId: accountId,
    videoId,
    isDeleted,
  };
  const path = PATHS(PATHS_PARAMS.addToWishList);
  postResource(path, data, success, fail);
}
