import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Form, Button } from "antd";
import { toastError, toastSuccess } from "helpers/toasters";
import { createParentalCode, updateParentalCode } from "../network";
import amuseLogo from "assets/images/amuse-logo.svg";
import { login } from "store/authorization";
import ROUTES from "constants/_routes";
import { OTPInputComponent } from "../components/OTPInput/Index";

function UpdateParentalCode() {
  const { authorization } = useSelector((state) => state.auth);
  const [code, setCode] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSubmitted && code.length !== 4) {
      setError(true);
    } else {
      setError(false);
    }
  }, [code.length, isSubmitted]);

  const { state } = useLocation();

  let parentId = authorization.userAccounts.filter(
    (el) => el.isParent === true
  )[0]?.id;

  function onSubmit() {
    setIsSubmitted(true);
    if (code?.length === 4) {
      setError(false);
      const payload = {
        code,
        parentId,
        oldCode: state.oldCode,
      };
      setLoading(true);
      updateParentalCode(
        payload,
        (success) => {
          setLoading(false);
          if (success.isSuccess) {
            dispatch(login({ ...authorization, pinCode: code }));
            toastSuccess(t("pinCodeUpdatedSuccess"));
            navigate(ROUTES.HOME);
          }
        },
        (fail) => {
          setLoading(false);
          if (fail.data) toastError(fail.data.err_msg);
          else toastError(t("pinUpdateFaild"));
        }
      );
    } else {
      setError(true);
    }
  }
  return (
    <section className='main-section PartenalCode'>
      <Container>
        <div className='single-centered-logo'>
          <Link to={ROUTES.HOME}>
            <img src={amuseLogo} alt='amuse the kids' />
          </Link>
        </div>
        <div className='single-centered-title'>
          {t("editPinCode")}
        </div>
        <div className='single-centered-subtitle'>
          {t("editFourDigitCode")}
        </div>
        <Form onSubmitCapture={onSubmit}>
          <div className='otp__container  text-center'>
            <div className='d-flex justify-content-center' dir='ltr'>
              <OTPInputComponent
                length={4}
                isNumberInput
                autoFocus
                className='otpContainer'
                inputClassName='otpInput'
                hasErrored={error}
                errorStyle={{
                  border: "1px solid red",
                }}
                onChangeOTP={setCode}
              />
              {/* <OtpInput
                className='otp__single-container mb-1'
                value={code}
                onChange={setCode}
                isInputNum
                numInputs={4}
                shouldAutoFocus
                hasErrored={error}
                errorStyle={{
                  border: "1px solid red",
                }}
                isRTL={true}
              /> */}
            </div>
          </div>
          <div
            className='otp__errorMessage'
            style={{
              visibility: error ? "visible" : "hidden",
              transition: "all 0.5s ease-in-out",
              opacity: error ? 1 : 0,
            }}
          >
            {t("enterValidCode")}
          </div>
          <div className='pay__button-primary--cent'>
            <Button
              className='button-primary parentalCode-btn'
              htmlType='submit'
              loading={loading}
            >
              {t("saveChanges")}
            </Button>
          </div>
        </Form>
      </Container>
    </section>
  );
}

export default UpdateParentalCode;
