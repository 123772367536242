import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  getBannerById,
  getBannerDetails,
  getRelatedMovieParts,
  getRelatedSeriesSessons,
} from "./network";
import { toastError } from "helpers/toasters";
import Banner from "modules/Shared/Banner";
import Cast from "./Cast";
import PartsOrSeries from "./PartsOrSeries";
import WebsiteTitle from "helpers/WebsiteTitle";

const BannerDetails = () => {
  const [bannerData, setBannerData] = useState(null);
  const [castData, setCastData] = useState(null);
  const [partsOrSeriesData, setPartsOrSeriesData] = useState(null);
  // loading
  const [castLoading, setcastLoading] = useState(true);
  const [bannerLoading, setBannerLoading] = useState(true);
  const [partsOrSeriesLoading, setPartsOrSeriesLoading] = useState(true);

  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const { lang } = useSelector((state) => state.lang);
  const { authorization } = useSelector((state) => state.auth);

  useEffect(() => {
    const payload = {
      bannerId: params.banner,
      ...(authorization?.online?.id && { userId: authorization?.online?.id }),
      lang,
    };

    getBannerById(
      payload,
      (success) => {
        setBannerData(success.data[0]);
        setBannerLoading(false);
      },
      (fail) => {
        toastError();
        setBannerLoading(false);
      }
    );
  }, [lang, params.banner, authorization?.online?.id]);

  useEffect(() => {
    if (bannerData) {
      let payload = {
        videoHeaderId: bannerData.videoHeader._id,
        isSeries: bannerData.videoHeader.isSeries,
        ...(authorization?.online?.id && { userId: authorization?.online?.id }),
        lang,
      };

      getBannerDetails(
        payload,
        (success) => {
          setcastLoading(false);
          setCastData(success.data.casts);
        },
        (fail) => {
          setcastLoading(false);
          toastError();
        }
      );

      payload.videoId = bannerData.video?._id;
      if (bannerData.videoHeader.isSeries) {
        getRelatedSeriesSessons(
          payload,
          (success) => {
            setPartsOrSeriesLoading(false);
            setPartsOrSeriesData(success.data);
          },
          (fail) => {
            setPartsOrSeriesLoading(false);
            toastError();
          }
        );
      } else {
        getRelatedMovieParts(
          payload,
          (success) => {
            setPartsOrSeriesData(success.data);
            setPartsOrSeriesLoading(false);
          },
          (fail) => {
            setPartsOrSeriesLoading(false);
            toastError();
          }
        );
      }
    }
  }, [lang, bannerData, authorization?.online?.id]);

  return (
    <section>
      {bannerData && <WebsiteTitle title={bannerData?.videoHeader?.title} />}
    </section>
  );
};

export default BannerDetails;
