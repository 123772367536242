import React from "react";
import Skeleton from "react-loading-skeleton";

const VideoTitle = ({ videoTitle }) => {
  return (
    <div className="video-controller__upper__upper">
      <header className="video-details__header">
        <h4>{videoTitle ? videoTitle : <Skeleton width={250} />}</h4>
      </header>
    </div>
  );
};

export default VideoTitle;
