import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

function SplashScreen() {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 100, color: "#ED2024" }} spin />
  );
  return <Spin indicator={antIcon} />;
}

export default SplashScreen;
