import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ROUTES from "constants/_routes";
import { useSelector } from "react-redux";
import VerifyOldCodeModal from "./VerifyOldCodeModal";

function AccountInProfile() {
  const { t } = useTranslation();
  const { authorization } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const isPaid = authorization?.isPaid?.isPaid;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [showVerifyCodeModal, setShowVerifyCodeModal] = useState(false);

  const handleEditPinCode = () => {
    setShowVerifyCodeModal(true);
  };

  return (
    <section className='main-section  AccountInProfile'>
      <Container className=' '>
        <div className='AccountInProfile__content  mt-5 mb-3'>
          <button
            onClick={() => navigate(ROUTES.CHANGE_PASSWORD)}
            className='AccountInProfile__btn mt-4 '
          >
            {t("changePassword")}
          </button>

          <button
            onClick={() => navigate(ROUTES.PERSONAL_INFORMATION)}
            className='AccountInProfile__btn mt-4 '
          >
            {t("changePersonalInformation")}
          </button>

          <button
            // onClick={() => navigate(ROUTES.UPDATE_PARENTAL_CODE)}
            onClick={handleEditPinCode}
            className='AccountInProfile__btn mt-4 '
          >
            {t("editPinCode")}
          </button>

          {/* <button
            onClick={() => navigate(ROUTES.MY_DOWNLOADS)}
            className='AccountInProfile__btn mt-4 '
          >
            {t("myDownloads")}
          </button> */}

          <button
            onClick={() => {
              if (isPaid) {
                navigate(ROUTES.SUBSCRIPTIONPLAN);
              } else {
                navigate(ROUTES.SUBSCRIPTION);
              }
            }}
            className='AccountInProfile__btn mt-4 '
          >
            {t("mySubscription")}
          </button>
          {/* <button
            onClick={() => {
              if (isPaid) {
                navigate(ROUTES.SUBSCRIPTIONPLAN);
              } else {
                navigate(ROUTES.SUBSCRIPTION);
              }
            }}
            className='AccountInProfile__btn mt-4 '
          >
            {t("LeaveCompetition")}
          </button> */}
        </div>
      </Container>

      {showVerifyCodeModal && (
        <VerifyOldCodeModal
          open={showVerifyCodeModal}
          onCancel={() => setShowVerifyCodeModal(false)}
        />
      )}
    </section>
  );
}

export default AccountInProfile;
