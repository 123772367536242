import React, { useEffect } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import ROUTES from "../constants/_routes.js";
import SignIn from "modules/Authentications/SignIn";
import SignUp from "modules/Authentications/SignUp";
import ForgetPassword from "modules/Authentications/Password/ForgetPassword";
import ChangePassword from "modules/Authentications/Password/ChangePassword";
import OtpCodeVerification from "modules/Authentications/Password/OtpCodeVerification";
import ResetPassword from "modules/Authentications/Password/ResetPassword";
import Home from "modules/Home/Home";
import PersonalInformation from "./Authentications/PersonalInformation";
import Watchers from "./Watchers/Watchers";
import SelectAvatar from "./SelectAvatar/SelectAvatar";
import Pay from "./Pay/Pay";
import AddChilds from "./AddChilds/AddChilds";
import ManageAccount from "./ManageAccount/ManageAccount";
import MyList from "./MyList/MyList";
import WatchNow from "./WatchNow/WatchNow";
import ContactUs from "./ContactUs/ContactUs";
import AccountInProfile from "./AccountInProfile/AccountInProfile";
import Logout from "./Authentications/Logout";
import CreateParentalCode from "./Authentications/ParentalCode/CreateParentalCode";
import VerifyParentalCode from "./Authentications/ParentalCode/VerifyParentalCode";
import PageNotFound from "./PageNotFound/PageNotFound";
import UpdateParentalCode from "./Authentications/ParentalCode/UpdateParentalCode";
import DeleteChilds from "./DeleteChilds/DeleteChilds";
import BannerDetails from "./BannerDetails/BannerDetails.js";
import SeeAll from "./SeeAll/SeeAll.js";
import Search from "./Search/Search.js";
import SelectGender from "./SelectAvatar/Pages/SelectGender.js";
import Gifts from "./Gifts/Gifts.js";
import Subscription from "./Subscription/Subscription.js";
import SubscriptionPlan from "./Subscription/SubscriptionPlan.js";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions.js";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy.js";
// import MyDownloads from "./MyDownloads/MyDownloads.js";
import Static from "./Static/index";
import PostPay from "./PostPay/PostPay.js";
import ForgetPinCode from "./Authentications/ParentalCode/ForgetPinCode.js";
import TpaySubscription from "./TpaySubscription/TpaySubscription.js";
import TpayVerification from "./TpaySubscription/TpayVerification.js";
import NoInternetPage from "./NoInternet/NoInternetPage.js";
import PrivacyKorean from "./PrivacyPolicy/PrivacyKorean.js";

function RoutesFile() {
  const { authorization } = useSelector((state) => state.auth);
  const isPaid = authorization?.isPaid?.isPaid;

  const navigate = useNavigate();
  const location = useLocation();

  const handleHomeRoutes = () => {
    if (!authorization?.token) return <Home />;
    // if (!authorization?.token) return <SignIn />;
    if (authorization?.token && !authorization.online)
      return <Navigate to={ROUTES.WATCHERS} />;
    if (authorization?.token && authorization?.online) return <Home />;
  };

  const watchVideo = location.pathname.includes(ROUTES.WATCH_NOW);
  useEffect(() => {
    const handleConnectionStatus = () => {
      if (navigator.onLine) {
        if (!watchVideo) {
          navigate(-1, { replace: true });
        }
      } else {
        if (!watchVideo) {
          navigate(ROUTES.NOINTERNET);
        }
      }
    };
    window.addEventListener("online", handleConnectionStatus);
    window.addEventListener("offline", handleConnectionStatus);

    return () => {
      window.removeEventListener("online", handleConnectionStatus);
      window.removeEventListener("offline", handleConnectionStatus);
    };
  }, [watchVideo, navigate]);

  return (
    <Routes>
      <Route exact path={ROUTES.HOME} element={handleHomeRoutes()} />

      <Route
        path={ROUTES.SIGNIN}
        element={
          authorization?.token ? <Navigate to={ROUTES.HOME} /> : <SignIn />
        }
      />

      <Route path={ROUTES.SIGNUP} element={<SignUp />} />

      <Route path={ROUTES.FORGET_PASSWORD} element={<ForgetPassword />} />
      <Route
        path={ROUTES.CHANGE_PASSWORD}
        element={
          authorization?.token ? (
            <ChangePassword />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      />
      <Route path={ROUTES.OTP} element={<OtpCodeVerification />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} replace />
      <Route
        path={ROUTES.PERSONAL_INFORMATION}
        element={
          authorization?.token ? (
            <PersonalInformation />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      />
      <Route
        path={ROUTES.WATCHERS}
        element={
          authorization?.token ? <Watchers /> : <Navigate to={ROUTES.SIGNIN} />
        }
      />

      <Route path={ROUTES.OTP} element={<OtpCodeVerification />} />
      <Route path={ROUTES.TPAYSUBSCRIPTION} element={<TpaySubscription />} />
      <Route path={ROUTES.TPAYVERIFICATION} element={<TpayVerification />} />
      <Route path={ROUTES.FORGETPINCODE} element={<ForgetPinCode />} />
      <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />

      <Route
        path={ROUTES.SELECT_GENDER}
        element={
          authorization?.token ? (
            <SelectGender />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
          // authorization?.token && authorization.online?.isParent ? (
          //   <SelectGender />
          // ) : (
          //   <Navigate to={ROUTES.SIGNIN} />
          // )
        }
      />
      <Route
        path={ROUTES.SELECT_AVATAR}
        element={
          authorization?.token ? (
            <SelectAvatar />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      >
        <Route path=':accountId'></Route>
      </Route>
      <Route
        path={ROUTES.PAYMENT}
        element={
          authorization?.token ? <Pay /> : <Navigate to={ROUTES.SIGNIN} />
        }
      />
      <Route
        path={ROUTES.ADD_CHILDS}
        element={
          authorization?.token ? <AddChilds /> : <Navigate to={ROUTES.SIGNIN} />
        }
      >
        <Route
          path=':accountId'
          element={
            authorization?.token ? (
              <AddChilds />
            ) : (
              <Navigate to={ROUTES.SIGNIN} />
            )
          }
        />
      </Route>
      <Route
        path={ROUTES.DELETE_CHILDS}
        element={
          authorization?.token ? (
            <DeleteChilds />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      />
      <Route
        path={ROUTES.MANAGE_ACCOUNT}
        element={
          authorization?.token ? (
            <ManageAccount />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
          // authorization?.token && authorization?.online?.isParent ? (
          //   <ManageAccount />
          // ) : (
          //   <Navigate to={ROUTES.SIGNIN} />
          // )
        }
      />
      <Route
        path={ROUTES.MY_LIST}
        element={
          authorization?.online?.id ? (
            <MyList />
          ) : authorization?.token ? (
            <Navigate to={ROUTES.WATCHERS} />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      />
      {/* DOWNLOADS: */}
      {/* <Route
        path={ROUTES.MY_DOWNLOADS}
        element={
          authorization?.token ? (
            <MyDownloads />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      /> */}
      <Route
        path={ROUTES.WATCH_NOW}
        element={
          authorization?.token ? <WatchNow /> : <Navigate to={ROUTES.SIGNIN} />
        }
      />

      {/* <Route
        path={ROUTES.WATCH_NOW}
        element={
          authorization?.token ? (
            isPaid ? (
              <WatchNow />
            ) : (
              <Navigate to={ROUTES.HOME} />
            )
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      /> */}
      <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
      <Route path={ROUTES.POSTPAY} element={<PostPay />} />
      <Route
        path={ROUTES.ACCOUNT_PROFILE}
        element={
          authorization?.token ? (
            <AccountInProfile />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      />
      <Route
        path={ROUTES.CREATE_PARENTAL_CODE}
        element={<CreateParentalCode />}
      />
      <Route
        path={ROUTES.UPDATE_PARENTAL_CODE}
        element={
          authorization?.pinCode ? (
            <UpdateParentalCode />
          ) : (
            <Navigate to={ROUTES.CREATE_PARENTAL_CODE} />
          )
        }
      />
      <Route
        path={ROUTES.VERIFY_PARENTAL_CODE}
        element={
          authorization?.pinCode ? (
            <VerifyParentalCode />
          ) : (
            <Navigate to={ROUTES.CREATE_PARENTAL_CODE} />
          )
        }
      />
      <Route
        path={ROUTES.VERIFY_PARENTAL_CODE}
        element={
          authorization?.pinCode ? (
            <VerifyParentalCode />
          ) : (
            <Navigate to={ROUTES.CREATE_PARENTAL_CODE} />
          )
        }
      />
      <Route path={ROUTES.SEE_ALL + "/:id"} element={<SeeAll />} />
      <Route path={ROUTES.BANNERBANNER_DETAILS} element={<BannerDetails />} />
      <Route path={ROUTES.SEARCH} element={<Search />} />
      <Route path={ROUTES.LOGOUT} element={<Logout />} replace />
      <Route path={ROUTES.GIFTS} element={<Gifts />} />
      <Route
        path={ROUTES.SUBSCRIPTION}
        element={
          authorization?.token ? (
            <Subscription />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      />
      <Route
        path={ROUTES.SUBSCRIPTIONPLAN}
        element={
          authorization?.token ? (
            <SubscriptionPlan />
          ) : (
            <Navigate to={ROUTES.SIGNIN} />
          )
        }
      />
      <Route
        path={ROUTES.TERMSANDCONDITIONS}
        element={<TermsAndConditions />}
      />
      <Route path={ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTES.PRIVACYPOLICY_KOREAN} element={<PrivacyKorean />} />
      {/* <Route path={ROUTES.NOINTERNET} element={<Navigate to={ROUTES.HOME} />} /> */}
      <Route path={ROUTES.STATIC} element={<Static />} />
      <Route path={ROUTES.NOT_FOUND} element={<PageNotFound />} />
      <Route path={ROUTES.NOINTERNET} element={<NoInternetPage />} />
    </Routes>
  );
}

export default RoutesFile;
