import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { baseUrl } from "services";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useSearchParams,
  createSearchParams,
} from "react-router-dom";
import ROUTES from "constants/_routes";

import UpperController from "./VideoComponents/UpperController";
import LowerController from "./VideoComponents/LowerController";
import { detectInternetSpeed } from "helpers/DetectInternetSpeed";
import { continueWatching } from "../network";
import { addTokenToMedia } from "helpers/addTokenToMedia";
function VideoShowNew({
  videoData,
  relatedPartsData,
  seriesOrParts,
  getCurrentSeasonId,
}) {
  let timeout;

  const [selectedSubtitle, updateSelectedSubtitle] = useState(0);
  const [selectedAudio, updateSelectedAudio] = useState(0);
  const [selectedQuality, updateSelectedQuality] = useState(0);
  const [subtitlesList, updateSubtitlesList] = useState([]);
  const [isOnline, setOnline] = useState(null);

  const [videoPath, setVideoPath] = useState("");
  const [subtitlePath, setSubtitlePath] = useState("");
  const [audioPath, setAudioPath] = useState("");

  const [isVideoReady, setIsVideoReady] = useState(false);
  const [isAudioReady, setIsAudioReady] = useState(false);
  const [isMediaReady, setIsMediaReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentVideoTime, setCurrentVideoTime] = useState(0);
  const [isEnded, setIsEnded] = useState(false);

  const [currentVideoBufferd, setCurrentVideoBufferd] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [showControls, setShowControls] = useState(true);
  const seekingTime = 10;
  const videoFullScreenWrapper = useRef();
  const interval = useRef();

  const [isReady, updateIsReady] = useState(false);
  const [isNavHidden, updateIsNavHidden] = useState(false);
  const [isInsideControls, updateIsInsideControls] = useState(false);
  const [isFullScreen, updateIsFullScreen] = useState(false);
  const [bufferedTime, updateBufferedTime] = useState(0);
  const [showLists, updateShowLists] = useState({
    audios: false,
    subtitles: false,
    quality: false,
    volume: false,
  });
  const [soundValue, updateSoundValue] = useState(100);
  const [isMuted, updateIsMuted] = useState(false);
  const [currentSeasonIndex, updateCurrentSeasonIndex] = useState(null);
  const [progress, updateProgress] = useState(0);
  const [duration, updateDuration] = useState(0);
  const { authorization } = useSelector((state) => state.auth);
  const [searchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const [videoHeaderId, videoId, isSeries, showFullScreen] =
    params.qp.split("-");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const lowerControllerRef = useRef(null);
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const trackRef = useRef(null);
  const videoElement = videoRef?.current;

  const audioElement = audioRef?.current;
  const trackElement = trackRef?.current;
  // const timeToIncDec = 10;
  const qualityList = [
    { languageName: t("auto"), key: 0 },
    { languageName: t("hd"), key: 1 },
    { languageName: t("low"), key: 2 },
  ];

  //
  //
  //
  //
  //
  //
  //
  //
  //
  // const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");

  const currentVideoIndex = relatedPartsData[currentSeasonIndex]?.seasonName
    ? relatedPartsData[currentSeasonIndex].videos.find(
        (video) => video.videoId === videoData.videoId,
      )?.videoIndex
    : relatedPartsData.find((video) => video.videoId === videoData.videoId)
        ?.videoIndex;

  const isNotFirstVideoInSeries = relatedPartsData[currentSeasonIndex]
    ?.seasonName
    ? !(
        parseInt(currentSeasonIndex) === 0 &&
        parseInt(currentVideoIndex) - 1 === 0
      )
    : parseInt(currentVideoIndex) - 1 !== 0;

  const isNotLastVideoInSeries = relatedPartsData[currentSeasonIndex]
    ?.seasonName
    ? !(
        currentSeasonIndex === relatedPartsData.length - 1 &&
        relatedPartsData[currentSeasonIndex]?.videos?.length ===
          currentVideoIndex
      )
    : relatedPartsData.length !== currentVideoIndex;

  /**______________ FUNCTIONS ______________ */

  /**HANDEL MUTED SOUND */
  const handleChangeSound = (volume) => {
    if (volume === "mute") {
      updateIsMuted(!isMuted);
      audioElement.muted = !isMuted;
    } else {
      updateSoundValue(volume);
      audioElement.volume = volume / 100;
      const isMuted = volume === 0 ? true : false;
      updateIsMuted(isMuted);
      audioElement.muted = isMuted;
    }
  };
  /**HANDEL CHANGE TRACKS AUDIO,QUALITY AND SUBTITLE */
  const onTrackChange = (trackIndex, isAudio, isQuality) => {
    if (isQuality) {
      updateSelectedQuality(trackIndex);
    } else if (isAudio) {
      if (trackIndex === selectedAudio) return;
      videoElement.pause();
      setIsAudioReady(false);
      setIsVideoReady(false);
      selectAudio(trackIndex);
    } else {
      selectSubtitle(trackIndex);
    }
  };

  /**SENDING TIME FOE CONTINUE WATCHING FEATURE */
  const sendTimeForContinueWatching = (videoStatus = "play") => {
    if (isOnline) {
      let data = {
        LastTime:
          videoStatus === "finished"
            ? "0"
            : Math.round(currentVideoTime).toString(),
        userAccountId: authorization.online._id,
        videoId: videoData.videoId,
      };

      if (videoStatus === "play") {
        if (
          Math.round(currentVideoTime % 3) === 0 &&
          Math.round(currentVideoTime !== 0) &&
          isPlaying
        ) {
          continueWatching(
            data,
            (success) => {},
            (fail) => {
              localStorage.setItem(
                "lastSeenData",
                (currentVideoTime * 1000).toString() +
                  "-" +
                  authorization.online._id +
                  "-" +
                  videoId,
              );
            },
          );
        }
      } else {
        continueWatching(
          data,
          (success) => {},
          (fail) => {
            localStorage.setItem(
              "lastSeenData",
              (currentVideoTime * 1000).toString() +
                "-" +
                authorization.online._id +
                "-" +
                videoId,
            );
          },
        );
      }
    }
  };

  /**HANDLE SHOW AND HIDE CONTROLS LISTS */
  const handleShowHideLists = (listName, state) => {
    let allShowLists = {
      audios: false,
      subtitles: false,
      quality: false,
      volume: false,
    };
    allShowLists[listName] = state;
    updateShowLists(allShowLists);
  };

  const getVttPaths = (subtitles) =>
    subtitles.filter((subtitle) => subtitle.vttPath);

  const handleAutoPlayNextVideo = () => {
    if (
      relatedPartsData[currentSeasonIndex]?.seasonName
        ? !(
            relatedPartsData[currentSeasonIndex]?.videos?.length ===
              currentVideoIndex &&
            currentSeasonIndex === relatedPartsData.length - 1
          )
        : relatedPartsData.length !== currentVideoIndex
    ) {
      selectNextVideo();
    } else {
      setIsEnded(true);
    }
  };

  /**
    Calculate Bufferd Time to video
   */
  const getBufferedTime = () => {
    const buffer = videoElement?.buffered;

    if (((buffer?.length > 0 && videoElement?.duration) || 0) > 0) {
      let currentBuffer = 0;
      const inSeconds = videoElement?.currentTime || 0;

      for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
          currentBuffer = i;
          break;
        }
      }

      setCurrentVideoBufferd(buffer.end(currentBuffer) * 1000 || 0);
    }
  };

  const onFullScreenChange = () => {
    if (!isFullScreen) {
      videoFullScreenWrapper.current.requestFullscreen();
      updateIsFullScreen(true);
    }
    if (isFullScreen) {
      document.exitFullscreen();
      updateIsFullScreen(false);
    }
    // if (!isFullScreen) {
    //   if (videoElement.requestFullscreen) {
    //     videoElement.requestFullscreen();
    //   } else if (videoElement.mozRequestFullScreen) {
    //     videoElement.mozRequestFullScreen();
    //   } else if (videoElement.webkitRequestFullscreen) {
    //     videoElement.webkitRequestFullscreen();
    //   } else if (videoElement.msRequestFullscreen) {
    //     videoElement.msRequestFullscreen();
    //   }
    // } else {
    //   if (document.exitFullscreen) {
    //     document.exitFullscreen();
    //   } else if (document.mozCancelFullScreen) {
    //     document.mozCancelFullScreen();
    //   } else if (document.webkitExitFullscreen) {
    //     document.webkitExitFullscreen();
    //   } else if (document.msExitFullscreen) {
    //     document.msExitFullscreen();
    //   }
    // }

    // videoElement.controls = false;
  };

  const selectSubtitle = (subtitleIndex) => {
    if (videoData?.subtitles) {
      updateSelectedSubtitle(subtitleIndex);
    }
  };

  const selectAudio = (audioIndex) => {
    if (videoData?.audios) {
      updateSelectedAudio(audioIndex);
    }
  };

  function add10Sec() {
    if (videoElement.duration - videoElement.currentTime > 10) {
      videoElement.currentTime += seekingTime;
      // audioElement.currentTime = videoElement.currentTime;
    }
    if (videoElement.duration - videoElement.currentTime <= 10) {
      videoElement.currentTime = videoElement.duration;
      // audioElement.currentTime = videoElement.currentTime;
    }
  }

  function minus10Sec() {
    if (videoElement.currentTime > 10) {
      videoElement.currentTime -= seekingTime;
      // audioElement.currentTime = videoElement.currentTime;
    }
    if (videoElement.currentTime <= 10) {
      videoElement.currentTime = 0;
      // audioElement.currentTime = videoElement.currentTime;
    }
  }

  function selectPrevVideo() {
    if (relatedPartsData[currentSeasonIndex]?.seasonName) {
      let currentSeasonIndexVar = currentSeasonIndex;
      let currentVideoIndexVar = currentVideoIndex;
      if (currentVideoIndexVar - 1 === 0 && currentSeasonIndexVar !== 0) {
        currentSeasonIndexVar -= 1;
        updateCurrentSeasonIndex(currentSeasonIndexVar);
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[
            relatedPartsData[currentSeasonIndexVar].videos.length - 1
          ].videoId,
        );
        getCurrentSeasonId(seriesOrParts[currentSeasonIndexVar]._id);
      } else {
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[currentVideoIndex - 2]
            .videoId,
        );
      }
    } else {
      selectVideo(relatedPartsData[currentVideoIndex - 2].videoId);
    }
  }

  function selectNextVideo() {
    let currentSeasonIndexVar = currentSeasonIndex;
    let currentVideoIndexVar = currentVideoIndex;

    if (relatedPartsData[currentSeasonIndexVar]?.seasonName) {
      if (
        relatedPartsData[currentSeasonIndexVar]?.videos?.length ===
          currentVideoIndexVar &&
        currentSeasonIndexVar !== relatedPartsData.length - 1
      ) {
        currentSeasonIndexVar += 1;
        updateCurrentSeasonIndex(currentSeasonIndexVar);
        selectVideo(relatedPartsData[currentSeasonIndexVar].videos[0].videoId);

        getCurrentSeasonId(seriesOrParts[currentSeasonIndexVar]._id);
      } else {
        selectVideo(
          relatedPartsData[currentSeasonIndexVar].videos[currentVideoIndexVar]
            .videoId,
        );
      }
    } else {
      selectVideo(relatedPartsData[currentVideoIndexVar]?.videoId);
    }
  }

  function selectVideo(videoId) {
    let qpData = [videoHeaderId, videoId, isSeries].join("-");
    let qp = {
      qp: qpData,
    };
    setIsEnded(false);
    navigate({
      pathname: ROUTES.WATCH_NOW,
      search: `?${createSearchParams(qp)}`,
    });
  }

  /**______________ HANDEL AUDIO EVENTS ______________ */
  const handleAudioCanPlayThrough = (e) => {
    if (videoData.lastSeenTime) return;
    if (videoData.lastSeenTime) setIsAudioReady(true);

    // if (isPlaying) videoElement.play();
  };

  const handleAudioLoadData = () => {
    setIsVideoReady(false);
    setIsAudioReady(false);
  };

  const handleAudioLoadedData = () => {
    setIsVideoReady(true);
    setIsAudioReady(true);
  };

  /**______________ HANDEL VIDEO EVENTS ______________ */
  const handleVideoCanPlayThrough = (e) => {
    if (videoData.lastSeenTime) return;
    if (videoData.lastSeenTime) setIsVideoReady(true);
  };

  const handlePlay = () => {
    videoElement.play();
  };

  const handlePause = () => {
    //
    videoElement.pause();
    setIsPlaying(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      handlePause();
    } else {
      handlePlay();
    }
  };

  const handleVideoPause = (e) => {
    // audioElement.currentTime = e.target.currentTime;
    if (isMediaReady) {
      audioElement.pause();
    }
  };

  const handleVideoPlaying = (e) => {
    audioElement.currentTime = e.target.currentTime;
    if (isMediaReady) {
      audioElement.play();
      setIsPlaying(true);
    }
  };

  const handleOnTimeUpdate = (e) => {
    setCurrentVideoTime(e.target.currentTime * 1000);
    sendTimeForContinueWatching("play");
  };

  const handleVideoWaiting = () => {
    //
    audioElement.pause();
  };

  const handleVideoSeeking = (e) => {
    setIsVideoReady(false);
    setIsAudioReady(false);
  };

  const handleVideoSeeked = (e) => {
    setIsVideoReady(true);
    setIsAudioReady(true);
    if (isPlaying) audioElement.play();
    setIsEnded(false);
  };

  const handleVideoEnded = () => {
    //
    sendTimeForContinueWatching("finished");
    handleAutoPlayNextVideo();
  };

  const handleVideoError = () => {
    setIsAudioReady(false);
  };

  const handleTimeChange = (time) => {
    if (!videoElement?.currentTime) {
      return;
    }

    videoElement.currentTime = time / 1000;
    audioElement.currentTime = time / 1000;
    //
    setCurrentVideoTime(time);
  };

  const handleVideoLoadedData = (e) => {
    //
    setVideoDuration(e.target.duration * 1000);
  };

  const handleVideoLoadStart = (e) => {
    //
    // setIsVideoReady(false);
  };

  const handleVideoStalled = (e) => {
    //
    setIsAudioReady(false);
  };

  const handelVideoOnProgress = () => {
    getBufferedTime();
  };

  useEffect(() => {
    if (!isVideoReady || !isAudioReady) setIsMediaReady(false);
    if (isVideoReady && isAudioReady) setIsMediaReady(true);
  }, [isVideoReady, isAudioReady]);

  useEffect(() => {
    if (videoData && isOnline) {
      if (selectedQuality === 0) {
        const speed = detectInternetSpeed();
        speed
          .then((res) => {
            const path =
              res <= 0.9 && videoData?.lowQualityVideoPath
                ? videoData.lowQualityVideoPath
                : videoData.videoPath;
            if (path) {
              setVideoPath(path);
            }
          })
          .catch((err) => {
            const path = videoData.lowQualityVideoPath
              ? videoData.lowQualityVideoPath
              : videoData.videoPath;
            if (path) {
              setVideoPath(path);
            }
          });
      } else {
        if (selectedQuality === 1) setVideoPath(videoData.videoPath);
        if (selectedQuality === 2) setVideoPath(videoData.lowQualityVideoPath);
      }
    }
  }, [selectedQuality, videoData, isOnline]);

  useEffect(() => {
    if (videoData?.audios?.length && audioElement) {
      setAudioPath(videoData?.audios[selectedAudio]?.path);
    }
  }, [videoData, audioElement, selectedAudio]);

  useEffect(() => {
    if (videoData?.subtitles?.length && trackElement)
      setSubtitlePath(videoData?.subtitles[selectedSubtitle]?.vttPath);
  }, [selectedSubtitle, trackElement]);

  useEffect(() => {
    if (showControls && isPlaying)
      setTimeout(() => {
        setShowControls(false);
      }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [showControls, isPlaying]);

  useEffect(() => {
    const onFullscreenChange = () => {
      updateIsFullScreen(document.fullscreenElement !== null);
    };
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
    };
  }, [isFullScreen]);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  useEffect(() => {
    if (showFullScreen === "true") {
      onFullScreenChange();
    }
  }, [showFullScreen]);

  /**SET VIDEO TIME FOR THE LAST SEEN TIME */
  useEffect(() => {
    if (videoData.lastSeenTime && videoElement && audioElement) {
      setCurrentVideoTime(+videoData.lastSeenTime);
      videoElement.currentTime = +videoData.lastSeenTime / 1000;
      audioElement.currentTime = +videoData.lastSeenTime / 1000;
    }
  }, [videoData.lastSeenTime, videoElement, audioElement]);

  useEffect(() => {
    if (videoData?.subtitles) {
      const vttPaths = getVttPaths(videoData.subtitles);
      updateSubtitlesList([
        { languageName: "Off", _id: "off", path: null },
        ...vttPaths,
      ]);
    }
  }, [videoData]);

  return (
    <div
      onMouseMove={() => setShowControls(true)}
      onMouseLeave={() => setShowControls(false)}
      className='watch-now__video-show__wrapper'
    >
      <div ref={videoFullScreenWrapper} className='watch-now__video-show'>
        <video
          // controls
          // autoPlay
          onClick={() => setShowControls((showControl) => !showControl)}
          src={addTokenToMedia(baseUrl + videoPath)}
          muted
          key={videoData}
          ref={videoRef}
          crossOrigin='anonymous'
          className='watch-now__video'
          poster={addTokenToMedia(baseUrl + videoData?.imgPath)}
          onPlaying={handleVideoPlaying}
          onSeeking={handleVideoSeeking}
          onSeeked={handleVideoSeeked}
          // onPlayingCapture={handleVideoPlayingCapture}
          onPause={handleVideoPause}
          // onPauseCapture={handleVideoPauseCapture}
          // onLoadedMetadata={handleVideoLoadedMetadata}
          onLoadedData={handleVideoLoadedData}
          onLoadStart={handleVideoLoadStart}
          onCanPlayThrough={handleVideoCanPlayThrough}
          onTimeUpdate={handleOnTimeUpdate}
          onWaiting={handleVideoWaiting}
          onProgress={handelVideoOnProgress}
          onEnded={handleVideoEnded}
          onError={handleVideoError}
          // onAbort={handleVideoAport}
          onStalled={handleVideoStalled}
        >
          <track
            ref={trackRef}
            label={subtitlesList[selectedSubtitle]?.languageName}
            srclang={subtitlesList[selectedSubtitle]?.language}
            src={addTokenToMedia(
              baseUrl + subtitlesList[selectedSubtitle]?.vttPath,
            )}
            default={true}
            kind='captions'
          />
        </video>
        <div
          className={`video-controller__wrapper video-controller ${
            showControls ? "d-flex" : "d-none"
          }`}
        >
          <UpperController
            handleMinus10Sec={minus10Sec}
            handlePlus10Sec={add10Sec}
            handlePlayPause={handlePlayPause}
            isMediaReady={isMediaReady}
            isEnded={isEnded}
            isPlaying={isPlaying}
            videoTitle={videoData?.title}
          />
          <LowerController
            isMediaReady={isMediaReady}
            isNotFirstVideoInSeries={isNotFirstVideoInSeries}
            selectPrevVideo={selectPrevVideo}
            videoData={videoData}
            showLists={showLists}
            handleShowHideLists={handleShowHideLists}
            onTrackChange={onTrackChange}
            selectedAudio={selectedAudio}
            subtitlesList={subtitlesList}
            selectedSubtitle={selectedSubtitle}
            qualityList={qualityList}
            selectedQuality={selectedQuality}
            soundValue={soundValue}
            handleChangeSound={handleChangeSound}
            isMuted={isMuted}
            isNotLastVideoInSeries={isNotLastVideoInSeries}
            selectNextVideo={selectNextVideo}
            onFullScreenChange={onFullScreenChange}
            isFullScreen={isFullScreen}
            videoDuration={videoDuration}
            currentVideoTime={currentVideoTime}
            handleTimeChange={(time) => handleTimeChange(time)}
            currentVideoBufferd={currentVideoBufferd}
          />
        </div>
      </div>
      <audio
        controls
        src={addTokenToMedia(baseUrl + audioPath)}
        ref={audioRef}
        onCanPlayThroughCapture={handleAudioCanPlayThrough}
        onLoad={handleAudioLoadData}
        onLoadedData={handleAudioLoadedData}
      ></audio>
    </div>
  );
}

export default VideoShowNew;
