import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Space } from "antd";
import { useTranslation } from "react-i18next";
import closeIcon from "assets/icons/close.svg";
import Skeleton from "react-loading-skeleton";
import { Image } from "react-image-and-background-image-fade";
import { baseUrl } from "services";
import { deleteUserAccount } from "./network";
import { toastError, toastSuccess } from "helpers/toasters";
import DetectWindowSize from "helpers/DetectWindowSize";
import { addTokenToMedia } from "helpers/addTokenToMedia";
import {
  dbInfo,
  deleteDB,
  deleteSpecificDataFromDB,
  getAllDataFromDB,
} from "helpers/indexdb";
import {
  enterUserAccount,
  logoutUserAccount,
} from "modules/Authentications/network";
import { logout } from "store/authorization";
import { useNavigate } from "react-router-dom";
import ROUTES from "constants/_routes";

const DeleteModal = ({
  isModalVisible,
  onCancel,
  user,
  userAccounts,
  setUserAccounts,
}) => {
  const { t } = useTranslation();
  const [breakPointData, setBreakPointData] = useState();

  const [loading, setLoading] = useState(false);
  const {
    authorization: { online },
  } = useSelector((state) => state.auth);

  const parentAcc = userAccounts.find((user) => user.isParent);
  const { authorization } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleOnCancel() {
    // setUserAccounts((prev) => {});
    onCancel();
  }

  function deleteUser() {
    setLoading(true);
    const payload = {
      parentId: parentAcc?.id,
      userAccountId: user._id,
    };
    deleteUserAccount(
      payload,
      (success) => {
        deleteDB(user._id);
        if (authorization?.online?._id === user._id) {
          const payload = {
            deviceId: authorization.deviceId,
            userId: authorization?._id,
            accountId: authorization?.online?._id,
          };

          logoutUserAccount(
            payload,
            (success) => {
              dispatch(logout());
              navigate(ROUTES.SIGNIN);
            },
            (fail) => {
              //FIXME: fix this line
              // can we fix that!
              dispatch(logout());
              navigate(ROUTES.SIGNIN);
              if (fail?.data?.err_msg) {
                toastError(fail.data.err_msg);
              } else {
                toastError();
              }
            },
          );
        }
        setUserAccounts((prev) => {
          let filteredUsers = prev.filter((item) => item.id !== user.id);
          return [...filteredUsers];
        });

        setLoading(false);
        toastSuccess(success.message);
        onCancel();
      },
      (fail) => {
        setLoading(false);
        onCancel();
        if (fail?.data?.err_msg) {
          toastError(fail.data.err_msg);
        } else {
          toastError();
        }
      },
    );
  }

  return (
    <Modal
      open={isModalVisible}
      onCancel={handleOnCancel}
      className='delete-account-modal'
      // width={
      //   breakPointData?.winWidth > 991
      //     ? "50vw"
      //     : breakPointData?.winWidth <= 991 && breakPointData?.winWidth > 600
      //     ? "70vw"
      //     : "80vw"
      // }
      footer={false}
      closeIcon={<img src={closeIcon} alt='icon' />}
    >
      <header className='delete-account__header'>
        <h4>{t("areYouSureYouWantToDeleteThisUser")}</h4>
      </header>
      <figure className='delete-account__figure'>
        <Image
          wrapperClassName={"image-wrapper"}
          src={addTokenToMedia(baseUrl + user.avatar)}
          alt='avatar'
          role='button'
          className='delete-account__img'
        />
        {/* <Skeleton width={} height={}  /> */}
      </figure>
      <div className='delete-account__name'>{user.name}</div>

      <Space size='large' className='delete-account__btns'>
        <button
          className='cancel_Btn button-secondary w-100 myclass'
          onClick={() => handleOnCancel()}
        >
          {t("cancel")}
        </button>

        <Button
          className='delete button-primary-100vw w-100'
          loading={loading}
          onClick={deleteUser}
        >
          {t("delete")}
        </Button>
      </Space>
      <DetectWindowSize getBreakPointData={(data) => setBreakPointData(data)} />
    </Modal>
  );
};

export default DeleteModal;
