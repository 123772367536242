import { v4 as uuidv4 } from "uuid";

import ccIcon from "assets/video-controllers/cc.svg";
import QualityIcon from "assets/video-controllers/setting.svg";
import audioIcon from "assets/images/audio.svg";

import { t } from "i18next";
import AudioItem from "./AudioItem";
import SubtitleItem from "./SubtitleItem";
import QualityItem from "./QualityItem";
import VolumeItem from "./VolumeItem";
import FullScreenBtn from "../VideoComponents/BottomController/FullScreenBtn";
import NextVideoBtn from "../VideoComponents/BottomController/NextVideoBtn";
import PreviousVideoBtn from "../VideoComponents/BottomController/PreviousVideoBtn";
import { useSelector } from "react-redux";

export default function ControllersList({
  videoData,
  changeAudio,
  changeSubtitle,
  changeQuality,
  mutePlayer,
  volume,
  setVolume,
  fullScreenChange,
  isFullScreen,
  selectPrevVideo,
  selectNextVideo,
  isNotLastVideoInSeries,
  isNotFirstVideoInSeries,
  selectedQuality,
  setSelectedQuality,
  selectedAudio,
  setSelectedAudio,
  selectedSub,
  setSelectedSub,
}) {
  const audioSettings = {
    id: uuidv4(),
    title: t("audios"),
    icon: audioIcon,
    options: videoData.audioTracks,
    name: "audio",
  };

  const subtitleSettings = {
    id: uuidv4(),
    title: t("subtitle"),

    icon: ccIcon,
    options: videoData.subtitles,
    name: "subtitle",
  };

  const qualitySettings = {
    id: uuidv4(),
    title: t("quality"),
    icon: QualityIcon,
    options: videoData.videoQualities,
    name: "quality",
  };

  const { dir } = useSelector((state) => state.lang);

  return (
    <div
      className="controller-list"
      style={{
        display: "flex",
        width: "100%",
        maxWidth: "100%",
        padding: `0 40px`,
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: dir === "rtl" ? "row-reverse" : "row",
      }}
    >
      <PreviousVideoBtn
        isNotFirstVideoInSeries={isNotFirstVideoInSeries}
        selectPrevVideo={selectPrevVideo}
      />
      {/* {videoData.audioTracks.length > 0 && ( */}
      <AudioItem
        audio={audioSettings}
        changeAudio={changeAudio}
        selectedAudio={selectedAudio}
        setSelectedAudio={setSelectedAudio}
      />
      {/* )} */}
      {/* {videoData.subtitles.length > 0 && ( */}
      <SubtitleItem
        subtitle={subtitleSettings}
        changeSubtitle={changeSubtitle}
        selectedSub={selectedSub}
        setSelectedSub={setSelectedSub}
      />
      {/* )} */}
      {/* {videoData.videoQualities.length > 0 && ( */}
      <QualityItem
        quality={qualitySettings}
        changeQuality={changeQuality}
        selectedQuality={selectedQuality}
        setSelectedQuality={setSelectedQuality}
      />
      {/* )} */}
      <VolumeItem
        mutePlayer={mutePlayer}
        volume={volume}
        setVolume={setVolume}
      />

      <NextVideoBtn
        isNotLastVideoInSeries={isNotLastVideoInSeries}
        selectNextVideo={selectNextVideo}
      />

      <FullScreenBtn
        onFullScreenChange={fullScreenChange}
        isFullScreen={isFullScreen}
      />
    </div>
  );
}
