import { React } from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Form, Button, Radio } from "antd";
import visa from "assets/images/visa1.svg";
import payPal from "assets/images/payPal.svg";
import aman from "assets/images/aman1.png";
import vCach from "assets/images/vCash1.png";
import fawry from "assets/images/fawry1.png";

const Pay = () => {
  const { t } = useTranslation();
  return (
    <section className="pay main-section">
      <Container>
        <Form className="pay__form">
          <Radio.Group className="pay__allPayments">
            <Radio className="pay__content" value={1}>
              <img className="pay__logo" src={visa} alt="Visa" />
            </Radio>
            <Radio className="pay__content" value={2}>
              <img className="pay__logo" src={payPal} alt="payPal" />
            </Radio>
            <Radio className="pay__content" value={3}>
              <img className="pay__logo" src={aman} alt="Aman" />
            </Radio>
            <Radio className="pay__content" value={4}>
              <img className="pay__logo" src={vCach} alt="VodafoneCash" />
            </Radio>
            <Radio className="pay__content" value={5}>
              <img className="pay__logo" src={fawry} alt="MyFawry" />
            </Radio>
          </Radio.Group>
          <div className="pay__button-primary--cent">
            <Button className="button-primary  " htmlType="submit">
              {t("pay")}
            </Button>
          </div>
        </Form>
      </Container>
    </section>
  );
};
export default Pay;
