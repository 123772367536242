// import { useState } from "react";
// import { Popover, Space } from "antd";
// import { useTranslation } from "react-i18next";

// import { BsCheckLg } from "react-icons/bs";

// export default function AudioItem({ audio, changeAudio }) {
//   const [selected, setSelected] = useState(0);

//   const { t, i18n } = useTranslation();

//   const audioContent = (
//     // <ul className="setting-list-wrapper" key={audio.id}>
//     <ul key={audio.id}>
//       {audio?.options?.map((option, index) => {
//         return (
//           <li
//             // className="setting-list-wrapper_audio"
//             style={{
//               paddingInline: "1rem",
//               paddingBlock: ".20rem",
//             }}
//             onClick={() => {
//               changeAudio(option.id);
//               setSelected(option.id);
//             }}
//             key={option.id}
//             role="button"
//           >
//             {selected === option.id ? (
//               <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
//             ) : null}
//             {option.label}
//           </li>
//         );
//       })}
//     </ul>
//   );

//   return (
//     <Popover
//       content={audioContent}
//       placement="topLeft"
//       title={audio.title}
//       key={audio.id}
//       //   trigger="click"
//       trigger="hover"
//       showArrow={false}
//       //   getPopupContainer={() => videoFullScreenWrapper?.current}
//       // overlayStyle={{ position: "fixed", zIndex: "99999" }}
//     >
//       <Space align="center" role="button">
//         <span className="mock-block">
//           <img src={audio.icon} alt="" />
//         </span>
//         <span
//           //   className="mock-block"
//           style={{
//             color: "#fff",
//             // padding: ".25rem",
//           }}
//         >
//           {t(audio.title)}
//         </span>
//       </Space>
//     </Popover>
//   );
// }

import React, { useState } from "react";
import { Space } from "antd";
import { useTranslation } from "react-i18next";
import { BsCheckLg } from "react-icons/bs";

export default function AudioItem({
  audio,
  changeAudio,
  selectedAudio,
  setSelectedAudio,
}) {
  // const [selected, setSelected] = useState(-1);
  const [isListVisible, setIsListVisible] = useState(false);

  const { t, i18n } = useTranslation();

  // const handleSelected = (indx) => {
  //   setSelected(indx);
  // };

  const audioStyles = {
    // paddingInline: ".5rem",
    // paddingBlock: ".25rem",
    padding: "0.5rem",
    display: "flex",
    color: "#fff",
    width: "100%",
  };

  const audioContent = (
    <ul
      //   className="setting-list-wrapper"
      onMouseLeave={() => setIsListVisible(!isListVisible)}
      key={audio.id}
      style={{
        width: "130px",
        position: "absolute",
        bottom: "30px",
        background: "rgba(0, 0,0, .5)",
        color: "#fff",
        zIndex: 1000,
        listStyle: "none",
        padding: 0,
        margin: 0,
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
      }}
    >
      <li
        role="button"
        style={audioStyles}
        onClick={() => {
          changeAudio(0);
          setSelectedAudio(-1);
          setIsListVisible(false);
        }}
      >
        {selectedAudio === -1 ? (
          <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
        ) : null}
        {t("playerOffKey")}
      </li>
      {audio?.options?.map((option) => (
        <li
          key={option.id}
          style={audioStyles}
          onClick={() => {
            changeAudio(option.id);
            setSelectedAudio(option.id);
            setIsListVisible(false);
          }}
          role="button"
        >
          {selectedAudio === option.id ? (
            <BsCheckLg size={20} style={{ marginInlineEnd: "0.5rem" }} />
          ) : null}
          {option.label}
        </li>
      ))}
    </ul>
  );

  return (
    <div
      style={{ position: "relative" }}
      onClick={() => setIsListVisible(!isListVisible)}
    >
      <Space align="center" role="button">
        <span className="mock-block">
          <img src={audio.icon} alt="" />
        </span>
        <span
          className="mock-block"
          style={{
            color: "#fff",
          }}
        >
          {t(audio.title)}
        </span>
      </Space>
      {isListVisible && audioContent}
    </div>
  );
}
